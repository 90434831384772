import { HistoryOperationProps } from './HistoryOperation.props';
import clsx from 'classnames';
import classes from './HistoryOperation.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import {
  columnsArrayTransactions,
  tableDataTransactions,
} from '../transactionData';
import { useEffect, useMemo, useState } from 'react';
import TableTransactions from 'components/shared/TableTransactions';
import { financesStore } from 'stores/financesStore';
import { observer } from 'mobx-react-lite';
import { OrderingTransactions } from 'services/finances';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useUserStore } from 'contexts/UserContext';

const HistoryOperation = observer(
  ({ handleShowModal, className, ...props }: HistoryOperationProps) => {
    const userStore = useUserStore();
    const { isMediaTablet } = useWindowWidth();
    const { t } = useTranslation();

    const isStaff = userStore.user.is_staff;
    const { loadTransactions, transactions, isLoadingTransactions } =
      financesStore;
    const columns = useMemo(() => columnsArrayTransactions(), []);

    const [tableActive, setTableActive] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(isMediaTablet ? 5 : 10);
    const [ordering, setOrdering] =
      useState<OrderingTransactions>('-created_at');
    const [customPageCount, setCustomPageCount] = useState(0);

    useEffect(() => {
      loadTransactions({
        page: page,
        limit: limit,
        ordering: ordering,
      });
    }, [ordering, page]);

    useEffect(() => {
      if (transactions?.count) {
        setCustomPageCount(Math.ceil(transactions.count / limit));
      }
    }, [transactions?.count, limit]);

    if (isLoadingTransactions) {
      return <div>Loading...</div>;
    }

    if (!transactions) return null;

    return (
      <div className={className} {...props}>
        {transactions.results.length < 1 ? (
          <div className={clsx(classes.empty)}>
            <div className={classes.empty__header}>{t('No content')}</div>
            <div className={classes.empty__text}>
              {t('All completed financial transactions will be displayed here')}
            </div>

            {isStaff && (
              <Button
                theme={'primary'}
                size="middle"
                className={classes.empty__btn}
                onClick={handleShowModal}
              >
                {t('Top up')}
              </Button>
            )}
          </div>
        ) : (
          <TableTransactions
            additionalClassNames={classes.table}
            data={tableDataTransactions(transactions.results, t)}
            columns={columns}
            tableActive={tableActive}
            isMobile
            setOrdering={setOrdering}
            ordering={ordering}
            customPageCount={customPageCount}
            currentPage={page}
            setCurrentPage={setPage}
          />
        )}
      </div>
    );
  }
);

export default HistoryOperation;
