import classes from './Notice.module.scss';
import clsx from 'clsx';
import { CheckCircleIcon } from 'components/shared/Icons/CheckCirleIcon';
import { UnionIcon } from 'components/shared/Icons/UnionIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { NoticeProps } from './Notice.props';
import { ExclamationIcon } from '../Icons/ExclamationIcon';

const icons = {
  success: <CheckCircleIcon fill={'#00C853'} width={'100%'} height={'100%'} />,
  warning: <InfoIcon fill={'#F3CA37'} width={'100%'} height={'100%'} />,
  error: <UnionIcon fill={'#F5222D'} width={'100%'} height={'100%'} />,
  default: <ExclamationIcon fill={'#F5222D'} width={'100%'} height={'100%'} />,
};

export const Notice = ({
  type,
  children,
  noticeClass,
  childrenClass,
  noticeIconClass,
  isCustomNotice,
  title,
  description,
}: NoticeProps) => (
  <div className={clsx(classes.notice, classes[type], noticeClass)}>
    <div className={clsx(classes.icon, noticeIconClass)}>
      {icons[type as keyof typeof icons]}
    </div>

    {!isCustomNotice ? (
      <span className={clsx(classes.text, childrenClass)}>{children}</span>
    ) : (
      <div className={classes.textBlock}>
        <div className={classes.noticeTitle}>{title}</div>
        {description && (
          <div
            className={classes.noticeDescription}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    )}
  </div>
);
