import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';
import _ from 'lodash';
import { FinancesTransaction } from 'types';

export const columnsArrayBonus = () => [
  {
    header: '',
    accessor: 'serialNumber',
  },
  {
    header: 'Data',
    accessor: 'date',
  },
  {
    header: 'Transaction',
    accessor: 'transactions',
  },
  {
    header: 'Targets',
    accessor: 'targets',
  },
  {
    header: 'Status',
    accessor: 'status',
  },
  {
    header: 'Sum',
    accessor: 'amount',
  },
];

export const tableDataBonusTransactions = (
  bonusTransactions: FinancesTransaction[]
) =>
  bonusTransactions?.map((transaction) => {
    const transactionDate = new Date(transaction?.created_at);

    return {
      key: _.uniqueId(),
      serialNumber: {
        label: transaction.id,
      },
      date: {
        label: getFormattedDate(transactionDate),
        description: {
          text: getFormattedTime(transactionDate),
        },
      },
      transactions: {
        label: transaction.money_transfer_description.label,
        description: {
          text: transaction.type.label,
        },
      },
      targets: transaction.project
        ? {
            label: transaction.project?.name,
            description: {
              text: `ID ${transaction.project?.id}`,
            },
          }
        : transaction.service
          ? {
              label: transaction.service?.name,
            }
          : {},
      status: {
        label: transaction.money_transfer_status?.label,
        type: transaction.money_transfer_status?.value,
      },
      amount: {
        label: transaction.amount,
      },
    };
  });

export const mockBonusTransactions: FinancesTransaction[] = [
  {
    id: 1,
    money_transfer_description: {
      label: 'Бонусные баллы',
      value: 'string',
    },
    money_transfer_status: {
      label: 'Ожидает подтверждения',
      value: 3,
    },
    type: {
      label: 'Списание',
      value: 'OUTCOME',
    },
    project: {
      id: 678,
      name: 'Покупка',
    },
    created_at: '2024-11-13T14:20:25.864Z',
    updated_at: '2024-11-13T14:20:25.864Z',
    amount: '-23100',
    service: {
      name: 'service',
    },
    user: 6777,
    money_transfer: 11,
  },
  {
    id: 2,
    money_transfer_description: {
      label: 'Бонусные баллы',
      value: 'string',
    },
    money_transfer_status: {
      label: 'Ожидает проверки сервиса',
      value: 4,
    },
    type: {
      label: 'Списание',
      value: 'OUTCOME',
    },
    project: {
      id: 999,
      name: 'Начисление',
    },
    created_at: '2024-11-13T14:20:25.864Z',
    updated_at: '2024-11-13T14:20:25.864Z',
    amount: '12100',
    service: {
      name: 'service',
    },
    user: 6777,
    money_transfer: 11,
  },
  {
    id: 3,
    money_transfer_description: {
      label: 'Бонусные баллы',
      value: 'string',
    },
    money_transfer_status: {
      label: 'Выполнено',
      value: 2,
    },
    type: {
      label: 'Списание',
      value: 'OUTCOME',
    },
    project: {
      id: 988,
      name: 'Покупка',
    },
    created_at: '2024-11-13T14:20:25.864Z',
    updated_at: '2024-11-13T14:20:25.864Z',
    amount: '-200',
    service: {
      name: 'service',
    },
    user: 6777,
    money_transfer: 11,
  },
  {
    id: 4,
    money_transfer_description: {
      label: 'Бонусные баллы',
      value: 'string',
    },
    money_transfer_status: {
      label: 'Отменено',
      value: 1,
    },
    type: {
      label: 'Пополнение',
      value: 'INCOME',
    },
    project: {
      id: 988,
      name: 'Кешбек',
    },
    created_at: '2024-11-13T14:20:25.864Z',
    updated_at: '2024-11-13T14:20:25.864Z',
    amount: '300',
    service: {
      name: 'service',
    },
    user: 6777,
    money_transfer: 11,
  },
];
