import { Label } from './Label';
import classes from './Input.module.scss';
import TextareaAutosize from 'react-textarea-autosize';
import clsx from 'clsx';
import { TextareaProps } from './Input.props';

export const Textarea = ({
  label = '',
  labelPosition = 'left',
  placeholder = '',
  disabled = false,
  error = '',
  value = '',
  onChange,
  maxLength,
}: TextareaProps) => {
  const className = clsx(classes.textarea, error && classes.textareaError);

  return (
    <div className={classes.inputWrap}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <TextareaAutosize
        className={className}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e.target.value)}
        maxLength={maxLength}
      />
    </div>
  );
};
