import { useEffect, useState } from 'react';
import classes from './HotDealsItem.module.scss';
import clsx from 'clsx';
import { WhiteFireIcon } from 'components/shared/Icons/WhiteFireIcon';
import { Link, useNavigate } from 'react-router-dom';
import { FilledHeartIcon } from 'components/shared/Icons/FilledHeartIcon';
import { HeartIcon } from 'components/shared/Icons/HeartIcon';
import { Button } from 'components/shared/Button';
import { useFavorites } from 'hooks';
import { useUserStore } from 'contexts/UserContext';
import { toJS } from 'mobx';
import { useHotDeals } from 'hooks/useHotDeals';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { useCartStore } from 'contexts/CartContext';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import AddToCartButton from 'components/shared/Button/AddToCartButton';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import PriceText from 'components/shared/PriceText';
import { useCheckCurrency } from 'hooks/useCheckCurrency';
import { HotDealsItemProps } from './HotDealsItem.props';
import { ICartData } from 'types';

export const HotDealsItem = observer(
  ({ infoDiscount, deals, isFeature }: HotDealsItemProps) => {
    const cartStore = useCartStore();
    const userStore = useUserStore();
    const { t, i18n } = useTranslation();
    const { getHotDealText } = useHotDeals(t);
    const navigate = useNavigate();
    const checkCurrency = useCheckCurrency();

    const [isInCart, setIsInCart] = useState(false);
    const [cartSources, setCartSources] = useState<any>();
    const [isFavorite, setIsFavorite] = useState(infoDiscount.is_favorite);
    const [hotDealDiscountText, setHotDealDiscountText] = useState('');

    const changeFavorites = useFavorites(
      infoDiscount.id,
      isFavorite,
      setIsFavorite
    );

    useEffect(() => {
      if (toJS(cartStore.cart)) {
        if (Object.keys(toJS(cartStore.cart!)).length !== 0) {
          const newCart = toJS(cartStore.getActiveCart());
          newCart?.basket_project_service?.forEach((service: ICartData) => {
            if (service.service.id === infoDiscount.id) {
              setIsInCart(true);
              setCartSources(service.source);
            }
          });
        }
      }
    }, [cartStore.cart]);

    useEffect(() => {
      setHotDealDiscountText(getHotDealText(deals, infoDiscount));
    }, [deals, infoDiscount]);

    const utcDate = new Date(`${deals.deal_end_date}`);
    const dayEnd = utcDate.getDate();
    const monthEnd = utcDate.toLocaleString(i18n.language, { month: 'long' });
    const yearEnd = utcDate.getFullYear();

    const formattedPrice = (price?: number | string, withMinus?: boolean) => (
      <FormatPrice
        currency={userStore.user?.currency?.symbol}
        price={price}
        withMinus={withMinus}
      />
    );

    const isShowOldPrice =
      infoDiscount.has_active_discounts &&
      Number(infoDiscount?.cost) !==
        Number(infoDiscount?.cost_with_discounts) &&
      formattedPrice(infoDiscount?.cost) &&
      !infoDiscount?.discounts?.find(
        (item: any) => item.cost_change_type === 'PRICE_NEW'
      );

    return (
      <div className={clsx(classes.item)}>
        <div className={clsx(classes.deal, !isFeature && classes.activeDeal)}>
          {deals.cost_change_type === 'MONEY' &&
            formattedPrice(undefined, true)}
          {hotDealDiscountText}
        </div>

        <div className={classes.inner}>
          <div className={classes.box}>
            <div className={classes.header}>
              <div className={classes.tags}>
                <div className={clsx(classes.tag, classes.hit)}>
                  <WhiteFireIcon />
                  {deals.cost_change_type === 'MONEY' &&
                    formattedPrice(undefined, true)}
                  {hotDealDiscountText}
                </div>
                <div className={classes.tag}>{infoDiscount.product?.name}</div>
              </div>

              <div className={classes.favored}>
                <Button
                  className={classes.favoriteButton}
                  theme="icon-default"
                  onClick={changeFavorites}
                >
                  {isFavorite ? (
                    <FilledHeartIcon />
                  ) : (
                    <HeartIcon fill={'#8362F3'} />
                  )}
                </Button>
              </div>
            </div>

            {isFeature ? (
              <span
                className={clsx(classes.title, isFeature && classes.disabled)}
              >
                {infoDiscount.name}
              </span>
            ) : (
              <div>
                <Link
                  to={`/solutions/${infoDiscount.slug}`}
                  className={classes.title}
                >
                  {infoDiscount.name}
                </Link>

                <div className={classes.tooltipWrap}>
                  <span className={classes.closeTimeInner}>
                    {t('Closing time')}:{' '}
                    <span className={classes.closeTime}>
                      {dayEnd} {monthEnd} {yearEnd}{' '}
                    </span>
                  </span>

                  <TooltipContainer
                    text={t(
                      'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                    )}
                    position={'top'}
                    className={classes.tooltipContainer}
                    classNameTooltip={classes.tooltip}
                  >
                    <InfoIcon fill="#A09CAC" width="18px" height="18px" />
                  </TooltipContainer>
                </div>
              </div>
            )}
          </div>

          <div className={classes.footer}>
            <div
              className={clsx(
                classes.price,
                isFeature && classes.priceDisabled
              )}
            >
              <span className={classes.priceTitle}>
                {infoDiscount?.price_per && (
                  <PriceText data={infoDiscount} t={t} />
                )}
              </span>
              <div className={classes.row}>
                <span
                  className={clsx(
                    classes.priceItem,
                    checkCurrency && classes['priceItem--small']
                  )}
                >
                  {formattedPrice(
                    (infoDiscount?.has_active_discounts
                      ? infoDiscount?.cost_with_discounts
                      : infoDiscount?.cost) ?? ''
                  )}
                </span>

                {!isFeature && isShowOldPrice && (
                  <span className={classes.current}>
                    {infoDiscount.has_active_discounts &&
                      Number(infoDiscount?.cost) !==
                        Number(infoDiscount?.cost_with_discounts) &&
                      formattedPrice(infoDiscount?.cost)}
                  </span>
                )}
              </div>
            </div>

            <div className={classes['mobile-buttons']}>
              <AddToCartButton data={infoDiscount} disabled={isFeature} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
