import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import PermissionErrorContainer from 'containers/PermissionError';
import { useUserStore } from 'contexts/UserContext';
import { Outlet } from 'react-router-dom';

export function PermissionErrorPage() {
  const userStore = useUserStore();

  if (userStore.isPermissionError || userStore.isLocaleError) {
    return (
      <>
        <TitleAndDescription pageName="403" />

        <PermissionErrorContainer
          isLocaleError={userStore.isLocaleError}
          isPermissionError={userStore.isPermissionError}
        />
      </>
    );
  }

  return <Outlet />;
}
