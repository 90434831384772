import { createContext, ReactNode, useContext } from 'react';
import { cartStore } from 'stores/cartStore';

const CartContext = createContext<typeof cartStore | null>(null);

export const CartProvider = ({ children }: { children: ReactNode }) => (
  <CartContext.Provider value={cartStore}>{children}</CartContext.Provider>
);

export const useCartStore = () => {
  const context = useContext(CartContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with CartProvider'
    );
  }
  return context;
};
