import classes from './BonusList.module.scss';
import BonusListItem from '../BonusListItem';
import { Button } from 'components/shared/Button';
import { SortIcon } from 'components/shared/Icons/SortIcon';
import { Dispatch, SetStateAction, useState } from 'react';
import { FinancesTransaction } from 'types';
import { OrderingTransactions } from 'services/finances';
import { ModalMobile } from 'components/shared/ModalMobile';
import { useTranslation } from 'react-i18next';
import {
  MobileFilter,
  sortOptions,
} from 'components/shared/TableTransactions/MobileFilter';
import { observer } from 'mobx-react-lite';
import { BonusTablePagination } from '../BonusTablePagination';

interface BonusListProps {
  data: FinancesTransaction[];
  customPageCount: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setOrdering: Dispatch<SetStateAction<OrderingTransactions>>;
  ordering: OrderingTransactions;
}

const BonusList = ({
  data,
  customPageCount,
  currentPage,
  setCurrentPage,
  setOrdering,
  ordering,
}: BonusListProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          className={classes.button}
          theme="text"
          onClick={() => setShowModal(true)}
        >
          {t(
            sortOptions.find((option) => option.key === ordering)?.label ||
              'New first'
          )}
          <SortIcon />
        </Button>
        <ul className={classes.bonusList}>
          {data.map((item) => (
            <BonusListItem key={item.id} transaction={item} />
          ))}
        </ul>

        {customPageCount > 1 && (
          <div className={classes.pagination}>
            <BonusTablePagination
              currentPage={currentPage}
              totalPages={customPageCount}
              setPageNumber={setCurrentPage}
            />
          </div>
        )}
      </div>
      <ModalMobile
        title=""
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isBasicHeader={false}
      >
        <MobileFilter ordering={ordering} setOrdering={setOrdering} />
      </ModalMobile>
    </>
  );
};

export default observer(BonusList);
