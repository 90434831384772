import ColorSticker, { StickerColor } from 'components/shared/ColorSticker';
import classes from './ResearchCard.module.scss';
import back_image_1 from 'assets/img/GrowthProgram/bg-card-1.png';
import back_image_2 from 'assets/img/GrowthProgram/bg-card-2.png';
import back_image_3 from 'assets/img/GrowthProgram/bg-card-3.png';
import FileLink from 'components/shared/FileLink';
import { useLayoutEffect, useRef } from 'react';
import clamp from 'clamp-js';
import clsx from 'clsx';

interface ResearchCardProps {
  section: string;
  title: string;
  description: string;
  fileLink: string;
  fileSize: number;
  fileDate: string;
  tagColor: StickerColor;
  currentIndex: number;
}

const ResearchCard = (props: ResearchCardProps) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      clamp(textRef.current, { clamp: 6 });
    }
  }, []);

  const selectBackground = (index: number) => {
    switch (index) {
      case 0:
        return <img src={back_image_1} alt="" className={classes.background} />;
      case 1:
        return (
          <img
            src={back_image_2}
            alt=""
            className={clsx(classes.background, classes.bottom)}
          />
        );
      case 2:
        return (
          <img
            src={back_image_3}
            alt=""
            className={clsx(classes.background, classes.left)}
          />
        );

      default:
        return <img src={back_image_1} alt="" className={classes.background} />;
    }
  };

  return (
    <div className={classes.card}>
      {selectBackground(props.currentIndex)}
      <ColorSticker
        color={props.tagColor}
        text={props.section}
        className={classes.card__sticker}
      />
      <span className={classes.card__title}>{props.title}</span>
      <p ref={textRef} className={classes.card__description}>
        {props.description}
      </p>
      <FileLink
        size={props.fileSize}
        date={props.fileDate}
        link={props.fileLink}
        linkName="Скачать PDF"
        className={classes.card__link}
      />
    </div>
  );
};

export default ResearchCard;
