import classes from './CircleIcon.module.scss';
import clsx from 'clsx';

interface CircleIconProps {
  icon: React.ReactNode;
  className?: string;
}

const CircleIcon = ({ icon, className }: CircleIconProps) => (
  <div className={clsx(classes.iconBlock, className)}>{icon}</div>
);

export default CircleIcon;
