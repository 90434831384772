export const handleDownload = (filePath?: string, inNewTab = false) => {
  if (!filePath) return;
  const fileUrl = `${filePath}`;
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = fileUrl;
  a.download = fileUrl?.split('/').pop() as string;
  if (inNewTab) a.target = '_blank';
  document.body?.appendChild(a);
  a.click();
  document.body?.removeChild(a);
};
