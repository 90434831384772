import BreadCrumbs from 'components/shared/BreadCrumbs';
import classes from './GrowthProgramRules.module.scss';
import AnchorsWrapper from './components/AnchorsWrapper';
import { MockText, mockTexts } from './mockData';
import TextWrapper from './components/TextWrapper';
import { useState } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import Select from 'components/shared/SelectComponent';
import { ModalMobile } from 'components/shared/ModalMobile';
import { useAnchorScroll } from 'hooks/useAnchorScroll';

const GrowthProgramRulesContainer = () => {
  const { width } = useWindowWidth();
  const [currentText, setCurrentText] = useState<MockText>(mockTexts[0]);
  const [openSelect, setOpenSelect] = useState(false);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const { currentAnchor, setCurrentAnchor } = useAnchorScroll('start');

  const callbacks = {
    onSelectText: (text: MockText) => {
      setCurrentText(text);
    },
    onSelectAnchor: (a: string) => {
      setCurrentAnchor(a);
      setOpenMobileModal(false);
      setOpenSelect(false);
    },
    onSelectMobileText: (val: { id: number; name: string }) => {
      const newText = mockTexts.find((text) => text.id === val.id);
      if (newText) {
        setCurrentText(newText);
      }
    },
  };

  return (
    <div className={classes.wrapper}>
      <BreadCrumbs
        link="/programma-rosta/"
        linkName="Программа роста"
        className={classes.breadcrumbs}
        items={['Правила программы роста']}
      />
      <div className={classes.content}>
        {width < 1024 && (
          <Select
            select={{ id: currentText.id, name: currentText.title }}
            setSelect={callbacks.onSelectMobileText}
            options={mockTexts.map((text) => ({
              id: text.id,
              name: text.title,
            }))}
            open={openSelect}
            setOpen={setOpenSelect}
            title={''}
            boxClassName={classes.selectBox}
            wrapperClassName={classes.selectWrapper}
            enableMobile={false}
            disableDropdown={width < 1024}
            onClick={() => setOpenMobileModal(true)}
          />
        )}
        {width > 1024 && (
          <AnchorsWrapper
            currentText={currentText}
            currentAnchor={currentAnchor}
            texts={mockTexts}
            onSelectText={callbacks.onSelectText}
            onSelectAnchor={callbacks.onSelectAnchor}
          />
        )}
        {width < 1024 && (
          <ModalMobile
            title=""
            isOpen={openMobileModal}
            onClose={() => {
              setOpenMobileModal(false);
              setOpenSelect(false);
            }}
            isBasicHeader={false}
          >
            <AnchorsWrapper
              isMobile
              currentText={currentText}
              currentAnchor={currentAnchor}
              texts={mockTexts}
              onSelectText={callbacks.onSelectText}
              onSelectAnchor={callbacks.onSelectAnchor}
            />
          </ModalMobile>
        )}
        <TextWrapper text={currentText.text} />
      </div>
    </div>
  );
};

export default GrowthProgramRulesContainer;
