import React, { FC, useMemo, useState } from 'react';
import classes from '../Cases.module.scss';
import { getLangName } from 'tools/utils';
import { Button } from 'components/shared/Button';
import clsx from 'clsx';
import { SmallPurpleArrowDownIcon } from 'components/shared/Icons/SmallPurpleArrowDownIcon';
import { AMOUNT_TYPE } from 'constants/enums';
import { formatCasesForLocale } from '../formattedCases';
import { Modal } from 'components/shared/Modal';
import UiIcon from 'components/shared/Icon';
import { SingleCaseProps } from './SingleCase.props';
import { observer } from 'mobx-react-lite';
import { useWindowWidth } from 'hooks/useWindowWidth';
import {
  CaseKPI,
  InternalCaseKPI,
  Platform,
} from '../../../../types/solutions';
import { casesStore } from '../../../../stores/casesStore';
import { toJS } from 'mobx';

const SingleCase: FC<SingleCaseProps> = observer(({ singleCase, t, i18n }) => {
  const [showMore, setShowMore] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const { isSmallTablet } = useWindowWidth();
  const internalCase = useMemo(() => toJS(singleCase), [singleCase]);

  return (
    <div className={classes.case}>
      <div className={classes['image-wrapper']}>
        <img className={classes.image} src={internalCase.image} alt="" />
      </div>

      <div className={classes.info}>
        <div className={classes.head}>
          <div className={classes.type}>
            {getLangName(internalCase, 'achievement', i18n)}
          </div>

          <div className={classes.tags}>
            <div className={classes.tag}>
              {getLangName(internalCase?.product, 'name', i18n)}
            </div>

            {internalCase?.platform?.map((tag: Platform) => {
              if (tag.image) {
                return (
                  <div className={classes.tag_img} key={tag.id}>
                    <img className={classes.platform} src={tag.image} alt="" />
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div className={classes.body}>
          <div
            className={classes.name}
            dangerouslySetInnerHTML={{
              __html: getLangName(internalCase, 'description', i18n),
            }}
          />

          {!showMore && !isSmallTablet ? (
            <Button
              onClick={() => setShowMore(true)}
              className={classes.button}
              theme="text"
            >
              {t('Show more')}
              <div className={clsx(classes['button-icon'], classes.rotate)}>
                <SmallPurpleArrowDownIcon />
              </div>
            </Button>
          ) : (
            !isSmallTablet && (
              <Button
                onClick={() => setShowMore(false)}
                className={classes.button}
                theme="text"
              >
                {t('Show less')}
                <div className={clsx(classes['button-icon'])}>
                  <SmallPurpleArrowDownIcon />
                </div>
              </Button>
            )
          )}
          {isSmallTablet && (
            <Button
              onClick={() => setReadMore(true)}
              className={classes.button}
              theme="text"
            >
              {t('Show more')}
              <div className={clsx(classes['button-icon'], classes.rotate)}>
                <SmallPurpleArrowDownIcon />
              </div>
            </Button>
          )}
        </div>

        {showMore && !isSmallTablet && (
          <div className={classes.kpises}>
            {internalCase?.case_kpises?.map(
              (kpi: InternalCaseKPI, index: number) => (
                <div className={classes.kpi} key={kpi?.name + index}>
                  <div className={classes.description}>{kpi?.description}</div>

                  <div className={classes.amount}>
                    {
                      AMOUNT_TYPE[
                        kpi.amount_type as unknown as keyof typeof AMOUNT_TYPE
                      ]
                    }
                    {formatCasesForLocale(kpi.amount, kpi.amount_unit, 2)}
                  </div>
                </div>
              )
            )}
          </div>
        )}

        <Modal
          title={''}
          isOpen={readMore}
          className={classes.del_modal}
          onClose={() => setReadMore(false)}
          classNameForContent={classes.modalContent}
        >
          <div className={classes.modalContainer}>
            <div className={classes.modalInner}>
              <span className={classes.modalTitle}>
                {t('New credit requests approved in TrueBalance')}
              </span>
            </div>

            <div className={classes.kpises}>
              {internalCase?.case_kpises?.map((kpi: InternalCaseKPI, index) => (
                <div className={classes.kpi} key={kpi?.name + index}>
                  <div className={classes.description}>{kpi.description}</div>
                  <div className={classes.amount}>
                    {
                      AMOUNT_TYPE[
                        kpi.amount_type as unknown as keyof typeof AMOUNT_TYPE
                      ]
                    }
                    {formatCasesForLocale(kpi.amount, kpi.amount_unit, 2)}
                  </div>
                </div>
              ))}
            </div>

            <button
              className={classes.modalClose}
              onClick={() => setReadMore(false)}
            >
              <UiIcon
                name="CrossIcon"
                additionalClassName={classes['close-icon']}
              />
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
});

export default SingleCase;
