import * as React from 'react';
import { SVGProps } from 'react';

export const ClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M14.8853 4.16602H16.1188C16.2257 4.16602 16.2882 4.25905 16.2241 4.32568L10.8398 9.99935L16.2241 15.673C16.2882 15.7396 16.2257 15.8327 16.1188 15.8327H14.8853C14.8014 15.8327 14.7224 15.8013 14.6731 15.7497L10.0003 10.8266L5.32754 15.7497C5.27819 15.8013 5.19925 15.8327 5.11536 15.8327H3.88179C3.77488 15.8327 3.71238 15.7396 3.77488 15.673L9.15913 9.99935L3.77488 4.32568C3.71238 4.25905 3.77488 4.16602 3.88179 4.16602H5.11536C5.19925 4.16602 5.27819 4.19745 5.32754 4.24899L10.0003 9.17212L14.6731 4.24899C14.7224 4.19745 14.8014 4.16602 14.8853 4.16602Z"
      fill="#A09CAC"
    />
  </svg>
);
