import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M9.33332 1.51302V4.26672C9.33332 4.64009 9.33332 4.82677 9.40598 4.96938C9.4699 5.09482 9.57189 5.19681 9.69733 5.26072C9.83994 5.33338 10.0266 5.33338 10.4 5.33338H13.1537M10.6667 8.66667H5.33332M10.6667 11.3333H5.33332M6.66666 6H5.33332M9.33332 1.33334H5.86666C4.74655 1.33334 4.1865 1.33334 3.75868 1.55132C3.38235 1.74307 3.07639 2.04903 2.88464 2.42535C2.66666 2.85318 2.66666 3.41323 2.66666 4.53334V11.4667C2.66666 12.5868 2.66666 13.1468 2.88464 13.5746C3.07639 13.951 3.38235 14.2569 3.75868 14.4487C4.1865 14.6667 4.74655 14.6667 5.86666 14.6667H10.1333C11.2534 14.6667 11.8135 14.6667 12.2413 14.4487C12.6176 14.2569 12.9236 13.951 13.1153 13.5746C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V5.33334L9.33332 1.33334Z"
      stroke={RUSSIAN_LOCALE ? '#F14B4B' : '#8362F3'}
    />
  </svg>
);
