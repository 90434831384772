import clsx from 'clsx';
import classes from '../TableEmployees.module.scss';
import { RoleCellProps } from './RoleCell.props';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { OptionTypeString } from 'types/util-types';
import { useTranslation } from 'react-i18next';
import { SelectNew } from 'components/shared/SelectNew/SelectNew';
import { employeesRoles } from '../../employeesRoles';
import { CLIENT_ROLE } from 'types/user';

const RoleCell = ({
  value,
  isDisabledChangeRole,
  handleChangePermissions,
}: RoleCellProps) => {
  const { isMediaTablet } = useWindowWidth();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.label,
        classes[value.label],
        classes[`type_${value.type}`]
      )}
    >
      {isMediaTablet ? (
        <div className={clsx(classes.nameRole, !value.id && classes.opacity)}>
          {t(
            employeesRoles?.find(
              (item: OptionTypeString) => item?.id === value.type
            )?.name || ''
          )}
        </div>
      ) : (
        <SelectNew
          select={employeesRoles?.find(
            (item: OptionTypeString) => item?.id === value.type
          )}
          small
          options={employeesRoles}
          isSolutionForm
          onSelect={(item: OptionTypeString) =>
            handleChangePermissions(
              value.id,
              item.id as CLIENT_ROLE,
              value.email
            )
          }
          disabled={isDisabledChangeRole}
        />
      )}
    </div>
  );
};

export default RoleCell;
