import { Component, ErrorInfo } from 'react';
import { ErrorPageContainer } from 'components/common/ErrorPageContainer';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.props';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    console.log('Error caught by ErrorBoundary:', error, errorInfo);
    if (this.props.isServerError) {
      this.props.navigate('/error');
    }
  }

  render() {
    const errorInfo = {
      errorInfo: this.state.errorInfo,
      error: this.state.error?.toString(),
    };
    if (this.state.hasError) {
      return (
        <ErrorPageContainer
          errorMessage={this.props.t(
            'Sorry, there are some technical issues. Please refresh the page or try again later'
          )}
          errorInfo={errorInfo}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
