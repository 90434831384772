import { createContext, ReactNode, useContext } from 'react';
import { userStore, userProps } from 'stores/userStore';
import { ProjectAct, ProjectInvoice } from '../types/project';

const UserContext = createContext<userProps>({
  user: {},
  projects: [],
  invoices: [],
  projectActs: {} as ProjectAct,
  projectInvoices: {} as ProjectInvoice,
  projectData: {},
  addUser() {},
  loadUser() {},
  loadInvoices() {},
  loadProjectInvoices() {},
  sendRegistrationCompleteEmail() {},
  changeLanguage() {},
  clearStore() {},
  pagesToOnboard: {},
  isInnerDataLoading: false,
  setLocaleChanged() {},
  localeChanged: false,
  isProjectError: false,
  isPermissionError: false,
  isLocaleError: false,
  isComplete: false,
  isDataLoading: false,
  dashboardsProjects: [],
  client: null,
  allOnboardings: [],
  loadAllOnboardings() {},
  onboardingSteps: [],
  loadOnboardingSteps() {},
  companySizesDictionary: [],
  loadDictionary() {},
  loadProjectActs() {},
});

export const UserProvider = ({ children }: { children: ReactNode }) => (
  <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
);

export const useUserStore = () => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with UserProvider'
    );
  }
  return context;
};
