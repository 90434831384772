import { useUserStore } from 'contexts/UserContext';

export const useCheckCurrency = () => {
  const userStore = useUserStore();

  return (
    userStore.user.currency.char_code === 'IDR' ||
    userStore.user.currency.char_code === 'INR'
  );
};
