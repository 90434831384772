import { FinancesContainer } from 'containers/Finances';
import { useUserStore } from 'contexts/UserContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/main.scss';
import { CLIENT_ROLE } from 'types/user';

// todo add localization feature
export const Finances = () => {
  const navigate = useNavigate();
  const userStore = useUserStore();

  const isClientAdmin =
    userStore.client &&
    userStore.client?.permission?.type === CLIENT_ROLE.ADMIN;

  useEffect(() => {
    if (!isClientAdmin) navigate('/');
  }, [isClientAdmin]);

  return <FinancesContainer />;
};
