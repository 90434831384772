import i18n from 'i18n/i18n';

export const formatBalanceForLocale = (
  balanceValue: number | string,
  currency: string,
  currentCurrencySymbol?: string,
  showComma = false
) => {
  const lang = i18n.language;
  let balance: string = '';

  // When the language is en and the currency selected is IDR or any other - then instead of the symbol we substitute char_code since the language does not match the char_code
  if (currentCurrencySymbol && lang !== 'ru') {
    balance = `${currentCurrencySymbol}${new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(balanceValue) ?? 0)}`;
  } // We do this check because in some cases "PERC" may come in char_code and we need to output the % sign
  else if (currency === 'PERC') {
    balance = `${new Intl.NumberFormat(lang, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(balanceValue) ?? 0)}%`;
  } // Handle Empty value in value unit cases
  else if (currency === 'NONE') {
    balance = new Intl.NumberFormat(lang, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(balanceValue) ?? 0);
  } // Delete the block below after the CUR in the data from the backend is removed
  else if (currency === 'CUR') {
    balance = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(balanceValue) ?? 0);
  } else {
    balance = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(balanceValue) ?? 0);
  }

  if (!showComma) {
    balance = balance.replace(/,/g, '\u00A0');
  }

  return balance;
};
