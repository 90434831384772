import { AccountContainer } from 'containers/Account';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/main.scss';
import { CLIENT_ROLE } from 'types/user';
import { RUSSIAN_LOCALE } from 'utils';

// todo add localization feature
export const Account = observer(({ section }: { section: string }) => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const { isMediaTablet } = useWindowWidth();

  const client = userStore?.client;
  const isViewer = client?.permission?.type === CLIENT_ROLE.VIEWER;

  useEffect(() => {
    if (!section) {
      !isMediaTablet && navigate('personal');
    }

    if (section === 'change-password' && RUSSIAN_LOCALE) navigate('/');

    if (section === 'employees' && (!client || isViewer)) navigate('/');
  }, [isMediaTablet]);

  return <AccountContainer section={section} />;
});
