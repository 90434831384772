import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import api from 'services/http';
import Search from 'services/search';
import { ShowNotification } from 'tools/showNotification';
import { TTab } from 'components/shared/TabFilter/types';
import { SearchResultsProps } from './SearchResults.props';

export const useSearchResults = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const parsedHash = queryString.parse(hash);

  const [solutions, setSolutions] = useState<SearchResultsProps | null>(null);
  const [projects, setProjects] = useState<SearchResultsProps | null>(null);
  const [tabs, setTabs] = useState<TTab[]>([
    {
      id: 1,
      name_key: 'solutions',
      name: t('Solutions'),
      count: 0,
    },
    {
      id: 2,
      name_key: 'projects',
      name: t('Projects'),
      count: 0,
    },
    {
      id: 3,
      name_key: 'finances',
      name: t('Finances'),
      count: 0,
    },
  ]);
  const [isLoading, setisLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<TTab>(tabs[0]);
  const [finances] = useState<any>(null);

  const isNoResults =
    (activeTab.name_key === 'solutions' && !solutions?.results?.length) ||
    (activeTab.name_key === 'projects' && !projects?.results?.length) ||
    (activeTab.name_key === 'finances' && !finances?.results?.length);

  const handleSeeMore = async (type?: string) => {
    try {
      if (type === 'solutions' && solutions?.next) {
        const response = (await api.get(solutions?.next))?.data;
        setSolutions((prevSolutions: SearchResultsProps | null) => ({
          count: response.count,
          next: response.next,
          previous: response.previous,
          results: [...prevSolutions!.results, ...response.results],
        }));
      }

      if (type === 'projects' && projects?.next) {
        const response = (await api.get(projects?.next))?.data;
        setProjects((prevProjects: SearchResultsProps | null) => ({
          count: response.count,
          next: response.next,
          previous: response.previous,
          results: [...prevProjects!.results, ...response.results],
        }));
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
  };

  const fetchData = async () => {
    setisLoading(true);
    try {
      const searchTerm = (queryString.parse(hash)?.search as string) || '';
      const responseSolutions = await Search.getSearchResults(
        'solutions',
        searchTerm
      );
      const responseProjects = await Search.getSearchResults(
        'projects',
        searchTerm
      );
      setSolutions(responseSolutions?.data);
      setProjects(responseProjects?.data);
      setTabs((prevTabs: TTab[]) => {
        const updatedTabs = [...prevTabs];
        const solutionsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'solutions'
        );
        const projectsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'projects'
        );
        updatedTabs[solutionsIndex].count = responseSolutions?.data?.count;
        updatedTabs[projectsIndex].count = responseProjects?.data?.count;
        return updatedTabs;
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: 'An error occurred. Please try again later',
      });
    }
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [window.location.href]);

  return {
    tabs,
    solutions,
    projects,
    t,
    isLoading,
    handleSeeMore,
    parsedHash,
    activeTab,
    setActiveTab,
    isNoResults,
  };
};
