import * as React from 'react';
import { SVGProps } from 'react';

export const SortIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.562 3.308a.466.466 0 0 0-.67 0l-2.18 2.233a.164.164 0 0 0 0 .228l.447.457a.155.155 0 0 0 .223 0l1.372-1.405v8.365c0 .09.07.162.158.162h.631a.16.16 0 0 0 .158-.162V4.821l1.372 1.405a.155.155 0 0 0 .223 0l.446-.457a.164.164 0 0 0 0-.228l-2.18-2.233ZM8.704 10.772a.155.155 0 0 1 .223 0l1.372 1.405V3.812c0-.089.07-.161.158-.161h.63a.16.16 0 0 1 .159.161v8.365l1.371-1.405a.155.155 0 0 1 .224 0l.446.457a.164.164 0 0 1 0 .23l-2.18 2.232a.466.466 0 0 1-.67 0l-2.18-2.233a.164.164 0 0 1 0-.229l.447-.457Z"
      fill="#A09CAC"
    />
  </svg>
);
