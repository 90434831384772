export interface IPagination {
  currentPage: number;
  totalPages: number;
  simbol?: number | string;
}

export const pagination = ({
  currentPage,
  totalPages,
  simbol = '...',
}: IPagination): (number | string)[] => {
  let range: (number | string)[] = [];

  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      range.push(i);
    }
  } else {
    if (currentPage === 1 || currentPage === totalPages) {
      range = [
        1,
        2,
        simbol,
        Math.ceil(totalPages / 2),
        simbol,
        totalPages - 1,
        totalPages,
      ];
    } else if (currentPage === Math.ceil(totalPages / 2)) {
      range = [
        1,
        simbol,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        simbol,
        totalPages,
      ];
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      range = [
        1,
        simbol,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        simbol,
        totalPages,
      ];
    } else if (currentPage >= 2 && currentPage <= 3) {
      range = [1, 2, 3, 4, simbol, totalPages - 1, totalPages];
    } else if (currentPage >= totalPages - 2 && currentPage <= totalPages - 1) {
      range = [
        1,
        2,
        simbol,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
  }

  return range;
};

export const paginationSecond = ({
  currentPage,
  totalPages,
  simbol = '...',
}: IPagination): (number | string)[] => {
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const range: (number | string)[] = [];

  range.push(1);
  if (currentPage <= 4) {
    for (let i = 2; i <= 4; i++) {
      range.push(i);
    }
    range.push(simbol);
  } else if (currentPage > totalPages - 4) {
    range.push(simbol);
    for (let i = totalPages - 3; i <= totalPages; i++) {
      range.push(i);
    }
  } else {
    range.push(simbol);
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      range.push(i);
    }
    range.push(simbol);
  }
  if (!range.includes(totalPages)) {
    range.push(totalPages);
  }

  return range;
};
