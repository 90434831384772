import { RUSSIAN_LOCALE } from 'utils';
import { FormatPriceProps } from './FormatPrice.props';
import { formatPrice } from 'tools/formatPrice';

const FormatPrice = ({
  currency,
  price,
  withMinus,
  text,
  formatted = false,
}: FormatPriceProps) => {
  const hasRupeeSymbol = /₹/.test(currency);

  const renderText = () => {
    const test = hasRupeeSymbol
      ? { fontFamily: 'Noto, sans-serif' }
      : { fontFamily: 'inherit' };
    const space = RUSSIAN_LOCALE ? '\u00A0' : '';
    const formattedPrice = formatted ? price : price && formatPrice(price);

    return (
      <span>
        {RUSSIAN_LOCALE ? (
          <>
            {formattedPrice}
            <span style={test}>
              {space}
              {withMinus ? `-${currency}` : currency}
            </span>
          </>
        ) : (
          <>
            <span style={test}>{withMinus ? `-${currency}` : currency}</span>
            {formattedPrice}
          </>
        )}

        {text && text}
      </span>
    );
  };

  return <>{renderText()}</>;
};

export default FormatPrice;
