import * as React from 'react';
import { SVGProps } from 'react';

export const LoginRuBGRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    // width="743"
    // height="279"
    viewBox="0 0 743 279"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.634277 0C34.2942 247.838 329.735 373.258 579 193.5C614.773 235.509 673.079 260.099 743 272.453V0H0.634277Z"
      fill="white"
      fillOpacity="0.3"
    />
  </svg>
);
