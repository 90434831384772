import { AxiosResponse } from 'axios';
import api from './http';
import { RefillResponse } from 'types';

export type OrderingTransactions =
  | '-amount'
  | 'amount'
  | '-created_at'
  | 'created_at'
  | undefined;
export type QueryParamsTransactions = {
  page: number | null;
  limit: number | null;
  ordering?: OrderingTransactions;
  currency?: string;
};

export default class Finances {
  static async getConditions(): Promise<AxiosResponse> {
    return api.get(
      '/finance/api/v1/refill/conditions/?service=paymaster&currency=RUB'
    );
  }

  static async getTransactions({
    page,
    limit,
    ordering,
    currency,
  }: QueryParamsTransactions): Promise<AxiosResponse> {
    return api({
      url: '/finance/api/v1/user-transactions/',
      method: 'get',
      params: {
        page: page || 1,
        page_size: limit,
        ordering: ordering,
        currency: currency,
      },
    });
  }

  static async payTransaction(formData: FormData): Promise<AxiosResponse> {
    return api.post('/api/v1/finances/pay', formData);
  }

  static async refillPayment(
    amount: number
  ): Promise<AxiosResponse<RefillResponse>> {
    return api<RefillResponse>({
      url: `/finance/api/v1/refill/payment/`,
      method: 'get',
      params: {
        service: 'paymaster',
        currency: 'RUB',
        amount: amount,
        return_url: process.env.REACT_APP_RETURN_URL_RU,
      },
    });
  }
}
