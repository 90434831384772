import * as React from 'react';
import { SVGProps } from 'react';

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 1L5.75769 2.96998C5.93898 3.44135 6.02963 3.67703 6.17059 3.87527C6.29552 4.05097 6.44903 4.20448 6.62473 4.32941C6.82297 4.47037 7.05865 4.56102 7.53002 4.74231L9.5 5.5L7.53002 6.25769C7.05866 6.43898 6.82297 6.52963 6.62473 6.67059C6.44903 6.79552 6.29552 6.94903 6.17059 7.12473C6.02963 7.32297 5.93898 7.55865 5.75769 8.03002L5 10L4.24231 8.03002C4.06102 7.55865 3.97037 7.32297 3.82941 7.12473C3.70448 6.94903 3.55097 6.79552 3.37527 6.67059C3.17703 6.52963 2.94135 6.43898 2.46998 6.25769L0.5 5.5L2.46998 4.74231C2.94135 4.56102 3.17703 4.47037 3.37527 4.32941C3.55097 4.20448 3.70448 4.05097 3.82941 3.87527C3.97037 3.67703 4.06102 3.44135 4.24231 2.96998L5 1Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
