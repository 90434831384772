import * as React from 'react';
import { SVGProps } from 'react';
import classes from './classes.module.scss';

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 14"
    width={props.width}
    height={props.height}
    fill={props.fill || '#A09CAC'}
    className={classes.icon}
    {...props}
  >
    <path d="M13.8623 0H15.3426C15.4709 0 15.5459 0.111638 15.4689 0.191595L9.00781 7L15.4689 13.8084C15.5459 13.8884 15.4709 14 15.3426 14H13.8623C13.7617 14 13.6669 13.9623 13.6077 13.9004L8.00037 7.99267L2.39304 13.9004C2.33383 13.9623 2.23909 14 2.13843 14H0.658147C0.529856 14 0.454854 13.8884 0.529856 13.8084L6.99096 7L0.529856 0.191595C0.454855 0.111638 0.529856 0 0.658147 0H2.13844C2.23909 0 2.33383 0.0377156 2.39304 0.0995691L8.00037 6.00733L13.6077 0.0995691C13.6669 0.0377156 13.7617 0 13.8623 0Z" />
  </svg>
);
