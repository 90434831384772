import * as React from 'react';
import { SVGProps } from 'react';

export const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.36 4H4.64C4.286 4 4 4.286 4 4.64V19.36C4 19.714 4.286 20 4.64 20H19.36C19.714 20 20 19.714 20 19.36V4.64C20 4.286 19.714 4 19.36 4ZM18.56 13.28H12.32V10.72H18.56V13.28ZM18.56 18.56H5.44V5.44H18.56V9.44H11.68C11.326 9.44 11.04 9.726 11.04 10.08V13.92C11.04 14.274 11.326 14.56 11.68 14.56H18.56V18.56ZM13.36 12C13.36 12.2122 13.4443 12.4157 13.5943 12.5657C13.7443 12.7157 13.9478 12.8 14.16 12.8C14.3722 12.8 14.5757 12.7157 14.7257 12.5657C14.8757 12.4157 14.96 12.2122 14.96 12C14.96 11.7878 14.8757 11.5843 14.7257 11.4343C14.5757 11.2843 14.3722 11.2 14.16 11.2C13.9478 11.2 13.7443 11.2843 13.5943 11.4343C13.4443 11.5843 13.36 11.7878 13.36 12Z" />
  </svg>
);
