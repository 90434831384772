import { Request } from 'tools/request';

export async function WithdrawCoupon({ access }: { access: string }) {
  const result = await Request({
    type: 'PUT',
    url: `${process.env.REACT_APP_API}/api/v1/basket/remove_coupon/`,
    isProtected: true,
    access: access,
  });

  return result;
}
