import { Helmet } from 'react-helmet';
import { ITitleAndDescription } from './TitleAndDescription.props';
import { useTitleAndDescription } from './useTitleAndDescription';

export const TitleAndDescription = ({
  title,
  pageName,
}: ITitleAndDescription) => {
  const { i18n, decodedTitle, decodedDescription } = useTitleAndDescription(
    pageName,
    title
  );

  return (
    <Helmet key={i18n.language}>
      <title>{decodedTitle}</title>

      <meta name="description" content={decodedDescription} />
    </Helmet>
  );
};
