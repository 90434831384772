import { useTranslation } from 'react-i18next';
import styles from './FilterData.module.scss';
import { ArrowUpIcon } from 'components/shared/Icons/ArrowUpIcon';
import { FilterDataProps } from './FilterData.props';

const FilterData = ({ setOrdering }: FilterDataProps) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li className={styles.item} onClick={() => setOrdering('created_at')}>
        <ArrowUpIcon />
        {t('low to high data')}
      </li>

      <li className={styles.item} onClick={() => setOrdering('-created_at')}>
        <div className={styles.down}>
          <ArrowUpIcon />
        </div>
        {t('high to low data')}
      </li>
    </ul>
  );
};

export default FilterData;
