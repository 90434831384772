import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import classes from './AnnounceCard.module.scss';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import { Button } from 'components/shared/Button';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { forwardRef, useLayoutEffect, useRef } from 'react';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { observer } from 'mobx-react-lite';
import clamp from 'clamp-js';

interface AnnounceCardProps {
  title: string;
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  nextLink?: string;
  onSubscribeEvent?: () => void;
  disabledEventButton?: boolean;
  isLoadingSubscribe?: boolean;
}

const AnnounceCard = forwardRef<HTMLDivElement, AnnounceCardProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const titleRef = useRef<HTMLSpanElement | null>(null);

    useLayoutEffect(() => {
      if (titleRef.current) {
        clamp(titleRef.current, { clamp: 2 });
      }
    }, []);

    return (
      <div ref={ref} className={classes.card}>
        <span ref={titleRef} className={classes.card__title}>
          {props.title}
        </span>
        <div className={classes.card__marks}>
          {props.eventDate && (
            <div className={classes.card__mark}>
              <PurpleTimeIcon />
              {props.eventDate}
            </div>
          )}
          {props.eventLocation ? (
            <div className={classes.card__mark}>
              <PinIcon />
              {props.eventLocation}
            </div>
          ) : props.eventLink ? (
            <Link to={props.eventLink || '#'} className={classes.card__link}>
              <LinkIcon />
              {'Подключиться'}
            </Link>
          ) : (
            <span className={classes.card__link}>
              {'Ссылка будет анонсирована позднее'}
            </span>
          )}
        </div>
        {!props.nextLink &&
          (props.disabledEventButton ? (
            <Button
              theme="primary-light"
              className={clsx(classes.card__button, classes.disabled)}
            >
              Участвую
              <CheckIcon />
            </Button>
          ) : (
            <Button
              theme={'primary'}
              className={classes.card__button}
              onClick={props.onSubscribeEvent}
              disabled={props.isLoadingSubscribe}
            >
              Буду участвовать
            </Button>
          ))}
        {props.nextLink && (
          <Button
            theme={'primary-light'}
            className={clsx(
              classes.card__button,
              classes['card__button--next']
            )}
            onClick={() => navigate(props.nextLink || '#')}
          >
            Следующее мероприятие
            <LeftArrowIcon />
          </Button>
        )}
      </div>
    );
  }
);

export default observer(AnnounceCard);
