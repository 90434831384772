import { yupResolver } from '@hookform/resolvers/yup';
import { PLATFORM } from 'constants/enums';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Request } from 'tools/request';
import { OpenModalType } from 'types/util-types';
import * as yup from 'yup';

interface FormValues {
  email: string;
  recaptcha?: string;
}

export const useRestoreModal = () => {
  const isDisableCaptcha = process.env.REACT_APP_DISABLE_RECAPCHA === 'true';

  const { t, i18n } = useTranslation();
  const restoreValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid Email'))
      .required(t('This field is required')),
    recaptcha: isDisableCaptcha
      ? yup.string()
      : yup.string().required(t('This field is required')),
  });

  const {
    getValues,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(restoreValidationSchema),
    mode: 'all',
  });

  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState<OpenModalType>({
    desktop: false,
    mobile: false,
  });
  const [resendRecaptcha, setResendRecaptcha] = useState('');

  const { DESKTOP, MOBILE } = PLATFORM;

  const { email } = getValues();

  const submit = async () => {
    const { email, recaptcha } = getValues();
    const formData = new FormData();

    formData.append('email', email);
    if (!isDisableCaptcha && recaptcha)
      formData.append(
        'g-recaptcha-response',
        step === 0 ? recaptcha : resendRecaptcha
      );

    await Request({
      type: 'POST',
      url: `${process.env.REACT_APP_API}/api/v1/auth/restore/`,
      data: formData,
    })
      .then(() => {
        setStep(1);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setError('email', {
            type: 'server',
            message: error.response.data?.email[0],
          });
        }
      });
  };

  const changeLanguage = (langObj: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const handleResend = async () => {
    await submit();
  };

  const handleOpenModal = (platform: string) => {
    if (platform === DESKTOP) {
      setOpenModal({ ...openModal, desktop: true });
    } else if (platform === MOBILE) {
      setOpenModal({ ...openModal, mobile: true });
    }
  };

  return {
    i18n,
    changeLanguage,
    step,
    t,
    handleSubmit,
    submit,
    control,
    errors,
    isValid,
    email,
    handleOpenModal,
    DESKTOP,
    MOBILE,
    handleResend,
    setResendRecaptcha,
    setOpenModal,
    openModal,
    isDisableCaptcha,
  };
};
