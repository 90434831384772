import { Request } from 'tools/request';

interface Props {
  access: string;
  refresh: string;
  reportID: string;
}

export async function GetDemoDashboardParams({
  access,
  refresh,
  reportID,
}: Props) {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/dashboards/demo_params/${reportID}/`,
    isProtected: true,
  });

  return result;
}
