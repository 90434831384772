import { useState } from 'react';
import {
  usePagination,
  useTable,
  useSortBy,
  ColumnInstance,
  Cell,
} from 'react-table';
import classes from './BonusTable.module.scss';
import clsx from 'classnames';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import _ from 'lodash';
import { TableProps } from './BonusTable.props';
import { useTranslation } from 'react-i18next';
import ColorSticker from 'components/shared/ColorSticker';
import AmountCell from '../AmountCell';
import FilterSearch from 'components/shared/FilterSearch/FilterSearch';
import FilterData from 'components/shared/TableTransactions/FilterData/FilterData';
import FilterAmount from 'components/shared/TableTransactions/FilterAmount/FilterAmount';
import { observer } from 'mobx-react-lite';
import { BonusTablePagination } from '../BonusTablePagination';

const BonusTable = ({
  data,
  columns,
  additionalClassNames,
  id,
  customPageCount,
  currentPage,
  setCurrentPage,
  setOrdering,
}: TableProps) => {
  const { t } = useTranslation();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({ columns, data });

  const renderCellData = function (cell: Cell) {
    const textValue = () => (
      <>
        <div className={classes.table__header}>{cell.value?.label}</div>

        {cell.value?.description && (
          <div className={classes.table__text}>
            {cell.value.description.text}
          </div>
        )}
      </>
    );
    switch (cell?.column?.id) {
      case 'date':
      case 'transactions':
      case 'targets':
        return textValue();
      case 'status':
        let color: string = '';
        const type = cell.value?.type;
        if (type === 1) {
          color = 'red';
        }

        if (type === 2) {
          color = 'green';
        }

        if (type === 3) {
          color = 'yellow';
        }

        if (type === 4) {
          color = 'blue';
        }

        return (
          <ColorSticker
            text={cell.value?.label}
            className={classes[`table__sticker--${color}`]}
          />
        );

      case 'amount':
        return (
          <AmountCell
            type={+cell.value?.label > 0 ? 'INCOME' : 'OUTCOME'}
            value={Math.abs(+cell.value?.label)}
          />
        );

      default:
        return textValue();
    }
  };

  const renderFilterByHeader = (header: string) => {
    switch (header) {
      case 'Data':
        return (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<FilterTableIcon />}
          >
            {() => <FilterData setOrdering={setOrdering} />}
          </FilterSearch>
        );
      // case 'Transaction':
      //   return (
      //     <FilterSearch
      //       classNameForButton={classes.table__search}
      //       buttonElement={<FilterTableIcon />}
      //     >
      //       {/* <FilterSelects
      //         filter={transactionFilter}
      //         setFilter={setTransactionFilter}
      //         filtersItem={mockFilterTransaction}
      //       /> */}
      //     </FilterSearch>
      //   );
      case 'Sum':
        return (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<FilterTableIcon />}
            isLastFilter={true}
          >
            {() => <FilterAmount setOrdering={setOrdering} />}
          </FilterSearch>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div
        className={clsx(
          classes.table,
          additionalClassNames && String(additionalClassNames)
        )}
        id={id}
      >
        <table {...getTableProps()} className={classes.table__wrapper}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={classes.table__row}
                key={_.uniqueId()}
              >
                <th className={classes.table__cell}></th>

                {headerGroup?.headers?.map((column: ColumnInstance<{}>) => {
                  if (column.render('header'))
                    return (
                      <th
                        className={classes.table__cell}
                        {...column.getHeaderProps()}
                        key={_.uniqueId()}
                      >
                        {t(column.render('header') as string)}

                        {renderFilterByHeader(
                          column.render('header') as string
                        )}
                      </th>
                    );

                  return null;
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows?.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  className={classes.table__content}
                  key={_.uniqueId()}
                >
                  {row?.cells?.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={classes.table__cell}
                        key={_.uniqueId()}
                      >
                        <div className={classes.table__cell__container}>
                          {renderCellData(cell)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {customPageCount > 1 && (
        <div className={classes.pagination}>
          <BonusTablePagination
            currentPage={currentPage}
            totalPages={customPageCount}
            setPageNumber={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default observer(BonusTable);
