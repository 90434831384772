import { SVGProps } from 'react';

export const BrokenCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="184"
    height="184"
    viewBox="0 0 184 184"
    fill="none"
    {...props}
  >
    <path
      d="M0.827493 129.202C4.34345 146.808 14.114 152.22 21.9914 150.22C37.2254 146.353 75.1049 146.065 109.694 170.647C155.506 203.205 172.099 170.657 162.278 132.589C152.457 94.5215 153.209 58.0905 174.908 42.1353C196.607 26.1801 177.926 -8.95663 129.85 14.5614C98.7818 29.7596 79.3844 21.2869 69.5487 13.3907C60.2789 5.94763 49.9065 -1.96436 38.486 0.438696C24.6282 3.35532 17.5025 14.1109 32.609 44.1376C54.0162 86.6885 -7.05337 89.74 0.827493 129.202Z"
      fill="url(#paint0_linear_1480_6665)"
      fillOpacity="0.2"
    />
    <path
      d="M92.055 125.114L93.3465 126.731L92.9582 139.361L40.5167 150.455C40.1298 150.537 39.7313 150.538 39.3441 150.458C38.9568 150.378 38.5884 150.217 38.2598 149.987C37.9312 149.756 37.6489 149.459 37.429 149.113C37.2091 148.767 37.056 148.378 36.9783 147.97L21.6382 67.1214C21.5599 66.7132 21.5587 66.2927 21.6348 65.884C21.7109 65.4754 21.8627 65.0866 22.0816 64.7401C22.3004 64.3936 22.582 64.0962 22.91 63.865C23.2381 63.6337 23.6062 63.4733 23.9932 63.3928L79.851 51.5679L77.4063 60.5623C77.4063 60.5623 81.8766 69.4639 82.7417 70.9085L78.6312 89.6801L90.7717 102.374L86.9217 118.655L92.055 125.114Z"
      fill="#F14B4B"
    />
    <path
      d="M84.034 104.705L40.6667 113.882C40.5347 113.91 40.4492 114.046 40.4756 114.185L41.756 120.931C41.7824 121.07 41.9108 121.161 42.0428 121.133L85.4101 111.956C85.5421 111.928 85.6276 111.792 85.6011 111.653L84.3208 104.907C84.2944 104.768 84.166 104.677 84.034 104.705Z"
      fill="white"
    />
    <path
      d="M86.9219 118.656L92.0547 125.115L45.9336 134.871C45.543 134.953 45.1377 134.868 44.8062 134.635C44.4747 134.402 44.2439 134.04 44.1643 133.628L43.3815 129.49C43.3419 129.285 43.341 129.075 43.3788 128.87C43.4166 128.665 43.4923 128.47 43.6017 128.297C43.7111 128.123 43.852 127.974 44.0162 127.858C44.1804 127.742 44.3646 127.662 44.5584 127.621L86.9219 118.656Z"
      fill="white"
    />
    <path
      d="M180.673 52.7562L160.492 132.402C160.284 133.214 159.781 133.906 159.092 134.327C158.402 134.748 157.583 134.864 156.813 134.649L97.2368 117.817L103.628 107.427L102.908 104.768L100.662 96.4788L100.664 96.4711L111.227 84.0981L105.361 65.9286L116.969 51.6517L116.445 38.6291L122.909 33.1475L178.553 48.8663C179.322 49.0864 179.977 49.6195 180.374 50.3487C180.772 51.0779 180.879 51.9437 180.673 52.7562Z"
      fill="#F14B4B"
    />
    <path
      d="M167.546 53.9707L156.704 50.9074C154.104 50.1727 151.433 51.8028 150.737 54.5483L147.836 65.996C147.14 68.7414 148.684 71.5626 151.284 72.2973L162.125 75.3606C164.725 76.0953 167.397 74.4652 168.093 71.7198L170.994 60.2721C171.69 57.5266 170.146 54.7054 167.546 53.9707Z"
      fill="white"
    />
    <path
      d="M112.911 101.577L111.883 105.655C111.779 106.061 111.527 106.408 111.182 106.618C110.838 106.829 110.428 106.887 110.043 106.779L102.908 104.767L100.662 96.478L111.854 99.6372C112.044 99.6903 112.223 99.7827 112.38 99.9092C112.536 100.036 112.667 100.194 112.766 100.374C112.864 100.555 112.928 100.754 112.953 100.96C112.978 101.167 112.963 101.377 112.911 101.577Z"
      fill="white"
    />
    <path
      d="M89.6912 46.6305C89.4071 46.3034 89.2542 45.8728 89.2647 45.4291C89.2751 44.9855 89.4482 44.5634 89.7474 44.2517L100.796 32.279C101.456 31.5639 102.529 31.5469 103.156 32.2419L104.029 33.2105C104.656 33.9052 104.585 35.0324 103.873 35.6897L91.95 46.6938C91.6383 46.9925 91.2294 47.1526 90.8086 47.1408C90.3878 47.129 89.9875 46.9462 89.6912 46.6305Z"
      fill="#F14B4B"
    />
    <path
      d="M83.1659 42.5239C83.0429 42.4641 82.9323 42.3792 82.8404 42.2742C82.7485 42.1692 82.677 42.0461 82.6301 41.912C82.5833 41.7778 82.5619 41.6352 82.5672 41.4923C82.5725 41.3494 82.6045 41.2091 82.6612 41.0792L86.8009 31.1114C86.917 30.8264 87.1355 30.6018 87.4083 30.4868C87.6812 30.3717 87.9861 30.3758 88.2561 30.498L89.0178 30.8618C89.1476 30.9253 89.2635 31.0165 89.3582 31.1297C89.4529 31.2428 89.5243 31.3755 89.568 31.5193C89.6117 31.6631 89.6267 31.8149 89.6121 31.9651C89.5974 32.1153 89.5534 32.2607 89.4829 32.3921L84.5817 41.9966C84.4516 42.2563 84.2337 42.4547 83.9714 42.5524C83.7091 42.6502 83.4215 42.64 83.1659 42.5239Z"
      fill="#F14B4B"
    />
    <path
      d="M76.2942 44.1378C75.4258 44.299 74.5922 43.519 74.4046 42.3696L71.0727 21.9621C70.8738 20.743 71.4756 19.5845 72.3967 19.4135L73.6807 19.1754C74.6018 19.0046 75.4717 19.8901 75.5936 21.1233L77.6415 41.7691C77.7572 42.9318 77.1627 43.9767 76.2942 44.1378Z"
      fill="#F14B4B"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1480_6665"
        x1="54.2835"
        y1="0.139027"
        x2="93.9391"
        y2="183.581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F14B4B" />
        <stop offset="1" stopColor="#FFDADA" />
      </linearGradient>
    </defs>
  </svg>
);
