import React, { CSSProperties, FC, HTMLAttributes } from 'react';

const rotateObj: { [key: string]: CSSProperties | undefined } = {
  right: undefined,
  left: { transform: 'rotate(180deg)' },
};

interface IProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  rotate?: 'right' | 'left';
  width?: number;
  height?: number;
  fill?: string;
}

export const ArrowPagination: FC<IProps> = ({
  className,
  width,
  height,
  fill,
  rotate,
}) => (
  <svg
    className={className}
    width={width ?? 16}
    height={height ?? 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={rotate ? rotateObj[rotate] : undefined}
  >
    <path
      d="M6 3.33321L10.6421 7.97532L6 12.6174"
      stroke={fill ?? '#D23439'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
