import { QueryParamTypes } from 'constants/queryParamTypes';
import classes from './Acts.module.scss';
import { TFunction } from 'i18next';
import { ProjectActResult } from 'types/project';

export const columnsArray = (t: TFunction) => [
  {
    header: '',
    accessor: 'serialNumber',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Projects'),
    accessor: 'project',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Acts'),
    accessor: 'act',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Status'),
    accessor: 'status',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Period'),
    accessor: 'period',
    footer: (props: { column: { id: string | number } }) => props.column.id,
  },
  {
    header: t('Sum'),
    accessor: 'amount',
    footer: (props: { column: { id: string | number } }) => props.column.id,
    sortType: 'basic'
  },
];

// act из бека

export const tableData = (acts: ProjectActResult[], t: TFunction) => {
  return acts?.map((act, idx) => {
    return {
      key: `act-${act.id}`,
      serialNumber: {
        value: idx + 1,
        className: classes.table__header,
      },
      project: {
        value: act.project,
      },
      act: {
        value: act.act,
        filePath: 'sdjkdskjjkdsdskj',
      },
      status: {
        value: act.status,
        label: 'LAVEL',
        type: 'TYPE',
        className: classes.table__header,
      },
      period: {
        value: act.period,
      },
      amount: {
        value: act.amount,
      },
    };
  });
};

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    'quick-filter': quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};
