import { useEffect, useState } from 'react';
import { Label } from './Label';
import { Icon } from 'components/shared/Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import { PhoneInputMaskProps } from './PhoneInput.props';

export const PhoneInputMask = ({
  label,
  labelPosition = 'left',
  placeholder = '',
  disabled = false,
  error = false,
  value = '',
  icon,
  iconFill = '#A09CAC',
  iconHeight = '18px',
  iconWidth = '18px',
  className = '',
  onChange,
  onBlur,
}: PhoneInputMaskProps) => {
  const [activePlaceholder, setActivePlaceHolder] = useState(placeholder);
  const classNames = clsx(style.input, icon && style.inputicon);

  useEffect(() => {
    setActivePlaceHolder(placeholder);
  }, [placeholder]);

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {icon && (
          <div className={style.iconWrap}>
            <Icon
              name={icon}
              width={iconWidth}
              height={iconHeight}
              iconFill={iconFill}
            />
          </div>
        )}

        <PhoneInput
          specialLabel={''}
          country={''}
          inputProps={{
            autoComplete: 'false',
          }}
          value={value}
          containerClass={classNames}
          inputClass={error && style.inputError}
          placeholder={activePlaceholder}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          onFocus={() => setActivePlaceHolder('+')}
          onBlur={() => {
            setActivePlaceHolder(placeholder);
            onBlur && onBlur();
          }}
        />
      </div>
    </div>
  );
};
