import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import CardsContainerSkeleton from 'components/shared/Skeleton/CardsContainerSkeleton/CardsContainerSkeleton';

export interface Props {
  children?: React.ReactNode;
  title: string;
}

export const LoaderWrapperCardsContainer = ({ title, children }: Props) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<CardsContainerSkeleton title={title} />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
