import { Button } from 'components/shared/Button';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { Link } from 'components/shared/Link';
import { PageLoader } from 'components/shared/PageLoader';
import { TabFilter } from 'components/shared/TabFilter';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { observer } from 'mobx-react-lite';
import classes from './SearchResults.module.scss';
import SingleResult from './SingleResult/SingleResult';
import { useSearchResults } from './useSearchResults';

export const SearchResultsContainer = observer(() => {
  const {
    parsedHash,
    tabs,
    solutions,
    projects,
    t,
    isLoading,
    handleSeeMore,
    activeTab,
    setActiveTab,
    isNoResults,
  } = useSearchResults();

  const fromPage = (parsedHash?.from as string) || '/solutions';
  const searchTerm = (parsedHash?.search as string) || '';

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <TitleAndDescription pageName="SearchResults" />

      <div className={classes.wrapper}>
        <Link className={classes.back} href={`${decodeURIComponent(fromPage)}`}>
          <PurpleArrowLeftIcon /> {t('Back')}
        </Link>

        <div className={classes.container}>
          <div className={classes.title}>
            {`${t('Search results for')} `}
            <span className={classes['search-term']}>{`«${searchTerm}»`}</span>
          </div>
          <TabFilter
            tabArray={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={classes['tab-name']}>{activeTab.name}</div>

          {isNoResults ? (
            <span className={classes['no-results']}>{t('No results')}</span>
          ) : (
            <SingleResult
              activeTab={activeTab}
              solutions={solutions}
              projects={projects}
            />
          )}

          {((activeTab.id === 1 && solutions?.next) ||
            (activeTab.id === 2 && projects?.next)) &&
            activeTab.count > 3 && (
              <div className={classes.pagination}>
                <Button
                  onClick={() => handleSeeMore(activeTab?.name_key)}
                  theme="default"
                  className={classes.button}
                >
                  {t('See more')}
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
});
