import classes from './BonusListItem.module.scss';
import ColorSticker from 'components/shared/ColorSticker';
import AmountCell from '../AmountCell';
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';
import { FinancesTransaction } from 'types';

interface BonusListItemProps {
  transaction: FinancesTransaction;
}

const BonusListItem = ({ transaction }: BonusListItemProps) => {
  let color: string = '';
  switch (transaction.money_transfer_status?.value) {
    case 1:
      color = 'red';
      break;
    case 2:
      color = 'green';
      break;
    case 3:
      color = 'yellow';
      break;
    case 4:
      color = 'blue';
      break;
    default:
      break;
  }

  const transactionDate = new Date(transaction.created_at);

  return (
    <li className={classes.wrapper}>
      <div className={classes.transaction}>
        <div className={classes.transaction__header}>
          <ColorSticker
            text={transaction.money_transfer_status?.label}
            className={classes[`transaction__sticker--${color}`]}
          />
          <span
            className={classes.transaction__date}
          >{`${getFormattedDate(transactionDate)},${getFormattedTime(transactionDate)}`}</span>
        </div>
        {(transaction.project || transaction.service) && (
          <div className={classes.transaction__body}>
            <span>
              {transaction.project
                ? transaction.project?.name
                : transaction.service?.name}
            </span>
            {transaction.project && <span>ID {transaction.project?.id}</span>}
          </div>
        )}
        <div className={classes.transaction__footer}>
          <span>{transaction.money_transfer_description?.label}</span>
          <span>{transaction.type?.label}</span>
        </div>
      </div>

      <AmountCell
        type={+transaction?.amount > 0 ? 'INCOME' : 'OUTCOME'}
        value={Math.abs(+transaction?.amount)}
        className={classes.price}
      />
    </li>
  );
};

export default BonusListItem;
