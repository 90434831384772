import { StarIcon } from 'components/shared/Icons/StarIcon';
import classes from './ClientPoints.module.scss';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface ClientPointsProps {
  title: string;
  points: number;
  firstLinkName?: string;
  secondLinkName?: string;
  className?: string;
  classNamePoints?: string;
}

const ClientPoints = ({
  title,
  points,
  firstLinkName,
  secondLinkName,
  className,
  classNamePoints,
}: ClientPointsProps) => (
  <div className={clsx(classes.clientPoints, className)}>
    <div className={classes.clientPoints__header}>
      <span className={classes.clientPoints__title}>{title}</span>
      <div className={clsx(classes.clientPoints__points, classNamePoints)}>
        <StarIcon />
        <FormatPrice currency="" price={points} />
      </div>
    </div>
    <div>
      {firstLinkName && (
        <Link
          to={'/programma-rosta/history'}
          className={classes.clientPoints__link}
        >
          {firstLinkName}
        </Link>
      )}

      {secondLinkName && (
        <Link
          to={'/programma-rosta/rules#kak-poluchit-grant'}
          className={classes.clientPoints__link}
        >
          {secondLinkName}
        </Link>
      )}
    </div>
  </div>
);

export default ClientPoints;
