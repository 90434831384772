import React, { FC } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

interface DashboardPowerBIProps {
  reportID: string;
  embedUrl: string;
  accessToken: string;
}

export const DashboardPowerBI: FC<DashboardPowerBIProps> = ({
  reportID,
  embedUrl,
  accessToken,
}) => {
  const embedConfig = {
    type: 'report',
    id: reportID,
    embedUrl: embedUrl,
    accessToken: accessToken,
    tokenType: models.TokenType.Embed,
    settings: { background: models.BackgroundType.Transparent },
  };

  return (
    <>
      <PowerBIEmbed embedConfig={embedConfig} />
    </>
  );
};
