import * as React from 'react';
import { SVGProps } from 'react';

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_3918_3596)">
      <path
        d="M6.6665 6.6665V4.33317C6.6665 3.39975 6.6665 2.93304 6.84816 2.57652C7.00795 2.26292 7.26292 2.00795 7.57652 1.84816C7.93304 1.6665 8.39975 1.6665 9.33317 1.6665H15.6665C16.5999 1.6665 17.0666 1.6665 17.4232 1.84816C17.7368 2.00795 17.9917 2.26292 18.1515 2.57652C18.3332 2.93304 18.3332 3.39975 18.3332 4.33317V10.6665C18.3332 11.5999 18.3332 12.0666 18.1515 12.4232C17.9917 12.7368 17.7368 12.9917 17.4232 13.1515C17.0666 13.3332 16.5999 13.3332 15.6665 13.3332H13.3332M4.33317 18.3332H10.6665C11.5999 18.3332 12.0666 18.3332 12.4232 18.1515C12.7368 17.9917 12.9917 17.7368 13.1515 17.4232C13.3332 17.0666 13.3332 16.5999 13.3332 15.6665V9.33317C13.3332 8.39975 13.3332 7.93304 13.1515 7.57652C12.9917 7.26292 12.7368 7.00795 12.4232 6.84816C12.0666 6.6665 11.5999 6.6665 10.6665 6.6665H4.33317C3.39975 6.6665 2.93304 6.6665 2.57652 6.84816C2.26292 7.00795 2.00795 7.26292 1.84816 7.57652C1.6665 7.93304 1.6665 8.39975 1.6665 9.33317V15.6665C1.6665 16.5999 1.6665 17.0666 1.84816 17.4232C2.00795 17.7368 2.26292 17.9917 2.57652 18.1515C2.93304 18.3332 3.39975 18.3332 4.33317 18.3332Z"
        stroke="#D3C4C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3918_3596">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
