import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import classes from '../Switch.module.scss';
import { SwitchNavItemProps } from './SwitchNavItem.props';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';

const SwitchNavItem = ({
  to,
  isActive,
  icon,
  label,
  isHidden,
  withoutShape,
}: SwitchNavItemProps) => {
  return (
    <NavLink
      to={to}
      className={clsx(
        classes.link,
        isHidden && classes.isHidden,
        isActive && classes.active
      )}
    >
      <div className={clsx(classes.icon, !withoutShape && classes.iconShape)}>
        {icon}
      </div>
      <div className={classes.back}>
        <ArrowDownIcon fill={'#898989'} width={'16px'} height={'8px'} />
      </div>
      <div className={classes.name}>{label}</div>
    </NavLink>
  );
};

export default SwitchNavItem;
