const dangerousText: string =
  'D' + 'ang' + 'er' + 'o' + 'us' + ' t' + 'ex' + 't';

export function checkTextForHTML(text: string): {
  __html: string | TrustedHTML;
} {
  const dangerousTags: string[] = ['script', 'iframe', 'object'];

  for (let i = 0; i < dangerousTags.length; i++) {
    const regex = new RegExp('<' + dangerousTags[i] + '[\\s\\S]*?>', 'gi');
    if (regex.test(text)) {
      return { __html: dangerousText };
    }
  }

  return { __html: text };
}

//converts links in html string to <a/> tag
export function formatHtmlText(text: string): { __html: string | TrustedHTML } {
  if (!text) return { __html: '' };

  const checkedText: string = checkTextForHTML(text).__html as string;
  if (checkedText === dangerousText) return { __html: dangerousText };

  const linkRegex: RegExp =
    /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1(?:\s+[^>]*)?>.*?<\/a>|(\bhttps?:\/\/\S+)/gi;
  const formattedText: string = text.replace(
    linkRegex,
    (match: string, p1: string, p2: string, p3: string) => {
      if (p3) {
        return `<a href="${p3}" style="text-decoration: underline;" target="_blank">${p3}</a>`;
      } else {
        return match;
      }
    }
  );

  return { __html: formattedText };
}
