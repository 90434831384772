import { FC } from 'react';
import { HotDealsItem } from '../HotDealsItem';
import { HotDealsContainerProps } from './HotDealsContainer.props';

export const HotDealsContainer = ({
  activeDiscount,
  isFeature,
}: HotDealsContainerProps) => {
  const dealsArray = activeDiscount.discounts.filter(
    (item) => item.type === 'HOT_DEAL' && item.is_active
  );

  return (
    <>
      {dealsArray.map((item: any) => (
        <HotDealsItem
          key={item.id}
          deals={item}
          infoDiscount={activeDiscount}
          isFeature={isFeature}
        />
      ))}
    </>
  );
};
