import { Button } from 'components/shared/Button';
import classes from './ClientPreview.module.scss';
import { useLayoutEffect, useRef } from 'react';
import clamp from 'clamp-js';
import { useNavigate } from 'react-router-dom';

interface ClientPreviewProps {
  title: string;
  name: string;
  description: string;
  buttonName: string;
}

const ClientPreview = ({
  title,
  name,
  description,
  buttonName,
}: ClientPreviewProps) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (textRef.current) {
      clamp(textRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <div className={classes.clientPreview}>
      <div className={classes.clientPreview__header}>
        <span className={classes.clientPreview__title}>{title}</span>
        <span className={classes.clientPreview__name}>{name}</span>
        <p ref={textRef} className={classes.clientPreview__description}>
          {description}
        </p>
      </div>
      <Button
        onClick={() => navigate('/account/personal')}
        theme={'light'}
        className={classes.clientPreview__button}
      >
        {buttonName}
      </Button>
    </div>
  );
};

export default ClientPreview;
