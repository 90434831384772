import { TFunction } from 'i18next';
// styles for onboarding for Account
import classesAccount from 'containers/Account/Account.module.scss';
// styles for onboarding for Cart
import cartClasses from 'containers/Cart/Cart.module.scss';
import cartListClasses from 'containers/Cart/CartList/CartList.module.scss';
import cartTotalClasses from 'containers/Cart/Total/Total.module.scss';
import cartMenuClasses from 'components/shared/Menu/Menu.module.scss';
// styles for onboarding for ServiceDetail
import serviceDetailClasses from 'containers/ServiceDetail/ServiceDetail.module.scss';
import serviceDetailCartClasses from 'components/common/Header/Header.module.scss';
import serviceDetailPriceClasses from 'containers/ServiceDetail/Price/Price.module.scss';
import serviceDetailCartManagerClasses from 'containers/ServiceDetail/CardManager/CardManager.module.scss';
import serviceDetailRecommendedServiceClasses from 'containers/RecommendedService/RecommendedService.module.scss';
// styles for onboarding for Locale
import localeClasses from 'containers/Locale/LocalePageContainer.module.scss';
// styles for onboarding for Solutions
import solutionClasses from 'containers/CardsContainer/CardsContainer.module.scss';
import solutionProductListClasses from 'containers/CardsContainer/ProductList/ProductList.module.scss';
import solutionSearchClasses from 'components/shared/SearchComponent/SearchComponent.module.scss';
import solutionProductCardClasses from 'containers/CardsContainer/ProductList/ProductCard/ProductCard.module.scss';
// styles for onboarding for _________

export const LABELS = (t: any) => ({
  GOT_IT: t('Got it'),
  NEXT: t('Next'),
  BACK: t('Back'),
  DONE: t('Done'),
});

// onboarding for Account
export const accountMessages = (t: TFunction) => [
  {
    title: t(
      'The Account page displays information that was entered during the registration process. The fields are grouped into three blocks'
    ),
    intro: '',
  },
  {
    element: `.${classesAccount.inner}`,
    intro: t(
      'Personal information – email cannot be changed, as the account is linked to this identificator'
    ),
    position: 'right',
  },
  {
    element: `.${classesAccount.inner}`,
    position: 'right',
    intro: t(
      'Business information - please note that these fields are only available for editing until the first project is created. After that, it will only be possible to change the information through your personal manager, as it affects the analysis and preparation of commercial offers'
    ),
  },
  {
    element: `.${classesAccount.inner}`,
    intro: t('You can set a new password here'),
    position: 'right',
  },
];

export const accountMessagesRu = (t: TFunction) => [
  {
    title: t(
      'The Account page displays information that was entered during the registration process. The fields are grouped into three blocks'
    ),
    intro: '',
  },
  {
    element: `.${classesAccount.inner}`,
    intro: t(
      'Personal information – email cannot be changed, as the account is linked to this identificator'
    ),
    position: 'right',
  },
  {
    element: `.${classesAccount.inner}`,
    position: 'right',
    intro: t(
      'Business information - please note that these fields are only available for editing until the first project is created. After that, it will only be possible to change the information through your personal manager, as it affects the analysis and preparation of commercial offers'
    ),
  },
];

// onboarding for Cart
export const emptyCartMessages = (t: TFunction) => [
  {
    title: t(
      'Once you add solutions to your cart, they will appear on this screen'
    ),
    intro: '',
  },
];

export const cartMessages = (t: TFunction) => [
  {
    title: t(
      'On this page you will find all the solutions you have added to your Cart'
    ),
    intro: '',
  },
  {
    element: `.${cartListClasses.list}`,
    intro: t(
      'Checkboxes allow you to choose which of the items you are ready to order now - one, several or all of them'
    ),
    position: 'right',
    scrollTo: 'tooltip',
  },
  {
    element: `.${cartClasses.orderDetails}`,
    intro: t(
      'Order details will show your information on pricing of all chosen solutions and applied discounts'
    ),
    position: 'left',
  },
  {
    element: `.${cartTotalClasses.button}`,
    intro: t(
      "Once you've decided on the items you are ready to order now, click on the button 'Start projects'"
    ),
  },
  {
    element: `.${cartMenuClasses.projects}`,
    intro: t(
      'Click Projects in the top menu to see the list of created projects'
    ),
  },
];

// onboarding for ServiceDetail
export const serviceDetailMessages = (t: TFunction) => [
  {
    title: t(
      'On a service page card, you will find the detailed information about this item, including the type, name, pricing and payment details, description of the stages of work on the project'
    ),
    intro: '',
  },
  {
    element: `.${serviceDetailClasses.manager} .${serviceDetailCartManagerClasses.container}`,
    intro: t(
      'If you have any questions about a specific service, contact our manager by clicking "Get in touch" button next to the description'
    ),
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${serviceDetailRecommendedServiceClasses.wrapper}`,
    intro: t(
      'You can see our recommendations on another solutions that can also suit your goals'
    ),
    scrollToElement: true,
    scrollTo: 'tooltip',
    highlightClass: 'tooltip-recommended',
  },
  {
    element: `.${serviceDetailPriceClasses.buttons}`,
    intro: t(
      'When you are satisfied with the description of the solution and are ready to order it, click "Add to Cart"'
    ),
    scrollTo: 'tooltip',
  },
  {
    element: `.${serviceDetailCartClasses.cart}`,
    intro: t('Click on the cart icon to go to your shopping list'),
    scrollTo: 'tooltip',
    highlightClass: 'tooltip-cart',
  },
];

// onboarding for Projects
export const projectsHints = (t: TFunction) => [
  {
    title: t(
      'After you create a project, it will appear in this section. You can monitor Project stages and its expected dates here'
    ),
    intro: '',
  },
];

// onboarding for Locale
export const localeMessages = (userName: string, t: TFunction) => [
  {
    element: `.${localeClasses.preference}`,
    title: `${userName}, ${t('welcome to KIT Global Platform! ')}`,
    intro: t(
      "Let's start with defining your local preferences to show more relevant content. Please set appropriate currency, country and language that you want to see in the interface"
    ),
  },
];

// onboarding for Solutions
export const solutionsMessages = (t: TFunction) => [
  {
    element: `.${solutionProductListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${solutionProductListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${solutionClasses.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${solutionSearchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${solutionProductListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${solutionProductListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-buttons']} .${solutionProductCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
    disableInteraction: false,
  },
  {
    element: `.${solutionProductCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];
export const solutionsMessagesWithoutPaginationWithServices = (
  t: TFunction
) => [
  {
    element: `.${solutionProductListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${solutionProductListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${solutionClasses.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${solutionSearchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${solutionProductListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-buttons']} .${solutionProductCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];
export const solutionsMessagesWithPaginationWithServices = (t: TFunction) => [
  {
    element: `.${solutionProductListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${solutionProductListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${solutionClasses.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${solutionSearchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${solutionProductListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${solutionProductListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-buttons']} .${solutionProductCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];
export const solutionsMessagesWithPaginationWithoutServices = (
  t: TFunction
) => [
  {
    element: `.${solutionProductListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${solutionClasses.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${solutionSearchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${solutionProductListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${solutionProductListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-buttons']} .${solutionProductCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];
export const solutionsMessagesWithoutPaginationAndWithoutServices = (
  t: TFunction
) => [
  {
    element: `.${solutionProductListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${solutionClasses.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${solutionSearchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${solutionProductListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-buttons']} .${solutionProductCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${solutionProductCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];

// onboarding for Finances
export const financesMessages = (t: any) => [
  {
    title: t(
      'Here you can see the due time to pay an invoice, download issued invoices and control payment status'
    ),
    intro: '',
  },
];

// onboarding for ________
