import classes from './ErrorPage.module.scss';
import plug from 'assets/img/System/plug.png';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import { useAuthStore } from 'contexts/AuthContext';
import { ErrorPageProps } from './ErrorPageContainer.props';
import clsx from 'clsx';
import { Logo } from '../Logo';

export const ErrorPageContainer = ({
  errorMessage,
  title,
  errorInfo,
  isNotAuth = false,
}: ErrorPageProps) => {
  const { t } = useTranslation();
  const { isServerError } = useAuthStore();
  const { error, errorInfo: errorInfoData } = errorInfo || {};

  return (
    <div className={clsx(classes.wrap, isNotAuth && classes.isNotAuth)}>
      {isNotAuth && (
        <div className={classes.logo}>
          <Logo />
        </div>
      )}
      <div className={classes.inner}>
        <span className={classes.title}>{title}</span>
        <p className={classes.subtitle}>
          {errorMessage ||
            t(
              'Sorry, there are some technical issues. Please refresh the page or try again later'
            )}
        </p>
        {!isServerError && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error}
            <br />
            {errorInfoData?.componentStack}
          </details>
        )}
        {!isNotAuth && (
          <Button
            theme="primary"
            className={classes.button}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              !isServerError ? window.history.back() : window.location.reload();
            }}
          >
            {!isServerError ? t('Back') : t('Refresh')}
          </Button>
        )}
      </div>

      <div className={classes.img}>
        <img src={plug} alt="plug" />
      </div>
    </div>
  );
};
