import clsx from 'clsx';
import classes from './BonusTableContainer.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import BonusTable from '../components/BonusTable';
import {
  columnsArrayBonus,
  tableDataBonusTransactions,
} from '../bonusHistoryData';
import { useWindowWidth } from 'hooks/useWindowWidth';
import BonusList from '../components/BonusList';
import { useEffect, useState } from 'react';
import { financesStore } from 'stores/financesStore';
import { useNavigate } from 'react-router-dom';
import { OrderingTransactions } from 'services/finances';
import { observer } from 'mobx-react-lite';

interface BonusTableProps {
  className?: string;
}

const BonusTableContainer = ({ className }: BonusTableProps) => {
  const { t } = useTranslation();
  const { isMediaTablet, width } = useWindowWidth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(isMediaTablet ? 5 : 10);
  const [ordering, setOrdering] = useState<OrderingTransactions>('-created_at');
  const [customPageCount, setCustomPageCount] = useState(0);

  const { loadTransactions, transactions, isLoadingTransactions } =
    financesStore;

  useEffect(() => {
    setLimit(isMediaTablet ? 5 : 10);
  }, [isMediaTablet]);

  useEffect(() => {
    loadTransactions({
      page: page,
      limit: limit,
      ordering: ordering,
      currency: 'KOK',
    });
  }, [ordering, limit]);

  useEffect(() => {
    if (transactions?.count) {
      setCustomPageCount(Math.ceil(transactions.count / limit));
    }
  }, [transactions?.count, limit]);

  return transactions?.results?.length && transactions?.results?.length > 0 ? (
    width > 700 ? (
      <BonusTable
        data={tableDataBonusTransactions(transactions?.results || [])}
        columns={columnsArrayBonus()}
        setOrdering={setOrdering}
        customPageCount={customPageCount}
        currentPage={page}
        setCurrentPage={setPage}
      />
    ) : (
      <BonusList
        data={transactions?.results || []}
        setOrdering={setOrdering}
        ordering={ordering}
        customPageCount={customPageCount}
        currentPage={page}
        setCurrentPage={setPage}
      />
    )
  ) : (
    <div className={clsx(classes.empty)}>
      <div className={classes.empty__header}>{t('No content')}</div>
      <div className={classes.empty__text}>
        {'Здесь будут отображаться все операции с баллами'}
      </div>
      <Button
        theme={'primary'}
        size="middle"
        className={classes.empty__btn}
        onClick={() => navigate('/programma-rosta')}
      >
        {'Вернуться к программе роста'}
      </Button>
    </div>
  );
};

export default observer(BonusTableContainer);
