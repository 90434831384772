import { useEffect, useState } from 'react';

export function useIsVisible<T extends HTMLElement>(
  ref: React.RefObject<T>,
  threshold = 0.1,
  dependencies?: any[]
): boolean {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting !== isVisible) {
          setIsVisible(entry.isIntersecting);
        }
      },
      { threshold }
    );

    observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, threshold, isVisible, ...(dependencies || [])]);

  return isVisible;
}
