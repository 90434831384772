import { TFunction } from 'i18next';

export const companySizeList = (t: TFunction) => [
  {
    id: 'MICRO',
    name: t('Micro Business'),
    description: t('1-10 employees'),
    size: 1,
  },
  {
    id: 'SMALL',
    name: t('Small Business'),
    description: t('11-50 employees'),
    size: 2,
  },
  {
    id: 'MEDIUM',
    name: t('Medium-Sized Business'),
    description: t('51-250 employees'),
    size: 3,
  },
  {
    id: 'LARGE',
    name: t('Large Business'),
    description: t('251-1000 employees'),
    size: 4,
  },
  {
    id: 'CORPORATION',
    name: t('Corporation'),
    description: t('more than 1000 employees'),
    size: 5,
  },
];
