import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { CheckCircleGradientItem } from 'components/shared/Icons/CheckCircleGradientItem';
import { Modal } from 'components/shared/Modal';
import CartSkeleton from 'components/shared/Skeleton/CartSkeleton/CartSkeleton';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { LABELS } from 'constants/onboarding';
import { routers } from 'constants/routers';
import { CartList } from 'containers/Cart/CartList';
import { EmptyItems } from 'containers/EmptyItems';
import { RecommendedService } from 'containers/RecommendedService';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import '../CardsContainer/IntroTooltip.css';
import classes from './Cart.module.scss';
import FixedButton from './FixedButton/FixedButton';
import { Total } from './Total';
import { useCart } from './useCart';
import { HotDealsCounter } from 'containers/CardsContainer/HotDeals/HotDealsCounter';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { userStore } from 'stores/userStore';
import { CLIENT_ROLE, CLIENT_STATUS } from 'types/user';

export const CartContainer = observer(() => {
  const {
    t,
    isEmpty,
    data,
    count,
    selectedItems,
    cartId,
    setCoupon,
    applyCouponHandler,
    onCreateDeal,
    totalWithDiscounts,
    total,
    coupon,
    cartStore,
    onCloseDealModal,
    showModal,
    isShowFixedButton,
    withRecommendServices,
    couponError,
    buttonRef,
    withdrawCouponHandler,
    errorMessage,
    setCouponError,
  } = useCart();
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const enableHints = true;

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);

  const onboardingPath = isEmpty ? 'EMPTY_ITEMS' : 'CART';
  const hasCompletedOnboarding = useRef<'EMPTY_ITEMS' | 'CART' | null>(null);

  useEffect(() => {
    findOnboardingSteps(onboardingPath).then((res) => {
      setOnboarding(res);
    });
  }, [onboardingPath]);

  const chat = document.getElementById('fsw-btn');

  useEffect(() => {
    if (chat) {
      if (isShowFixedButton) {
        chat.style.setProperty('bottom', '130px', 'important');
      } else {
        chat.style.setProperty('bottom', '70px', 'important');
      }
    }
  }, [chat, isShowFixedButton]);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current === onboardingPath) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = onboardingPath;
  }, [onboarding?.id, onboardingPath]);

  const client = userStore.client;
  const isViewer = client?.permission.type === CLIENT_ROLE.VIEWER;
  const isAdminNotVerified =
    client && client.status !== CLIENT_STATUS.ACTIVE_ON_PLATFORM;

  const isDisabled = isViewer || isAdminNotVerified || !client;

  return (
    <>
      <div className={classes.wrapper}>
        <TitleAndDescription pageName="Cart" />

        {!isMobile && onboarding ? (
          <Steps
            enabled={true}
            steps={formatSteps(onboardingPath, onboarding.steps)}
            options={{
              tooltipClass: 'customTooltip',
              showBullets: true,
              doneLabel: GOT_IT,
              nextLabel: NEXT,
              prevLabel: BACK,
            }}
            initialStep={0}
            onExit={completeCurrentOnboarding}
          />
        ) : null}

        {!isEmpty && data ? (
          <div className={classes.container}>
            {data && (
              <div className={classes.title}>
                {t('Cart')}
                <span>{count}</span>
              </div>
            )}

            <Grid direction="row">
              <GridItem col={{ default: 12, xsup: 8 }} className={classes.left}>
                {data && (
                  <CartList
                    data={data}
                    cartId={Number(cartId)}
                    selectedItems={selectedItems}
                  />
                )}
              </GridItem>

              <GridItem
                col={{ default: 12, xsup: 4 }}
                className={classes.right}
              >
                <div
                  className={clsx(
                    classes.orderDetails,
                    !enableHints && classes.sticky
                  )}
                  data-step-cart-2
                >
                  {data && (
                    <Total
                      total={total}
                      createDeals={onCreateDeal}
                      applyCouponHandler={applyCouponHandler}
                      setCoupon={setCoupon}
                      coupon={coupon}
                      couponError={couponError}
                      setCouponError={setCouponError}
                      errorMessage={errorMessage}
                      withdrawCouponHandler={withdrawCouponHandler}
                      selectedItems={selectedItems}
                      buttonRef={buttonRef}
                    />
                  )}
                </div>
              </GridItem>
            </Grid>

            <div className={classes.recommended}>
              <RecommendedService
                // ids={withRecommendServices}
                title={t('Others usually choose')}
                nameSource="Cart"
              />
            </div>
          </div>
        ) : cartStore.isLoading ? (
          <CartSkeleton />
        ) : (
          <EmptyItems
            isBackground={true}
            title={'Your cart is empty'}
            description={
              'Lots of useful solutions are waiting for you in our catalog'
            }
          />
        )}

        <Modal
          title={''}
          isOpen={showModal}
          onClose={() => onCloseDealModal()}
          className={classes.modal}
        >
          <div className={classes.gradient}>
            <div className={classes.innerDeals}>
              <div className={classes.iconDeals}>
                <CheckCircleGradientItem />
              </div>
              <h2 className={classes.titleDeals}>{t('Projects created')}</h2>
              <div className={classes.textDeals}>
                <Type size="simple" tag="p" className={classes.textItemDeals}>
                  {t(
                    "Congratulations, your Projects have been created! We'll get in touch within 2 working days."
                  )}
                </Type>
              </div>
              <div className={classes.buttonModalWrap}>
                <NavLink to={routers.projects}>
                  <Button
                    theme="primary"
                    size="big"
                    className={classes.buttonModal}
                    onClick={() => onCloseDealModal()}
                  >
                    <span>{t('Go to projects')}</span>
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </Modal>

        <div className={classes.hint}>
          <div className={classes.hint__wrapper}>
            <div className={classes.hint__text}>
              {t(
                'You’ll get the final price after consultation with a manager'
              )}
            </div>

            <Button className={classes['hint__btn']} theme="default">
              {t('I understood')}
            </Button>
          </div>
        </div>
      </div>

      {isShowFixedButton && !isDisabled && (
        <FixedButton
          totalWithDiscounts={totalWithDiscounts}
          total={total}
          onCreateDeal={onCreateDeal}
        />
      )}

      <HotDealsCounter />
    </>
  );
});
