export const formatPrice = (price: number | string) => {
  const realPrice: number =
    Number(price) < 1 && Number(price) > 0
      ? Math.ceil(Number(price))
      : Math.floor(Number(price));
  const parts = realPrice.toString()?.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts[0];
};
