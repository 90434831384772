import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useUserStore } from 'contexts/UserContext';
import { useHotDeals } from 'hooks/useHotDeals';
import 'intro.js/introjs.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Service from 'services/service';
import { ShowNotification } from 'tools/showNotification';
import 'containers/CardsContainer/IntroTooltip.css';
import { DiscountType, SolutionType } from 'types/solutions';
import { FetchError } from 'types/util-types';

export const useServiceDetail = () => {
  const userStore = useUserStore();
  const params = useParams();

  const marketPlaceStore = useMarketPlaceStore();
  const singleService = marketPlaceStore?.singleService;

  const [serviceInfo, setServiceInfo] = useState<SolutionType>(
    singleService as SolutionType
  );
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const deals = serviceInfo?.discounts?.find(
    (item: DiscountType) => item?.type === 'HOT_DEAL' && item?.is_active
  );
  const { t, i18n } = useTranslation();

  const { getHotDealText } = useHotDeals(t);
  const [isHotDeal, setIsHotDeal] = useState(false);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');
  let dealsDates = {
    dateDealDate: 0,
    monthEndDate: '',
    yearEndDate: 0,
    active: false,
  };

  const fetchData = async () => {
    if (params?.id) {
      try {
        const response = await Service.getService(params.id);
        setServiceInfo(response?.data);
        marketPlaceStore.loadActiveHotDeals([response?.data]);
        marketPlaceStore.loadDataTimeHotDeals();
        setHasError(false);
      } catch (error: FetchError | unknown) {
        setHasError(true);
        const errorMessage =
          (error as FetchError)?.response?.data?.detail ||
          (error as FetchError)?.message ||
          t('Something went wrong');
        ShowNotification({
          type: 'error',
          children: errorMessage,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userStore.user.language, userStore.user.currency]);

  useEffect(() => {
    if (serviceInfo && deals) {
      setIsHotDeal(true);
      const utcDate = new Date(`${deals.deal_end_date}`);
      const dateDealDate = utcDate.getDate();
      const monthEndDate = utcDate.toLocaleString(i18n.language, {
        month: 'long',
      });
      const yearEndDate = utcDate.getFullYear();
      const active = true;
      dealsDates = {
        dateDealDate,
        monthEndDate,
        yearEndDate,
        active,
      };
      setHotDealDiscountText(getHotDealText(deals, serviceInfo));
    }
  }, [serviceInfo, deals]);

  const highlightCartClass = document.querySelector(
    '.tooltip-cart'
  ) as HTMLElement;

  useEffect(() => {
    if (highlightCartClass) {
      highlightCartClass.style.padding = '5px';
    }
  }, [highlightCartClass]);

  return {
    serviceInfo,
    hasError,
    t,
    i18n,
    marketPlaceStore,
    dealsDates,
    hotDealDiscountText,
    userStore,
    deals,
    isHotDeal,
  };
};
