import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { toJS } from 'mobx';
import { useCallback, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { pushToDataLayer } from 'tools/analytics';
import { SolutionType } from 'types/solutions';

export const useFavorites = (
  id: number,
  isFavorite: boolean,
  setIsFavorite: Dispatch<SetStateAction<boolean>>,
  currentPage?: number,
  setCurrentPage?: (val: number) => void,
  setProducts?: Dispatch<SetStateAction<SolutionType[]>>
) => {
  const location = useLocation();
  const isFavoritesPage = location.pathname.includes('favorites');
  const marketPlaceStore = useMarketPlaceStore();

  const changeFavorites = useCallback(async () => {
    if (!isFavorite) {
      await marketPlaceStore.addServiceToFavorite(id);
      pushToDataLayer('addToFavorite', 'buttonClick', 'GAForms');
      setIsFavorite(true);
    } else {
      if (marketPlaceStore.services.length === 1) {
        if (currentPage && currentPage > 1) {
          setCurrentPage && setCurrentPage(currentPage - 1);

          await marketPlaceStore.removeServiceFromFavorite(id);

          isFavoritesPage &&
            setProducts &&
            setProducts((prev) => prev.filter((item) => item.id !== id));

          setIsFavorite(false);

          return;
        }

        await marketPlaceStore.removeServiceFromFavorite(id);

        isFavoritesPage &&
          setProducts &&
          setProducts((prev) => prev.filter((item) => item.id !== id));

        setIsFavorite(false);
      } else {
        await marketPlaceStore.removeServiceFromFavorite(id);

        isFavoritesPage &&
          setProducts &&
          setProducts((prev) => prev.filter((item) => item.id !== id));

        setIsFavorite(false);
      }
    }
  }, [isFavorite]);

  return changeFavorites;
};
