import { Language } from 'types/user';

const getRuLocale = () =>
  process.env.REACT_APP_PLATFORM_TYPE === 'ru'
    ? {
        id: 6,
        lang_code: 'ru',
        name: 'Русский',
      }
    : null;

const languagesData: Language[] = [
  {
    id: 1,
    lang_code: 'en',
    name: 'English',
  },
  {
    id: 3,
    lang_code: 'es',
    name: 'Español',
  },
];

const ruLocale = getRuLocale();

if (ruLocale) {
  languagesData.push(ruLocale);
}

export const languages = languagesData;
