import { NavLink } from 'react-router-dom';
import classes from './BreadCrumbs.module.scss';
import clsx from 'clsx';
import _ from 'lodash';

interface BreadCrumbsProps {
  link: string;
  linkName: string;
  items: string[];
  className?: string;
}

const BreadCrumbs = ({
  link,
  linkName,
  items,
  className,
}: BreadCrumbsProps) => (
  <ul className={clsx(classes.breadcrumbs, className)}>
    <li className={classes.breadcrumbs__item}>
      <NavLink to={link} className={classes.breadcrumbs__link}>
        {linkName}
      </NavLink>
    </li>
    {items.map((item) => (
      <li key={_.uniqueId()} className={classes.breadcrumbs__item}>
        <span className={classes.breadcrumbs__link}>{item}</span>
      </li>
    ))}
  </ul>
);

export default BreadCrumbs;
