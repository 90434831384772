import { MockText } from 'containers/GrowthProgramRules/mockData';
import classes from './AnchorsWrapper.module.scss';
import Accordion from 'components/common/Accordion';
import _ from 'lodash';
import clsx from 'clsx';

interface AnchorsWrapperProps {
  texts: MockText[];
  currentAnchor: string;
  currentText: MockText;
  onSelectText: (text: MockText) => void;
  onSelectAnchor: (a: string) => void;
  isMobile?: boolean;
  openMobileModal?: boolean;
}

const AnchorsWrapper = ({
  texts,
  currentText,
  currentAnchor,
  onSelectText,
  onSelectAnchor,
  isMobile,
}: AnchorsWrapperProps) => (
  <div className={classes.wrapper}>
    {isMobile && <span className={classes.mobileTitle}>Документ</span>}
    <ul className={classes.textsList}>
      {texts.map((text) => (
        <li
          key={_.uniqueId()}
          className={classes.textsList__item}
          onClick={() => onSelectText(text)}
        >
          <Accordion
            header={<div>{text.title}</div>}
            className={classes.accordion}
            headerClassName={classes.accordion__header}
            activeClassName={classes.accordion__active}
            expanded={currentText.id === text.id}
          >
            <ul className={classes.anchorsList}>
              {text.anchors.map((anchor) => (
                <li key={_.uniqueId()}>
                  <a
                    href={anchor.value}
                    className={clsx(
                      classes.anchorsList__item,
                      anchor.value === currentAnchor &&
                        classes['anchorsList__item-active']
                    )}
                    onClick={(event) => {
                      event.preventDefault();
                      onSelectAnchor(anchor.value);
                    }}
                  >
                    {anchor.title}
                  </a>
                </li>
              ))}
            </ul>
          </Accordion>
        </li>
      ))}
    </ul>
  </div>
);

export default AnchorsWrapper;
