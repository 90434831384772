import clsx from 'clsx';
import { ProductCard } from 'containers/CardsContainer/ProductList/ProductCard';
import { ProjectCard } from 'containers/Projects/Card';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import classes from '../SearchResults.module.scss';
import { SingleResultProps } from './SingleResult.props';
import { SolutionType } from 'types/solutions';
import { ProjectProps } from 'types/project';

const SingleResult = ({
  activeTab,
  solutions,
  projects,
}: SingleResultProps) => {
  const marketPlaceStore = useMarketPlaceStore();

  return (
    <div
      className={clsx(classes.list, activeTab?.id === 2 && classes.projectList)}
    >
      {activeTab.name_key === 'solutions' &&
        (solutions?.results as SolutionType[]).map((service: SolutionType) => (
          <ProductCard
            key={service?.id}
            data={service}
            hotDeals={marketPlaceStore.dataTimeHotDeals}
          />
        ))}

      {activeTab.name_key === 'projects' &&
        (projects?.results as ProjectProps[]).map((project: ProjectProps) => (
          <ProjectCard project={project} key={project?.id} />
        ))}
    </div>
  );
};

export default SingleResult;
