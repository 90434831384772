import { useTranslation } from 'react-i18next';
import classes from './LoyaltyProgram.module.scss';
import UiIcon from '../Icon';
import clsx from 'clsx';
import { CLIENT_LEVEL } from 'constants/enums';
import { LoyaltyIcon } from '../Icons/LoyaltyIcon';
import { Link } from 'react-router-dom';

interface LoyaltyProgramProps {
  level: CLIENT_LEVEL;
  link: string;
}

const LoyaltyProgram = ({ level, link }: LoyaltyProgramProps) => {
  const { t } = useTranslation();

  return (
    <Link to={link} className={clsx(classes.wrapper, classes[level])}>
      <div className={classes.left}>
        <LoyaltyIcon level={level} />
        <div className={classes.text}>
          <div className={classes.title}>{t('Loyalty program')}</div>
          <div className={classes.description}>{level}</div>
        </div>
      </div>

      <div className={classes.arrow}>
        <UiIcon name="ArrowDownIcon" />
      </div>
    </Link>
  );
};

export default LoyaltyProgram;
