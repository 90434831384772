import * as React from 'react';
import { SVGProps } from 'react';

export const WhiteFireIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.7734 7.36669C11.62 7.16669 11.4334 6.99336 11.26 6.82002C10.8134 6.42002 10.3067 6.13336 9.88003 5.71336C8.8867 4.74002 8.6667 3.13336 9.30003 1.90002C8.6667 2.05336 8.11337 2.40002 7.64003 2.78002C5.91337 4.16669 5.23337 6.61336 6.0467 8.71336C6.07337 8.78002 6.10003 8.84669 6.10003 8.93336C6.10003 9.08002 6.00003 9.21336 5.8667 9.26669C5.71337 9.33336 5.55337 9.29336 5.4267 9.18669C5.3867 9.15336 5.36003 9.12002 5.33337 9.07336C4.58003 8.12002 4.46003 6.75336 4.9667 5.66002C3.85337 6.56669 3.2467 8.10002 3.33337 9.54669C3.37337 9.88002 3.41337 10.2134 3.5267 10.5467C3.62003 10.9467 3.80003 11.3467 4.00003 11.7C4.72003 12.8534 5.9667 13.68 7.3067 13.8467C8.73337 14.0267 10.26 13.7667 11.3534 12.78C12.5734 11.6734 13 9.90002 12.3734 8.38002L12.2867 8.20669C12.1467 7.90002 11.7734 7.36669 11.7734 7.36669Z"
      fill="white"
    />
  </svg>
);
