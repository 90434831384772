import * as React from 'react';
import { SVGProps } from 'react';

export const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 20 20"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.7136 7.32871L14.7148 7.32729C14.9274 7.06434 14.7227 6.69995 14.4116 6.69995H13.6407C13.4433 6.69995 13.2535 6.78896 13.1292 6.94575L13.1285 6.94655L8.81666 12.3311L6.87057 9.90035C6.80921 9.82356 6.73129 9.76181 6.64287 9.71944C6.55445 9.67708 6.45731 9.6551 6.35938 9.65499H5.58846C5.27182 9.65499 5.07699 10.0229 5.28479 10.2827L8.30572 14.0555C8.56745 14.382 9.06606 14.3806 9.32794 14.0564L14.7136 7.32871Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0C4.47768 0 0 4.47768 0 10ZM1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10Z"
    />
  </svg>
);
