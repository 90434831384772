import classes from '../Table.module.scss';
import FilterMobileTableIcon from 'components/shared/Icons/FilterMobileTableIcon';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import { SORT_BY } from 'constants/queryParamTypes';
import { RUSSIAN_LOCALE } from 'utils';
import { MobileOptionsProps } from './MobileOptions.props';

const MobileOptions = ({ handleSort, mobileModeTitle }: MobileOptionsProps) => {
  return (
    <div className={classes.table__row_mob}>
      <button
        className={classes.table__sort}
        onClick={() => handleSort(SORT_BY.DATE)}
      >
        <span>{mobileModeTitle}</span>

        <FilterMobileTableIcon />
      </button>

      {!RUSSIAN_LOCALE && (
        <button
          className={classes.table__list_mob}
          onClick={() => handleSort(SORT_BY.NAME, 'projects')}
        >
          <FilterTableIcon />
        </button>
      )}
    </div>
  );
};

export default MobileOptions;
