import clsx from 'clsx';
import classes from './Link.module.scss';
import { Link as BaseLink } from 'react-router-dom';
import { LinkProps } from './Link.props';

export const Link = ({
  children,
  href,
  className,
  isEmpty = false,
  target,
  onClick,
  query,
}: LinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const url = new URL(href, window.location.origin);
  if (query) {
    Object.keys(query).forEach((key) =>
      url.searchParams.append(key, query[key])
    );
  }

  return (
    <div className={clsx(classes.wrapper, isEmpty && classes.empty, className)}>
      <BaseLink
        to={url.pathname + url.search}
        target={target}
        onClick={handleClick}
      >
        {children}
      </BaseLink>
    </div>
  );
};
