import { CLIENT_LEVEL } from 'constants/enums';
import { ICartData } from 'types';
import { RUSSIAN_LOCALE } from 'utils';

const ranges = [
  { min: 50000, max: 150000, rate: 0.01 },
  { min: 150001, max: 500000, rate: 0.02 },
  { min: 500001, max: 1000000, rate: 0.025 },
];

export const calculateBonusCount = (
  level: CLIENT_LEVEL | undefined,
  price: number
): number | null => {
  if (
    !level ||
    [CLIENT_LEVEL.PLATINUM, CLIENT_LEVEL.SIGNATURE].includes(level) ||
    price < 50000 ||
    !RUSSIAN_LOCALE
  ) {
    return null;
  }

  for (const { min, max, rate } of ranges) {
    if (price >= min && price <= max) {
      return price * rate;
    }
  }

  if (price > 1000000) {
    return 1000000 * 0.025;
  }

  return null;
};

export const calculateAllBonusCount = (
  items: ICartData[],
  client: CLIENT_LEVEL | undefined
) =>
  items.reduce((result, item) => {
    const totalCost = item.total_cost;

    const bonusCount = calculateBonusCount(client, totalCost * item.count) || 0;

    return result + bonusCount;
  }, 0);
