import clsx from 'clsx';
import classes from './ColorSticker.module.scss';
import { RUSSIAN_LOCALE } from 'utils';
export type StickerColor = 'purple' | 'yellow' | 'green' | 'red' | 'orange';

type ColorStickerProps = {
  color?: StickerColor;
  text: string;
  className?: string;
  customColor?: string;
};

const getColorClass = function (color?: StickerColor) {
  switch (color) {
    case 'purple':
      return RUSSIAN_LOCALE ? 'sticker_purple' : 'sticker_purple_global';
    case 'yellow':
      return 'sticker_yellow';
    case 'green':
      return 'sticker_green';
    case 'red':
      return 'sticker_red';
    case 'orange':
      return 'sticker_orange';
    default:
      return 'sticker_purple';
  }
};

function ColorSticker({
  color,
  text,
  className,
  customColor,
}: ColorStickerProps) {
  return (
    <div
      style={{ backgroundColor: customColor }}
      className={clsx(
        classes.sticker,
        classes[getColorClass(color)],
        className
      )}
    >
      <span className={classes.sticker__text}>{text}</span>
    </div>
  );
}

export default ColorSticker;
