import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'services/user';
import { userStore } from 'stores/userStore';
import { getLangName } from 'tools/utils';
import { Goal, ProjectProps } from 'types/project';

export const useGoals = (
  project: ProjectProps,
  updateProject: () => Promise<ProjectProps | undefined>,
  tabsId?: string
) => {
  const { t, i18n } = useTranslation();
  const editableDivRef = useRef<HTMLInputElement>(null);
  const [projectGoals, setProjectGoals] = useState<Goal[]>(project?.goals);
  const [systemProjectGoals, setSystemProjectGoal] = useState<Goal[]>(
    project?.project_system_goals
  );
  const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [newGoal, setNewGoal] = useState<Goal | null>(null);
  const [projectId, setProjectId] = useState<number>();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const userLocale = userStore.user?.language?.lang_code;
  const maxLength = 40;
  const goalName = (goal: Goal) => getLangName(goal, 'name', i18n) || '';

  useEffect(() => {
    if (newGoal) {
      setProjectId(project.id);
    }
    if (!newGoal) {
      setIsFocused(false);
    }
  }, [newGoal, project.id, projectId]);

  useEffect(() => {
    userStore.loadProjectData(project?.id);
  }, []);

  useEffect(() => {
    updateProject().then((project: ProjectProps | undefined) => {
      setSystemProjectGoal(project?.project_system_goals as Goal[]);
      setProjectGoals(project?.goals as Goal[]);
    });
  }, [userLocale]);

  const handleAddAnotherGoal = () => {
    handleGoal(project.id, newGoal as Goal);
    setNewGoal(null);
    setAddAnotherGoal(false);
    setIsFocused(false);
    setProjectId(undefined);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddAnotherGoal();
    }
  };

  const handleNonInputClick = (event: MouseEvent) => {
    if (
      event.target instanceof HTMLElement &&
      event.target.closest(`#${tabsId}`)
    )
      return;

    const target = event?.target as HTMLElement;
    if (
      !target?.closest('input') &&
      !target?.closest('svg') &&
      projectId &&
      isFocused
    ) {
      event.preventDefault();
      handleAddAnotherGoal();
    } else if (
      !target.closest('input') &&
      !target.closest('svg') &&
      !isFocused &&
      !goalName(newGoal as Goal)
    ) {
      event.preventDefault();
      setNewGoal(null);
      setAddAnotherGoal(false);
      setProjectId(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleNonInputClick);
    return () => {
      document.removeEventListener('click', handleNonInputClick);
    };
  }, [newGoal, projectId, isFocused, handleNonInputClick]);

  const checkGoals = (goalsArray: Goal[], goal: Goal) =>
    _.findIndex(goalsArray, (o) => _.isMatch(o, goal)) > -1;

  const handleGoal = async (projectId: number, goal: Goal, removed = false) => {
    setIsClicked(true);
    const finalGoal = goal?.goal ? goal.goal : goal;
    try {
      if (removed) {
        'id' in finalGoal &&
          User.deleteGoal(projectId, finalGoal?.id)
            .then(async (data) => {
              setSystemProjectGoal(data.project_system_goals);
              setProjectGoals(data.goals);
            })
            .catch(() => {
              updateProject();
            });
      }
      if (!removed && !checkGoals(projectGoals, finalGoal)) {
        finalGoal?.name &&
          User.updateProject(projectId, finalGoal?.name).then((data) => {
            setProjectGoals(data?.goals);
            setSystemProjectGoal(data?.project_system_goals);
          });
      }
    } catch (error) {
      console.error(error);
    } finally {
      await updateProject();
    }
    setTimeout(() => setIsClicked(false), 500);
  };

  useEffect(() => {
    if (addAnotherGoal && editableDivRef.current) {
      editableDivRef.current.focus();
    }
  }, [addAnotherGoal]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setNewGoal({ name: value } as Goal);
      e.target.style.width = `${e.target.scrollWidth}px`;
    }
  };

  return {
    setNewGoal,
    setAddAnotherGoal,
    setIsFocused,
    setProjectId,
    projectGoals,
    handleGoal,
    setIsClicked,
    goalName,
    addAnotherGoal,
    maxLength,
    newGoal,
    editableDivRef,
    handleKeyDown,
    handleChange,
    t,
    isClicked,
    systemProjectGoals,
  };
};
