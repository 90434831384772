import { useEffect } from 'react';
import clsx from 'clsx';
import classes from './Modal.module.scss';
import { Logo } from 'components/common/Logo';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { ChevronIcon } from 'components/shared/Icons/ChevronIcon';
import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { Portal } from 'components/common/Portal';
import { ModalProps } from './Modal.props';

export const Modal = ({
  subModal = false,
  children,
  title,
  isOpen,
  onClose,
  isBottom = false,
  isBasicHeader = false,
  filterMobile,
  className,
  classNameForContent,
  isPriceModal,
  isOutsideBase,
  withMediaMobile = true,
}: ModalProps) => {
  useDisableScrolling(isOpen);

  useEffect(() => {
    if (isOpen) {
      if (typeof window !== undefined) {
        const layout = document.getElementsByClassName('.layout-container')[0];
        const element: HTMLElement | null =
          document?.getElementById('#modal-block');
        if (layout && element) {
          layout.appendChild(element);
        }
      }
    }
  }, [isOpen]);

  const handleBackgroundClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Portal
      selector={isOutsideBase ? '.restore-container' : '.layout-container'}
    >
      <div
        className={clsx(
          !isBottom && classes.modal,
          isOpen && !isBottom && classes.open,
          subModal && classes['sub-modal'],
          isBottom && classes['bottom-modal'],
          isOpen && isBottom && classes['bottom-open'],
          filterMobile && classes['mobile-filter'],
          className && className,
          isPriceModal && classes['price-modal'],
          withMediaMobile ? classes.mediaMobile : classes.mediaTablet
        )}
        id="modal-block"
        onClick={handleBackgroundClick}
      >
        <div
          className={clsx(
            classes.header,
            isBasicHeader && classes.basic,
            withMediaMobile ? classes.mediaMobile : classes.mediaTablet
          )}
        >
          {!subModal ? (
            <div
              className={clsx(
                classes.wrapper,
                isBasicHeader && classes.basic,
                withMediaMobile ? classes.mediaMobile : classes.mediaTablet
              )}
            >
              {!isBasicHeader && <Logo />}
              <div className={classes.title}>{title}</div>

              <div role="button" onClick={onClose} className={classes.close}>
                <CrossIcon />
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                classes.wrapper,
                withMediaMobile ? classes.mediaMobile : classes.mediaTablet
              )}
            >
              <div className={classes.title}>
                <div role="button" onClick={onClose}>
                  <ChevronIcon width="16px" height="8px" />
                </div>
                {title}
              </div>

              <div role="button" onClick={onClose} className={classes.close}>
                <CrossIcon />
              </div>
            </div>
          )}
        </div>

        <div
          className={clsx(
            classes.content,
            classNameForContent,
            withMediaMobile ? classes.mediaMobile : classes.mediaTablet
          )}
        >
          {!isPriceModal && (
            <div
              role="button"
              onClick={onClose}
              className={clsx(
                classes.close,
                classes.desktop,
                withMediaMobile ? classes.mediaMobile : classes.mediaTablet
              )}
            >
              <CrossIcon width={'15px'} height={'15px'} />
            </div>
          )}
          {children}
        </div>
      </div>
    </Portal>
  );
};
