import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import classes from '../CartCard.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import UiIcon from 'components/shared/Icon';
import { useBreakPoints } from '../useBreakPoints';
import { useUserStore } from 'contexts/UserContext';
import { CartCardPriceProps } from './CartCardPrice.props';
import { formatPrice } from 'tools/formatPrice';
import BonusCount from 'components/shared/BonusCount/BonusCount';
import { observer } from 'mobx-react-lite';
import { calculateBonusCount } from 'tools/calculateBonusCount';

const CartCardPrice = observer(
  ({
    discountNoDecimal,
    totalNoDecimal,
    priceToText,
    itemCount,
    totalCostWithNoDiscountNumber,
    free,
    totalCostNumber,
    total,
    handleOpenModal,
    hideDiscount,
  }: CartCardPriceProps) => {
    const { t } = useTranslation();
    const { isTablet } = useBreakPoints();
    const userStore = useUserStore();
    const currency = userStore?.user?.currency;

    const priceUnitText =
      itemCount > 1
        ? `${priceToText === 'all' ? '' : priceToText}`
        : priceToText;

    const cardCostWithText =
      Number(totalNoDecimal) && Number(discountNoDecimal)
        ? ` / ${priceUnitText}`
        : ` / ${priceUnitText}`;

    const hasDiscount = Number(discountNoDecimal) !== Number(totalNoDecimal);

    const cardCostWithNoDiscountWithText = hasDiscount
      ? ` / ${priceToText}`
      : '';

    const cardCost = itemCount > 1 ? '' : cardCostWithText;
    const cardCostWithNoDiscount =
      itemCount > 1
        ? hasDiscount && formatPrice(totalCostWithNoDiscountNumber)
        : cardCostWithNoDiscountWithText;

    const longCardCostMobile = String(cardCost)?.length > 14;
    const longCardCost = String(cardCost)?.length > 10;
    const longCardCostDesktop = String(cardCost)?.length > 10;

    const formattedTotalWithDiscounts = formatPrice(discountNoDecimal);
    const formattedTotal = formatPrice(total);

    const client = userStore.client;

    const bonusCount = calculateBonusCount(
      client?.level?.name,
      free ? 0 : total * itemCount
    );

    return (
      <div className={classes.price}>
        <div className={classes.price__firstBlock}>
          <div className={clsx(classes.price__total)}>
            <div
              className={clsx(
                classes.price__variations,
                longCardCostMobile && classes.price__longTotalMobile,
                longCardCost && classes.price__longTotal
              )}
            >
              <div
                className={clsx(
                  longCardCostMobile && classes.price__longTotalMobile,
                  longCardCost && classes.price__longTotal,
                  longCardCostDesktop && classes.price__longTotalDesktop
                )}
              >
                <div className={classes.wrapperPrice}>
                  <span
                    className={classes.total}
                    style={{ display: 'inline-block' }}
                  >
                    {!free && (
                      <FormatPrice
                        formatted
                        price={
                          itemCount > 1
                            ? formatPrice(totalCostNumber)
                            : formattedTotalWithDiscounts
                        }
                        currency={currency?.symbol}
                      />
                    )}

                    {free ? t('Free') : cardCost}
                  </span>

                  {!isTablet &&
                    cardCostWithNoDiscount &&
                    !free &&
                    !hideDiscount && (
                      <span
                        style={{ display: 'inline-block' }}
                        className={clsx(classes?.price__discount)}
                      >
                        <FormatPrice
                          formatted
                          price={
                            itemCount > 1
                              ? formatPrice(totalCostWithNoDiscountNumber)
                              : formattedTotal
                          }
                          currency={currency?.symbol}
                          text={itemCount > 1 ? '' : cardCostWithNoDiscount}
                        />
                      </span>
                    )}

                  {isTablet &&
                    (cardCostWithNoDiscount && !free && !hideDiscount ? (
                      <>
                        <span className={classes?.price__discount}>
                          <FormatPrice
                            formatted
                            price={
                              itemCount > 1
                                ? formatPrice(totalCostWithNoDiscountNumber)
                                : formattedTotal
                            }
                            currency={currency?.symbol}
                            text={itemCount > 1 ? '' : cardCostWithNoDiscount}
                          />
                        </span>
                      </>
                    ) : // (
                    //   itemCount > 1 &&
                    //   cardCostWithText && (
                    //     <span
                    //       className={clsx(
                    //         classes.price__count,
                    //         longCardCost && classes.price__longCount
                    //       )}
                    //     >
                    //       <FormatPrice
                    //         formatted
                    //         price={formattedTotalWithDiscounts}
                    //         currency={currency?.symbol}
                    //         text={cardCostWithText}
                    //       />
                    //     </span>
                    //   )
                    // )
                    null)}

                  <button
                    className={classes.price__hint_btn}
                    onClick={handleOpenModal}
                  >
                    <UiIcon
                      name="HintIcon"
                      additionalClassName={classes['hint-icon']}
                    />
                  </button>
                </div>

                {isTablet && cardCostWithNoDiscount && bonusCount && (
                  <div className={classes.bonusCount}>
                    <BonusCount
                      size="sm"
                      type="filled"
                      count={bonusCount}
                      isTooltip={false}
                    />
                  </div>
                )}

                {bonusCount && (
                  <div
                    className={clsx(
                      classes.bonusCount,
                      cardCostWithNoDiscount && isTablet && classes.hidden
                    )}
                  >
                    <BonusCount
                      size="sm"
                      type="filled"
                      count={bonusCount}
                      isTooltip={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* {!isTablet && itemCount > 1 && cardCostWithText && !free && (
            <span
              className={clsx(
                classes.price__count,
                longCardCost && classes.price__longCount
              )}
            >
              <span style={{ textAlign: 'right' }}>
                <FormatPrice
                  formatted
                  price={formattedTotalWithDiscounts}
                  currency={currency?.symbol}
                  text={cardCostWithText}
                />
              </span>
            </span>
          )} */}

          <div className={classes.price__info}>
            {t('You’ll get the final price after consultation with a manager')}
          </div>
        </div>
      </div>
    );
  }
);

export default CartCardPrice;
