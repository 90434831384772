import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const SearchGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.276 3.621a6.12 6.12 0 1 0-8.655 8.655 6.12 6.12 0 0 0 8.655-8.655ZM2.42 2.42a7.82 7.82 0 0 1 11.628 10.426l3.824 3.824-1.202 1.202-3.824-3.824A7.821 7.821 0 0 1 2.42 2.42Z"
      fill="url(#a)"
    />
    <defs>
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="a"
          x1={13.28}
          y1={0.129}
          x2={12.062}
          y2={18.079}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65B2FF" />
          <stop offset={1} stopColor="#6638F4" />
        </linearGradient>
      )}
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="a"
          x1="-5.47608"
          y1="-6.71807"
          x2="13.863"
          y2="14.6613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
