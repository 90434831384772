import React, { ReactElement, ReactNode } from 'react';
import classes from './Fader.module.scss';
import classnames from 'classnames';

interface IFader {
  children: ReactNode;
  active: boolean;
}

export const Fader = ({ children, active }: IFader) => {
  const modifyChild = (child: ReactElement) => {
    const className = classnames(child.props.className, {
      [classes.loading]: active,
      [classes.loading_done]: !active,
    });

    const props = {
      ...child.props,
      className: className,
    };

    return React.cloneElement(child, props);
  };

  const modifiedChildren = React.Children.map(children, (child) =>
    React.isValidElement(child) ? modifyChild(child) : child
  );

  // Check for absence of children
  return modifiedChildren ? <>{modifiedChildren}</> : null;
};
