import { AxiosResponse } from 'axios';
import api from './http';

export default class GrowthProgramService {
  static async subscribe(eventId: number): Promise<AxiosResponse> {
    return api.get(`/api/v1/event/${eventId}/subscribe/`);
  }

  static async getSubscribe(eventId: number): Promise<AxiosResponse> {
    return api.get(`/api/v1/event/${eventId}/get_subscribe/`);
  }
}
