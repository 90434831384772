import styles from './CardsContainerSkeleton.module.scss';
import FiltersSkeleton from 'components/shared/Skeleton/FiltersSkeleton/FiltersSkeleton';
import { useTranslation } from 'react-i18next';
import ProductListSkeleton from 'components/shared/Skeleton/ProductListSkeleton/ProductListSkeleton';
import YouMayAlsoNeedSkeleton from 'components/shared/Skeleton/YouMayAlsoNeedSkeleton/YouMayAlsoNeedSkeleton';

const CardsContainerSkeleton = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t(`${title}`)}</div>

      <div className={styles.content}>
        <div className={styles.left}>
          <FiltersSkeleton />
        </div>

        <div className={styles.right}>
          <ProductListSkeleton />
        </div>
      </div>

      {window.location.pathname === '/favorites' && (
        <div className={styles['wrapper-other']}>
          <h3 className={styles['title-other']}>
            {title ? title : t('Others usually choose')}
          </h3>
          <YouMayAlsoNeedSkeleton />
        </div>
      )}
    </div>
  );
};

export default CardsContainerSkeleton;
