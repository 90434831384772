import { TimerGradientIcon } from 'components/shared/Icons/TimerGradientIcon';
import classes from './HowItWorks.module.scss';
import { CartGradientIcon } from 'components/shared/Icons/CartGradientIcon';
import { CalendarGradientIcon } from 'components/shared/Icons/CalendarGradientIcon';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { Swiper, SwiperSlide } from 'swiper/react';

export const HotItWorks = () => {
  const { t } = useTranslation();

  const slidesData = [
    {
      icon: <TimerGradientIcon />,
      subtitle: t('Catch the bargain'),
      text: t(
        'Hot Deals are activated at a specific time and available for a limited period to catch it'
      ),
    },
    {
      icon: <CartGradientIcon />,
      subtitle: t('Complete your Cart'),
      text: t(
        'Add an item to your Cart and create a Solution before the timer goes off and the offer expires'
      ),
    },
    {
      icon: <CalendarGradientIcon />,
      subtitle: t('Sign an agreement'),
      text: t(
        'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
      ),
    },
  ];

  return (
    <div className={classes.wrap}>
      <span className={classes.title}>{t('How it works')}</span>

      <div className={classes['swiper-wrapper']}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={16}
          navigation={true}
          modules={[Navigation]}
          className={clsx(classes.swiper)}
          breakpoints={{
            768: {
              spaceBetween: 30,
            },
          }}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide key={index} className={classes['swiper-slider']}>
              <div className={classes.item}>
                <div className={classes.icon}>{slide.icon}</div>
                <div className={classes.subtitle}>{slide.subtitle}</div>
                <p className={classes.text}>{slide.text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
