import * as React from 'react';
import { SVGProps } from 'react';

export const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.4501 19.1H16.3501C16.8001 19.1 16.8001 19.1 16.8001 18.65V6.95002C16.8001 6.50002 16.8001 6.50002 16.3501 6.50002H6.4501C6.0001 6.50002 6.0001 6.50002 6.0001 6.95002V18.65C6.0001 19.1 6.0001 19.1 6.4501 19.1ZM7.8001 7.94002H15.0001C15.3601 7.94002 15.3601 7.94002 15.3601 8.21639V17.3C15.3601 17.66 15.3601 17.66 15.0001 17.66H7.8001C7.4401 17.66 7.4401 17.66 7.4401 17.3V8.21639C7.4401 7.94002 7.4401 7.94002 7.8001 7.94002ZM14.297 3.54689L13.8617 3.11161C13.6501 2.90002 13.6501 2.90002 13.2001 2.90002H9.6001C9.1501 2.90002 9.1501 2.90002 8.93852 3.11161L8.50324 3.54689C8.2501 3.80002 8.2501 3.80002 7.8001 3.80002H5.5501C5.1001 3.80002 5.1001 3.80002 5.1001 4.25002V4.70002C5.10009 5.24002 5.1001 5.24002 5.5501 5.24002H17.2501C17.7001 5.24002 17.7001 5.24002 17.7001 4.70002V4.25002C17.7001 3.80002 17.7001 3.80002 17.2501 3.80002H15.0001C14.5501 3.80002 14.5501 3.80002 14.297 3.54689Z" />
  </svg>
);
