import Lottie from 'lottie-react';
import classes from './PageLoader.module.scss';
import loader from './loader.json';
import loader_ru from './loading_ru.json';
import { RUSSIAN_LOCALE } from 'utils';

export const PageLoader = ({ children }: { children?: React.ReactNode }) => (
  <div className={classes.loaderWrapper}>
    <div className={classes['page-loader']}>
      <Lottie animationData={RUSSIAN_LOCALE ? loader_ru : loader} loop={true} />
    </div>

    {children}
  </div>
);
