import _ from 'lodash';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import {
  FilterProps,
  QueryParamsHandlerType,
  SolutionType,
  TopFilterProps,
} from 'types/solutions';
import { userStore } from 'stores/userStore';
import { SelectData } from 'components/shared/SelectComponent/SelectComponent.props';
import { TFunction } from 'i18next';
import { OptionType, OrderSortParamsType } from 'types/util-types';

export const getRandomServices = (
  allProducts: SolutionType[],
  limit: number
) => {
  const ids = [];
  const nameCounts: { [key: string]: number } = {};
  const generatedIds = new Set();

  for (const item of allProducts) {
    const name = item.product.name;
    nameCounts[name] = (nameCounts[name] || 0) + 1;
  }

  while (ids.length < 5 && ids.length < allProducts.length) {
    const randomIndex = _.random(0, allProducts.length - 1);
    const randomId = allProducts[randomIndex].id;
    const productName = allProducts[randomIndex].product.name;

    if (nameCounts[productName] > limit) continue;

    if (generatedIds.has(randomId)) continue;

    ids.push(randomId);
    nameCounts[productName]++;
    generatedIds.add(randomId);
  }

  return ids;
};

export const queryParamsHandler = (
  {
    page,
    page_size: pageSize,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    serviceTypes,
    businessTypes,
    isFavorites,
    budget,
    price_min,
    ids,
    order,
    clearBusinessTypes,
  }: QueryParamsHandlerType,
  isRecommended?: boolean
) => {
  // Setting the default type of business on the solutions page, NOT on the favorites page
  if (window.location.pathname !== '/favorites') {
    if (clearBusinessTypes && !businessTypes) {
      businessTypes = [];
    } else {
      // Checking if the user has selected a type of business, if so - set it as default in the query
      if (userStore?.user?.company_industry) {
        businessTypes = businessTypes || [];

        if (businessTypes.length === 0 && !isFavorites && page && page === 1) {
          businessTypes.push(userStore.user.company_industry);
        }

        if (businessTypes.includes('clear') && !isFavorites) businessTypes = [];
      }
    }
  }

  const queryParams = {
    page: page || 1,
    page_size: pageSize || 8,
    is_hot: isHot,
    is_free: isFree,
    is_new: isNew,
    is_sale: isSale,
    is_top_up: isTopUp,
    price_max: budget === -1 ? '0' : budget || 0,
    price_min: price_min || 0,
    is_favorite: isFavorites,
    service_type: serviceTypes?.length && serviceTypes?.join(','),
    business_type: businessTypes?.length && businessTypes?.join(','),
    ...(isRecommended && {
      source_services: ids?.length ? JSON.stringify(ids) : JSON.stringify([]),
    }),
    order,
  };

  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};

export const handleFilterServices = (
  { price_max, price_min, businessTypes, serviceTypes }: FilterProps,
  page_size: number,
  businessTypesChecks: string[] | number[],
  serviceTypesChecks: string[] | number[],
  setProductsToShow: (products: SolutionType[]) => void,
  topFilters?: TopFilterProps,
  isFavorites?: boolean,
  order?: OrderSortParamsType
) => {
  const params = queryParamsHandler({
    price_min,
    page_size,
    businessTypes: businessTypes || businessTypesChecks,
    serviceTypes: serviceTypes || serviceTypesChecks,
    isFavorites,
    order,
    ...topFilters,
  });

  marketPlaceStore.loadServices(params).then((services) => {
    setProductsToShow(services);
  });
};

export const options = (t: TFunction): OptionType[] => [
  { id: 1, name: t('Best sellers') },
  { id: 2, name: `${t('Price')}: ${t('low to high')}` },
  { id: 3, name: `${t('Price')}: ${t('high to low')}` },
];

// Created a separate one for Ru because it doesn't have the word "Price" at the beginning
export const optionsRu = (t: TFunction): OptionType[] => [
  { id: 1, name: t('Best sellers') },
  { id: 2, name: t('low to high') },
  { id: 3, name: t('high to low') },
];

export const defaultSortParams = (sortSelect: SelectData) => {
  switch (sortSelect?.id) {
    case 1:
      return 'views';
    case 2:
      return 'cost_lth';
    case 3:
      return 'cost_htl';
  }
};
