import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const GiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1875 6.05469H14.3047C14.5703 5.63672 14.7266 5.14062 14.7266 4.60938C14.7266 3.12305 13.5176 1.91406 12.0312 1.91406C11.2227 1.91406 10.4941 2.27344 10 2.83984C9.50586 2.27344 8.77734 1.91406 7.96875 1.91406C6.48242 1.91406 5.27344 3.12305 5.27344 4.60938C5.27344 5.14062 5.42773 5.63672 5.69531 6.05469H2.8125C2.4668 6.05469 2.1875 6.33398 2.1875 6.67969V10.5859C2.1875 10.6719 2.25781 10.7422 2.34375 10.7422H3.125V17.4609C3.125 17.8066 3.4043 18.0859 3.75 18.0859H16.25C16.5957 18.0859 16.875 17.8066 16.875 17.4609V10.7422H17.6562C17.7422 10.7422 17.8125 10.6719 17.8125 10.5859V6.67969C17.8125 6.33398 17.5332 6.05469 17.1875 6.05469ZM10.6641 4.60938C10.6641 3.85547 11.2773 3.24219 12.0312 3.24219C12.7852 3.24219 13.3984 3.85547 13.3984 4.60938C13.3984 5.36328 12.7852 5.97656 12.0312 5.97656H10.6641V4.60938ZM7.96875 3.24219C8.72266 3.24219 9.33594 3.85547 9.33594 4.60938V5.97656H7.96875C7.21484 5.97656 6.60156 5.36328 6.60156 4.60938C6.60156 3.85547 7.21484 3.24219 7.96875 3.24219ZM3.51562 9.41406V7.38281H9.33594V9.41406H3.51562ZM4.45312 10.7422H9.33594V16.7578H4.45312V10.7422ZM15.5469 16.7578H10.6641V10.7422H15.5469V16.7578ZM16.4844 9.41406H10.6641V7.38281H16.4844V9.41406Z"
      fill="url(#paint0_linear_4449_92984)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_4449_92984"
          x1="-3.20984"
          y1="-7.13385"
          x2="14.5006"
          y2="9.05271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_4449_92984"
          x1="-0.193307"
          y1="4.49584"
          x2="17.501"
          y2="19.9137"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BA5FF" />
          <stop offset="1" stopColor="#A54BFF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
