import { useEffect, useState } from 'react';
import { Label } from './Label';
import { Icon } from 'components/shared/Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import { PhoneInputMaskProps } from './PhoneInput.props';
import { RUSSIAN_LOCALE } from 'utils';
import { CrossIcon } from '../Icons/CrossIcon';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import { QuestionIcon } from '../Icons/QuestionIcon';

export const PhoneInputMaskFull = ({
  label,
  labelPosition = 'left',
  placeholder = '',
  disabled = false,
  error = false,
  value = '',
  icon,
  iconFill = '#A09CAC',
  iconHeight = '18px',
  iconWidth = '18px',
  className = '',
  onChange,
  onBlur,
  iconJSX,
  inputLabel,
  helperText,
  clearIcon,
  bigInput,
  isBackground,
}: PhoneInputMaskProps) => {
  const classNames = clsx(
    style.input,
    (icon || iconJSX) && style.inputicon,
    error && style.inputError,
    inputLabel && style.inputLabel,
    value && value.length === 0 && inputLabel && style.inputLabelEmptyInput,
    RUSSIAN_LOCALE && !isBackground && style.ru,
    className
  );

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {icon && !iconJSX && (
          <div className={style.iconWrap}>
            <Icon
              name={icon}
              width={iconWidth}
              height={iconHeight}
              iconFill={iconFill}
            />
          </div>
        )}

        {!icon && iconJSX && <div className={style.iconWrap}>{iconJSX}</div>}

        <PhoneInput
          specialLabel={''}
          country={''}
          inputProps={{
            autoComplete: 'false',
          }}
          value={value}
          // containerClass={classNames}
          inputClass={clsx(error && style.inputError, classNames)}
          placeholder={''}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          onFocus={() => {
            setIsFocused(true); // обновляем состояние при фокусе
          }}
          onBlur={() => {
            setIsFocused(false); // обновляем состояние при потере фокуса
            onBlur && onBlur();
          }}
        />

        {inputLabel && (
          <div
            className={clsx(
              style.inputLabelPlaceholder,
              (icon || iconJSX) && style.inputIconPlaceholder,
              value.length > 0 && style.inputLabelPlaceholderActive,
              style.inputLabelPlaceholderDefault,
              bigInput && style.bigInput,
              isFocused && style.inputLabelPlaceholderDefaultFocused
            )}
          >
            {placeholder}
          </div>
        )}

        {clearIcon && value.length > 0 && (
          <CrossIcon
            className={style.clearIcon}
            onClick={() => onChange && onChange('')}
            width={'15px'}
            height={'15px'}
          />
        )}

        {helperText && (
          <TooltipContainer
            text={helperText}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={style.error}
          >
            <QuestionIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>

      {error && <div className={style.errorText}>{error}</div>}
    </div>
  );
};
