import clsx from 'clsx';
import React from 'react';
import { Button } from 'components/shared/Button';
import AddToCartButton from 'components/shared/Button/AddToCartButton';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { FilledHeartIcon } from 'components/shared/Icons/FilledHeartIcon';
import { HeartIcon } from 'components/shared/Icons/HeartIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { WhiteFireIcon } from 'components/shared/Icons/WhiteFireIcon';
import PriceText from 'components/shared/PriceText';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useUserStore } from 'contexts/UserContext';
import { useCheckCurrency } from 'hooks/useCheckCurrency';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import classes from './ProductCard.module.scss';
import { ProductCardProps } from './ProductCard.props';
import { useProductCard } from './useProductCard';
import { getLangName } from 'tools/utils';
import BonusCount from 'components/shared/BonusCount/BonusCount';
import { calculateBonusCount } from 'tools/calculateBonusCount';

export const ProductCard = observer(
  ({
    data,
    isHit = false,
    isSmall = false,
    hotDeals,
    currentPage,
    setCurrentPage,
    setProducts,
  }: ProductCardProps) => {
    const {
      deals,
      t,
      hotDealDiscountText,
      i18n,
      dateDeal,
      checkDiscount,
      changeFavorites,
      isFavorite,
    } = useProductCard(data, currentPage, setCurrentPage, setProducts);
    const checkCurrency = useCheckCurrency();
    const userStore = useUserStore();

    const formattedPrice = (price?: number | string, withMinus?: boolean) => (
      <FormatPrice
        currency={userStore.user?.currency?.symbol}
        price={price}
        withMinus={withMinus}
      />
    );

    const client = userStore.client;

    const bonusCount = calculateBonusCount(
      client?.level?.name,
      data.cost_with_discounts || data.cost
    );

    const isDiscount =
      data?.has_active_discounts &&
      Number(data?.cost) !== Number(data?.cost_with_discounts) &&
      !data.discounts?.find((item) => item.cost_change_type === 'PRICE_NEW');

    return (
      <div
        className={clsx(
          classes.wrapper,
          isHit && classes.hit,
          isSmall && classes.small,
          hotDeals &&
            hotDeals.activeDeals &&
            !hotDeals.featureDeals &&
            deals &&
            classes.hit
        )}
      >
        <NavLink to={`/solutions/${data.slug}`} className={classes.link} />
        <div className={classes.top}>
          <div className={classes.head}>
            <div className={classes.tags}>
              {hotDeals &&
                hotDeals.activeDeals &&
                !hotDeals.featureDeals &&
                deals && (
                  <div className={clsx(classes.tag, classes['hit-tag'])}>
                    <WhiteFireIcon />
                    <span className={classes.tagInner}>
                      {deals.cost_change_type === 'MONEY' &&
                        formattedPrice(undefined, true)}
                      {hotDealDiscountText}
                    </span>
                  </div>
                )}

              {data?.is_new && (
                <div className={clsx(classes.tag, classes.new)}>
                  {t('NEW filter')}
                </div>
              )}

              {data?.is_free && (
                <div className={clsx(classes.tag, classes.free)}>
                  {t('FREE')}
                </div>
              )}

              <div className={classes.tag}>
                {getLangName(data.product, 'name', i18n)}
              </div>
            </div>
          </div>

          <div className={classes.inner}>
            <div className={classes.title}>
              {getLangName(data, 'name', i18n)}
            </div>

            <div className={classes.text}>
              {getLangName(data, 'short_description', i18n)}
            </div>

            {dateDeal.active &&
              !isSmall &&
              hotDeals &&
              !hotDeals.featureDeals &&
              deals && (
                <div>
                  <div className={classes.tooltipWrap}>
                    <span className={classes.closeTimeInner}>
                      {t('Closing time')}:{' '}
                      <span className={classes.closeTime}>
                        {dateDeal.dayEnd} {dateDeal.monthEnd} {dateDeal.yearEnd}{' '}
                      </span>
                      <div className={classes.closeTimeTooltip}>
                        <TooltipContainer
                          text={t(
                            'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                          )}
                          position={'top'}
                          className={classes.tooltipContainer}
                          classNameTooltip={classes.tooltip}
                        >
                          <InfoIcon fill="#fff" width="18px" height="18px" />
                        </TooltipContainer>
                      </div>
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className={classes.bottom}>
          {!data?.is_free ? (
            <div className={classes.price}>
              <span className={classes.price__tips}>
                <PriceText data={data} t={t} />
              </span>

              <div
                className={clsx(
                  classes.price__wrapper,
                  bonusCount &&
                    isDiscount &&
                    classes['price__wrapper--discount']
                )}
              >
                <span className={clsx(classes.price__currency, checkCurrency)}>
                  {formattedPrice(checkDiscount)}{' '}
                  {bonusCount && (
                    <BonusCount size="s" type="filled" count={bonusCount} />
                  )}
                </span>
                {isDiscount && (
                  <span
                    className={clsx(
                      classes?.price__discount,
                      checkCurrency && classes['price__discount--small']
                    )}
                  >
                    {formattedPrice(data?.cost)}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className={classes['price-free']}>
              <span>{t('Free')}</span>
            </div>
          )}
          <div className={classes['mobile-buttons']}>
            <div data-step-solutions-6 style={{ width: '100%' }}>
              <AddToCartButton data={data} />
            </div>

            <div className={classes.favored} data-step-solutions-7>
              <Button
                className={classes['mobile-button']}
                theme="icon-default"
                onClick={changeFavorites}
              >
                {isFavorite ? <FilledHeartIcon /> : <HeartIcon />}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
