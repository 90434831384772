import clsx from 'clsx';
import { FireIcon } from 'components/shared/Icons/FireIcon';
import { GiftIcon } from 'components/shared/Icons/GiftIcon';
import PercentIcon from 'components/shared/Icons/PercentIcon';
import { SpeakerIcon } from 'components/shared/Icons/SpeakerIcon';
import { ThunderIcon } from 'components/shared/Icons/ThunderIcon';
import { useTranslation } from 'react-i18next';
import classes from '../ProductList.module.scss';
import { ProductListFiltersProps } from './ProductListFilters.props';

const ProductListFilters = ({
  showMore,
  setCurrentPage,
  checkFilter,
  freeFilter,
  isLoadingServices,
  setPriceMin,
  setShowMore,
  setIsFree,
  isFree,
  defaultIsFree,
  newFilter,
  setIsNew,
  isNew,
  defaultIsNew,
  hotFilter,
  setIsHot,
  defaultIsHot,
  isHot,
  defaultIsSale,
  isSale,
  setIsSale,
  saleFilter,
  defaultIsTopUp,
  isTopUp,
  setIsTopUp,
  topUpFilter,
  clearSeeMore,
}: ProductListFiltersProps) => {
  const { t } = useTranslation();
  return (
    <div className={classes.left} data-step-solutions-1>
      {freeFilter && (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (isLoadingServices) return;

            setPriceMin(0);
            setShowMore(0);
            setIsFree(!(isFree || defaultIsFree));
            clearSeeMore();
            !showMore && setCurrentPage(1);
          }}
          className={clsx(
            classes.filter,
            !checkFilter(isFree, defaultIsFree) && classes.free,
            checkFilter(isFree, defaultIsFree) && classes['is-free']
          )}
        >
          <GiftIcon /> {t('Free')}
        </div>
      )}

      {newFilter && (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (isLoadingServices) return;

            setPriceMin(0);
            setShowMore(0);
            setIsNew(!(isNew || defaultIsNew));
            clearSeeMore();
            !showMore && setCurrentPage(1);
          }}
          className={clsx(
            classes.filter,
            !checkFilter(isNew, defaultIsNew) && classes.new,
            checkFilter(isNew, defaultIsNew) && classes['is-new']
          )}
        >
          <ThunderIcon /> {t('New')}
        </div>
      )}

      {hotFilter && (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (isLoadingServices) return;

            setPriceMin(0);
            setShowMore(0);
            setIsHot(!(isHot || defaultIsHot));
            clearSeeMore();
            !showMore && setCurrentPage(1);
          }}
          className={clsx(
            classes.filter,
            !checkFilter(isHot, defaultIsHot) && classes.hot,
            checkFilter(isHot, defaultIsHot) && classes['is-hot']
          )}
        >
          <FireIcon /> {t('Hot deal')}
        </div>
      )}

      {saleFilter && (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (isLoadingServices) return;

            setPriceMin(0);
            setShowMore(0);
            setIsSale(!(isSale || defaultIsSale));
            clearSeeMore();
            !showMore && setCurrentPage(1);
          }}
          className={clsx(
            classes.filter,
            !checkFilter(isSale, defaultIsSale) && classes.sale,
            checkFilter(isSale, defaultIsSale) && classes['is-sale']
          )}
        >
          <PercentIcon /> {t('Sale')}
        </div>
      )}

      {topUpFilter && (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (isLoadingServices) return;

            setPriceMin(0);
            setShowMore(0);
            setIsTopUp(!(isTopUp || defaultIsTopUp));
            clearSeeMore();
            !showMore && setCurrentPage(1);
          }}
          className={clsx(
            classes.filter,
            !checkFilter(isTopUp, defaultIsTopUp) && classes.topup,
            checkFilter(isTopUp, defaultIsTopUp) && classes['is-topup']
          )}
        >
          <SpeakerIcon /> {t('Top up filter')}
        </div>
      )}
    </div>
  );
};

export default ProductListFilters;
