import { ReactElement, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { paginationSecond as getPaginationList } from 'tools/pagination';
import { ArrowPagination } from 'components/shared/Icons/ArrowPagination';
import classes from './BonusTablePagination.module.scss';
import { BonusTablePaginationProps } from './BonusTablePagination.props';

export const BonusTablePagination = ({
  className,
  currentPage,
  totalPages,
  simbol = '...',
  setPageNumber,
  extraAction,
  prevPage,
  nextPage,
  clearSeeMore,
}: BonusTablePaginationProps): ReactElement => {
  const [currentPagePagination, setCurrentPagePagination] =
    useState(currentPage);

  useEffect(() => {
    setCurrentPagePagination(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (extraAction) {
      extraAction();
    }
  }, [currentPagePagination, extraAction]);

  const paginationList = useMemo(
    () =>
      getPaginationList({
        currentPage: currentPagePagination,
        totalPages,
        simbol,
      }),
    [currentPagePagination, totalPages, simbol]
  );

  const onClick = (page: number | string, index?: number) => {
    clearSeeMore && clearSeeMore();

    if (typeof page === 'number') {
      setPageNumber(page);
    }

    if (page === simbol && index) {
      const currentPageIndex = paginationList.findIndex(
        (page) => page === currentPage
      );

      if (currentPageIndex > index) {
        const lastVisible = +paginationList[index + 1];

        setPageNumber(lastVisible - 2);
      } else if (currentPageIndex < index) {
        const lastVisible = +paginationList[index - 1];

        setPageNumber(lastVisible + 2);
      }
    }
  };

  return (
    <div className={clsx(className, classes.wrapper)}>
      <button
        className={clsx(classes.button, classes.button_prev)}
        disabled={currentPagePagination === 1}
        onClick={() => {
          onClick(currentPagePagination - 1);
          prevPage && prevPage();
        }}
      >
        <ArrowPagination fill="currentColor" rotate="left" />
      </button>

      <div className={clsx(classes['wrapper-pagination'])}>
        {paginationList.map((item, index) => (
          <div
            key={index}
            className={clsx(
              classes.item,
              typeof item !== 'number' && classes.symbol,
              currentPagePagination === item && classes.item_active
            )}
            onClick={() => {
              onClick(item, index);
              extraAction && extraAction();
            }}
          >
            {item}
          </div>
        ))}
      </div>

      <button
        className={clsx(classes.button, classes.button_next)}
        disabled={currentPagePagination === totalPages}
        onClick={() => {
          onClick(currentPagePagination + 1);
          nextPage && nextPage();
        }}
      >
        <ArrowPagination fill="currentColor" />
      </button>
    </div>
  );
};
