import CircleIcon from '../CircleIcon';
import classes from './IconWithDescription.module.scss';

interface IconWithDescriptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const IconWithDescription = ({
  icon,
  title,
  description,
}: IconWithDescriptionProps) => (
  <div className={classes.wrapper}>
    <CircleIcon icon={icon} />
    <div>
      <span className={classes.title}>{title}</span>
      <span className={classes.description}>{description}</span>
    </div>
  </div>
);

export default IconWithDescription;
