import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import i18n from 'i18n/i18n';
import manager_ru from 'assets/img/default_manager_image_ru.svg';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_URL } from 'services/http';
import User from 'services/user';
import { getBillMessage, integerToWords } from 'tools/numberToWords';
import { RUSSIAN_LOCALE } from 'utils';
import { getLangName } from 'tools/utils';
import { ProjectProps } from 'types/project';

export const tabsId = 'project_tabs';
export const tableHeaders = [
  { label: 'Invoices', icon: true },
  { label: 'Issued', icon: true },
  { label: 'Amount', icon: true },
  { label: 'Action', icon: true },
];

export const useProjectsDetail = () => {
  const userStore = useUserStore();
  const [tableActive, setTableActive] = useState(false);
  const [toPayCountMessage, setToPayCountMessage] = useState('');
  const { t } = useTranslation();
  const { isTablet } = useWindowWidth();
  const manager_img = RUSSIAN_LOCALE
    ? manager_ru
    : `${API_URL}/static/core/img/default_manager_image.png`;

  const tabid1 = 1;
  const tabid2 = 2;
  const tabid3 = 3;
  const tabs = [
    {
      id: tabid1,
      label: t('stages'),
    },
    {
      id: tabid2,
      label: t('goals'),
    },
    // {
    //   id: tabid3,
    //   label: t('invoices'),
    // },
    {
      id: tabid3,
      label: t('documents'),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [project, setProject] = useState(userStore?.projectData);
  const projectName = getLangName(project, 'name', i18n);
  // invoices
  const projectInvoices = userStore?.projectInvoices;
  const toPayCount = projectInvoices?.results?.to_pay_count;
  const partiallyPaidAmountSum = projectInvoices?.results?.partially_paid_sum;
  const totalDue = projectInvoices?.results?.total_sum - partiallyPaidAmountSum;
  const source =
    project?.source?.name &&
    (project?.source?.name?.includes('http')
      ? project?.source?.name
      : `https://${project?.source?.name}`);

  // Pagination
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(projectInvoices?.count / itemsPerPage);

  const fetchPaginationData = useCallback(
    async (currentPage: number) => {
      setTableActive(true);
      try {
        setCurrentPage(currentPage);
        await userStore.loadProjectInvoices(currentPage, project.id);
      } catch (error) {
        console.error(error);
      } finally {
        setTableActive(false);
      }
    },
    [currentPage]
  );

  const updateProject = async (): Promise<ProjectProps | undefined> => {
    try {
      const response = await User.getProject(project.id);

      if (response?.data) {
        setProject(response.data);
        return response.data;
      }
    } catch (error) {
      console.error('Failed to update project:', error);
    }

    return undefined;
  };

  useEffect(() => {
    updateProject();
  }, [userStore.user]);

  useEffect(() => {
    const countToWords = integerToWords(toPayCount, t);

    const message =
      Number(toPayCount) >= 1
        ? RUSSIAN_LOCALE
          ? `${countToWords} ${getBillMessage(toPayCount)}`
          : t('{{ name }} invoice is waiting for payment', {
              name: countToWords,
            })
        : t('No invoices to pay');

    setToPayCountMessage(message);
  }, [activeTab, toPayCount, t, userStore.user]);

  const formattedMessage =
    toPayCountMessage[0]?.toUpperCase() + toPayCountMessage?.slice(1);

  const shortProjectName =
    projectName?.length > 30 ? `${projectName?.slice(0, 30)}...` : projectName;
  const shortSource =
    source?.length > (isTablet ? 35 : 60)
      ? `${source?.slice(0, isTablet ? 35 : 60)}...`
      : source;

  return {
    project,
    projectName,
    t,
    isTablet,
    shortProjectName,
    source,
    tabs,
    shortSource,
    tabid3,
    tabid2,
    tabid1,
    activeTab,
    setActiveTab,
    projectInvoices,
    formattedMessage,
    totalDue,
    tableActive,
    fetchPaginationData,
    currentPage,
    totalPages,
    updateProject,
    manager_img,
  };
};
