import { useState } from 'react';
import { financesStore } from 'stores/financesStore';

export const useTopUpBalance = (onClose: () => void) => {
  const [value, setValue] = useState('');

  const handleTopUpBalance = async (finalPrice: number) => {
    const payment_url = await financesStore.refillBalance(finalPrice);

    if (payment_url) {
      window.location.href = payment_url;
    }

    setValue('');
    onClose();
  };

  return { handleTopUpBalance, value, setValue };
};
