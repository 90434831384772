import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { pushToDataLayer } from 'tools/analytics';
import { Goal } from 'types/project';
import { GoalsProps } from './Goals.props';
import classes from './ProjectGoals.module.scss';
import { useGoals } from './useGoals';

export const ProjectGoalsContainer = observer(
  ({ project, updateProject, tabsId }: GoalsProps) => {
    const {
      setNewGoal,
      setAddAnotherGoal,
      setIsFocused,
      setProjectId,
      projectGoals,
      handleGoal,
      setIsClicked,
      goalName,
      addAnotherGoal,
      maxLength,
      newGoal,
      editableDivRef,
      handleKeyDown,
      handleChange,
      t,
      isClicked,
      systemProjectGoals,
    } = useGoals(project, updateProject, tabsId);

    const icon = () => (
      <svg
        onClick={() => {
          setNewGoal(null);
          setAddAnotherGoal(false);
          setIsFocused(false);
          setProjectId(undefined);
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.00016 3.33301V12.6663M3.3335 7.99967H12.6668"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

    return (
      <div className={classes.goals}>
        <div className={classes.goals__main}>
          {projectGoals?.map((goal: Goal) => {
            const finalGoal = (goal?.goal ? goal.goal : goal) as Goal;
            return (
              <div
                className={clsx(
                  classes.goals__item,
                  classes.goals__item_active
                )}
                onClick={() => {
                  handleGoal(project.id, goal, true);
                  setIsClicked(true);
                }}
              >
                <div>{goalName(finalGoal)}</div>
                <span className={classes.goalLength}>
                  {goalName(goal)?.length}/{maxLength}
                </span>
                {icon()}
              </div>
            );
          })}

          {addAnotherGoal && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_active)}
              onKeyDown={handleKeyDown}
            >
              <input
                ref={editableDivRef}
                className={classes.input}
                value={goalName(newGoal as Goal)}
                maxLength={40}
                onChange={handleChange}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  !goalName(newGoal as Goal) && setIsFocused(false);
                }}
              />
              <span className={classes.goalLength}>
                {goalName(newGoal as Goal)?.length || 0}/{maxLength}
              </span>
              {icon()}
            </div>
          )}

          {projectGoals?.length > 0 && (
            <div
              className={clsx(classes.goals__item, classes.goals__item_add)}
              onClick={() => setAddAnotherGoal(true)}
            >
              <span>{t('Add another…')}</span>
              {icon()}
            </div>
          )}

          {projectGoals?.length <= 0 && !addAnotherGoal && (
            <div className={clsx(classes.goals__empty_block)}>
              <p className={clsx(classes.goals__empty_text)}>
                {t('Add goals so they appear here')}
              </p>
              <div
                className={clsx(classes.goals__empty_btn)}
                onClick={() => {
                  setAddAnotherGoal(true);
                }}
              >
                {t('Add goal')}
                {icon()}
              </div>
            </div>
          )}
        </div>

        {systemProjectGoals?.length > 0 && (
          <div className={classes.goals__bottom_text}>
            {t('Perhaps your goals are among these')}
          </div>
        )}

        <div className={classes.goals__bottom}>
          {systemProjectGoals.map((goal: Goal) => (
            <div
              key={goal.id}
              className={classes.goals__item}
              onClick={() => {
                if (!isClicked) {
                  handleGoal(project.id, goal);
                  pushToDataLayer('addGoals', 'buttonClick', 'GAForms');
                }
              }}
            >
              <div>{goalName(goal)}</div>
              {icon()}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
