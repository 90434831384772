import clsx from 'classnames';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { Input } from 'components/shared/Input';
import _ from 'lodash';
import classes from './MultiselectComponent.module.scss';
import {
  MultiselectComponentProps,
  Option,
} from './MultiselectComponent.props';
import { useMultiselectComponent } from './useMultiselectComponent';
import { RUSSIAN_LOCALE } from 'utils';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import { QuestionIcon } from '../Icons/QuestionIcon';

export const MultiselectComponent = <T extends Option>({
  data,
  defaultSelected,
  placeholder,
  search = false,
  setMultiselect,
  langSelect,
  isCompanySize,
  isFieldEmpty,
  isCurrency,
  className,
  isDisabled,
  helperText,
  isBackground,
}: MultiselectComponentProps<T>) => {
  const {
    ref,
    setOpen,
    open,
    currentValue,
    sourcesToShow,
    inputValue,
    setInputValue,
    t,
    getPlacehoderDropdown,
    getDefaultValueMultiselect,
    getPlacehoderMultiselect,
    isPlaceholderVisible,
    setCurrentValue,
    getDataAttribute,
  } = useMultiselectComponent<T>(
    setMultiselect,
    data,
    defaultSelected,
    isCompanySize,
    placeholder,
    langSelect,
    isCurrency
  );

  return (
    <div
      ref={ref}
      className={clsx(
        classes.wrapper,
        langSelect && classes.selectLang,
        RUSSIAN_LOCALE && !isBackground && classes.ru,
        isDisabled && classes.disabled,
        className
      )}
    >
      <div
        onClick={() => {
          !isDisabled && setOpen(!open);
        }}
        className={clsx(
          classes['main-input'],
          open && classes.dropActive,
          isFieldEmpty && classes.error
        )}
      >
        <Input
          className={clsx(
            classes.drop,
            isPlaceholderVisible && classes.placeholder
          )}
          readOnly={true}
          placeholder={getPlacehoderMultiselect()}
          value={getDefaultValueMultiselect() || placeholder || ''}
        />
        <div className={clsx(classes.arrow, open && classes.arrowActive)}>
          <ArrowDownIcon />
        </div>
        {helperText && (
          <TooltipContainer
            text={helperText}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={classes.helper}
          >
            <QuestionIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>

      {isFieldEmpty && (
        <span className={classes.errorMessage}>
          {t('Fill in the fields to better understand your business.')}
        </span>
      )}

      {open && data && (
        <div className={classes.dropdown}>
          {search && (
            <div className={classes['enter-source']}>
              <Input
                value={inputValue || ''}
                onChange={(e) => setInputValue(e)}
                placeholder={getPlacehoderDropdown()}
                className={classes.input}
              />
            </div>
          )}

          <div className={classes.list}>
            {sourcesToShow &&
              sourcesToShow?.map((item: T, index: number) => (
                <div
                  key={`${item.id}${index}`}
                  onClick={() => {
                    setCurrentValue(item);
                    setOpen(false);
                    setMultiselect(item);
                  }}
                  className={clsx(
                    classes.item,
                    (item?.name === currentValue?.name ||
                      item.id === currentValue?.id) &&
                      classes.active
                  )}
                >
                  {item[getDataAttribute()]}
                  <span>{item['description']}</span>
                  {item?.id === currentValue?.id && (
                    <CheckIcon fill="#8362F3" />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
