import clsx from 'clsx';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { KrestIcon } from 'components/shared/Icons/KrestIcon';
import { PasswordCheckItemProps } from './PasswordCheckItem.props';
import classes from '../ChangePassword.module.scss';

export const PasswordCheckItem = ({
  isValid,
  passwordLength,
  text,
}: PasswordCheckItemProps) => (
  <div className={classes.check}>
    <div className={classes.icon}>
      {passwordLength === 0 && <CheckIcon fill="#A09CAC" />}
      {isValid && passwordLength >= 1 ? (
        <CheckIcon fill="#00C853" />
      ) : (
        passwordLength > 0 && <KrestIcon />
      )}
    </div>
    <div
      className={clsx(
        classes.name,
        isValid && passwordLength >= 1
          ? classes.correct
          : passwordLength > 0 && classes.wrong
      )}
    >
      {text}
    </div>
  </div>
);
