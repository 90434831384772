import 'styles/main.scss';
import { useTranslation } from 'react-i18next';
import { CardsContainer } from 'containers/CardsContainer';

// todo add localization feature
export const Favorites = () => {
  const { t } = useTranslation();

  return <CardsContainer title={t('Favorites')} />;
};
