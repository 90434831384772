import clsx from 'clsx';
import { CheckBoxIcon } from 'components/shared/Icons/CheckBoxIcon';
import { CheckboxProps } from './Checkbox.props';

export const Checkbox = ({
  className,
  onChange,
  disabled,
  name,
  checked,
  label,
}: CheckboxProps) => (
  <label className={clsx('common__checkbox', className)}>
    <input
      onChange={() => onChange(!checked)}
      name={name}
      type="checkbox"
      className="common__checkbox--hidden"
      disabled={disabled}
      checked={checked || false}
    />

    <span className="common__checkbox-visible">
      {checked && <CheckBoxIcon width="11px" height="9px" />}
    </span>

    {label && <span className="common__checkbox-label">{label}</span>}
  </label>
);
