import * as React from 'react';
import { SVGProps } from 'react';

export const ArrowNarrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 7H2.33337M2.33337 7L5.83337 10.5M2.33337 7L5.83337 3.5"
      stroke="#9C93AA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
