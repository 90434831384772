import DOMPurify from 'dompurify';
import classes from './TextWrapper.module.scss';
import { formatHtmlText } from 'tools/formatHtmlText';
import './TextWrapper.scss';

interface TextWrapperProps {
  text: string;
}

const TextWrapper = ({ text }: TextWrapperProps) => {
  return (
    <div className={classes.wrapper}>
      <div
        dangerouslySetInnerHTML={formatHtmlText(DOMPurify.sanitize(text))}
        className={classes.text}
      />
    </div>
  );
};

export default TextWrapper;
