import React, { SVGProps } from 'react';

const FilterTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M4 8H12M2 4H14M6 12H10"
      stroke="#9C93AA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterTableIcon;
