import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './Employees.module.scss';
import { Type } from 'components/shared/Type';
import { useTranslation } from 'react-i18next';
import EmployeesInvite from './EmployeesInvite/EmployeesInvite';
import { tableDataEmployees } from './employeesTableData';
import { observer } from 'mobx-react-lite';
import TableEmployees from './TableEmployees';
import { useEmployees } from './useEmployees';
import { CLIENT_ROLE } from 'types/user';
import { RUSSIAN_LOCALE } from 'utils';

const Employees = observer(() => {
  const { t } = useTranslation();
  const {
    client,
    isLoading,
    clients,
    columns,
    getUsers,
    handleDeletePermissions,
    handleChangePermissions,
  } = useEmployees();

  const isAdmin = client?.permission?.type === CLIENT_ROLE.ADMIN;

  return (
    <div className={classes.container}>
      <TitleAndDescription pageName="Account/employees" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} className={classes.title}>
            {t('Employees')}
          </Type>
        </div>
        <p className={classes['info']}>
          {isAdmin ? (
            t(
              'Inviting employees to collaborate on the platform and managing access levels'
            )
          ) : RUSSIAN_LOCALE ? (
            <>
              {t('View employee invitations and check access levels')}.
              <br />
              {t('Inviting new employees is not available for your role')}
            </>
          ) : (
            t('View employee invitations and check access levels')
          )}
        </p>

        {isAdmin && RUSSIAN_LOCALE && (
          <EmployeesInvite client={client} getUsers={getUsers} />
        )}

        {isLoading || !clients ? (
          <div>{t('Loading...')}</div>
        ) : (
          <TableEmployees
            additionalClassNames={classes.table}
            data={tableDataEmployees(clients, t)}
            columns={columns}
            client={client}
            handleDeletePermissions={handleDeletePermissions}
            handleChangePermissions={handleChangePermissions}
          />
        )}
      </div>
    </div>
  );
});

export default Employees;
