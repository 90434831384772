import { useState } from 'react';
import clsx from 'classnames';
import classes from './MobileFilterModal.module.scss';
import UiIcon from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Portal } from 'components/common/Portal';
import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { MobileFilterModalProps } from './MobileFilterModal.props';

const MobileFilterModal = function ({
  title,
  titleClassName,
  onClose,
  children,
  isOutsideBase,
}: MobileFilterModalProps) {
  const [isClosing, setIsClosing] = useState(false);

  useDisableScrolling();

  const handleClose = () => {
    setIsClosing(true);
    onClose();
  };

  return (
    <Portal
      selector={isOutsideBase ? '.restore-container' : '.layout-container'}
    >
      <div
        className={clsx(classes.overlay, { [classes.close]: isClosing })}
        onClick={handleClose}
      />
      <div
        className={clsx(classes.fixedButton, { [classes.close]: isClosing })}
      >
        <div className={classes.title}>
          <div className={titleClassName}>{title}</div>
          <Button theme="text" onClick={handleClose}>
            <UiIcon name="CrossIcon" />
          </Button>
        </div>

        <div className={classes.content}>{children}</div>
      </div>
    </Portal>
  );
};

export default MobileFilterModal;
