import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import clsx from 'clsx';
import classes from './DashboardContainer.module.scss';
import Select from '../../components/shared/SelectComponent';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import emptyDashboard from 'assets/img/Dashboard/EmptyDashboard.svg';
import emptyDashboard_ru from 'assets/img/Dashboard/EmptyDashboard_ru.svg';
import { TitleAndDescription } from '../../components/shared/TitleAndDescription/TitleAndDescription';
import { GetDashboardParams } from 'services/api/getDashboardParams';
import { GetDemoDashboardParams } from 'services/api/getDemoDashboardParams';
import {
  GetDashboardProjects,
  GetDemoDashboards,
} from 'services/api/getDashboardProjects';
import { RUSSIAN_LOCALE } from 'utils';
import { DashboardPowerBI } from './DashboardPowerBI';
import { Notice } from '../../components/shared/Notice';

interface DashboardContainerProps {
  title: string;
}

export const DashboardContainerRU: FC<DashboardContainerProps> = ({
  title,
}) => {
  const { t } = useTranslation();

  const [dashboardUserProjects, setDashboardUserProjects] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [openSelect, setOpenSelect] = useState(false);
  const { isMediaTablet } = useWindowWidth();
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [selectedReportID, setSelectedReportID] = useState('');
  const [selectedEmbedUrl, setSelectedEmbedUrl] = useState('');
  const [currentAccessToken, setCurrentAccessToken] = useState('');
  const [dashboardSelectTitle, setDashboardSelectTitle] = useState(
    t('Projects')
  );
  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    const getDashboardProjects = async () => {
      try {
        return await GetDashboardProjects();
      } catch (error) {
        console.log(error, 'Get DashboardParams Error');
      }
    };
    const response = getDashboardProjects();
    response.then((projects) => {
      setDashboardUserProjects(projects.results);
      if (projects.results?.length > 0) {
        setSelectedProject(projects.results?.[0]);
        setIsDemo(false);
      } else {
        setDashboardSelectTitle(t('Demo Dashboards'));
        const response = GetDemoDashboards();
        response.then((demoDashboards) => {
          setDashboardUserProjects(demoDashboards);
          setSelectedProject(demoDashboards?.[0]);
          setIsDemo(true);
        });
      }
    });
  }, []);
  useEffect(() => {
    if (!!selectedProject) {
      if (selectedProject?.is_demo) {
        const response = GetDemoDashboardParams({
          access: cookies.access,
          refresh: cookies.refresh,
          reportID: selectedProject?.report_id,
        });
        response.then((params) => {
          setSelectedReportID(params?.reportConfig.reportID);
          setSelectedEmbedUrl(params?.reportConfig.embedUrl);
          setCurrentAccessToken(params?.accessToken);
        });
      } else {
        const response = getDashBoardParams(
          selectedProject.id,
          selectedProject.powerbi_report_id
        );
        response.then((params) => {
          setSelectedReportID(params?.reportConfig.reportID);
          setSelectedEmbedUrl(params?.reportConfig.embedUrl);
          setCurrentAccessToken(params?.accessToken);
        });
      }
    }
  }, [selectedProject]);

  const getDashBoardParams = async (projectID: string, reportID: string) => {
    try {
      const response = await GetDashboardParams({
        access: cookies.access,
        refresh: cookies.refresh,
        projectID: projectID,
        reportID: reportID,
      });
      return response;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
    }
  };

  const emptyPage = (
    <div className={clsx(classes.emptyPage, 'empty-background')}>
      <div className={classes.emptyPage__icon}>
        <img
          src={RUSSIAN_LOCALE ? emptyDashboard_ru : emptyDashboard}
          alt={t('This information is only available on the desktop version')}
          className={classes.emptyPage__image}
        />
      </div>
      <div className={classes.emptyPage__text}>
        {t('This information is only available on the desktop version')}
      </div>
    </div>
  );

  return (
    <>
      {isMediaTablet && <div className={classes.background} />}

      <div className={clsx(classes.wrapper)}>
        <TitleAndDescription pageName="Dashboard" />
        <div className={classes.title}>{title}</div>
        {!isMediaTablet ? (
          <div className={classes.headerContainer}>
            <div className={classes.selectContainer}>
              <Select
                select={selectedProject}
                setSelect={setSelectedProject}
                options={dashboardUserProjects}
                open={openSelect}
                setOpen={setOpenSelect}
                title={dashboardSelectTitle}
                boxClassName={classes.selectBox}
                wrapperClassName={classes.selectWrapper}
              />
            </div>
            {isDemo && (
              <Notice
                type="default"
                isCustomNotice
                title="Данные представлены в демо режиме"
                description={`<span>
                  Переключайтесь между типами решений, чтобы увидеть разные
                  варианты отображения статистики. </br> Реальную аналитику вы сможете
                  видеть после передачи ваших проектов в производство
                </span>`}
              />
            )}
          </div>
        ) : (
          emptyPage
        )}
      </div>
      {!isMediaTablet && (
        <div className={clsx(classes.powerbi_dashboard)}>
          <DashboardPowerBI
            reportID={selectedReportID}
            embedUrl={selectedEmbedUrl}
            accessToken={currentAccessToken}
          />
        </div>
      )}
    </>
  );
};
