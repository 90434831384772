import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M1 9L9 1M9 1H3.66667M9 1V6.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgArrowUpRight;
