import * as React from 'react';
import { SVGProps } from 'react';

export const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 4.75a2.138 2.138 0 0 0-2.125 2.125v38.25c0 1.164.961 2.125 2.125 2.125h29.75a2.125 2.125 0 0 1 0 4.25H6.5c-3.511 0-6.375-2.864-6.375-6.375V6.875C.125 3.364 2.989.5 6.5.5h51c3.511 0 6.375 2.864 6.375 6.375V30.25a2.125 2.125 0 0 1-4.25 0V6.875A2.138 2.138 0 0 0 57.5 4.75h-51Zm3.499 10.803a2.125 2.125 0 0 1 2.92-.707L32 26.496l19.08-11.65a2.125 2.125 0 0 1 2.215 3.628L33.107 30.799a2.125 2.125 0 0 1-2.214 0L10.705 18.474A2.125 2.125 0 0 1 10 15.553Zm53.055 22.346c.822.837.81 2.183-.028 3.005L51.34 52.379a2.125 2.125 0 0 1-2.992-.014l-7.225-7.225a2.125 2.125 0 1 1 3.006-3.005l5.736 5.736 10.185-10a2.125 2.125 0 0 1 3.005.028Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={47.378}
        y1={0.5}
        x2={44.406}
        y2={53.681}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#65B2FF" />
        <stop offset={1} stopColor="#6638F4" />
      </linearGradient>
    </defs>
  </svg>
);
