import * as React from 'react';
import { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '1em'}
    height={props.height || '1em'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.107 4.69H.964c-.153 0-.233.179-.142.297l4.475 5.838c.23.3.67.299.901 0L14.178.412c.092-.117.01-.296-.142-.296h-1.142a.565.565 0 0 0-.45.225L5.747 9.077l-3.19-4.163a.576.576 0 0 0-.45-.225Z"
      stroke="#A09CAC"
      strokeWidth={0.1}
    />
  </svg>
);
