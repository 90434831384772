import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import AddToCartButton from 'components/shared/Button/AddToCartButton';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { FilledHeartIcon } from 'components/shared/Icons/FilledHeartIcon';
import { HeartIcon } from 'components/shared/Icons/HeartIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import PriceText from 'components/shared/PriceText';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useCartStore } from 'contexts/CartContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useUserStore } from 'contexts/UserContext';
import { useFavorites } from 'hooks';
import { useCheckCurrency } from 'hooks/useCheckCurrency';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classes from './Price.module.scss';
import { PriceProps } from './Price.props';
import { OptionType } from 'types/util-types';
import BonusCount from 'components/shared/BonusCount/BonusCount';
import { calculateBonusCount } from 'tools/calculateBonusCount';

export const Price = observer(
  ({ data, className, deals, nameSource }: PriceProps) => {
    const marketPlaceStore = useMarketPlaceStore();
    const { isMediaTablet } = useWindowWidth();
    const cartStore = useCartStore();
    const userStore = useUserStore();
    const { t } = useTranslation();

    const activeDeals = marketPlaceStore.dataTimeHotDeals.activeDeals;
    const featureDeals = marketPlaceStore.dataTimeHotDeals.featureDeals;

    const [isFavorite, setIsFavorite] = useState(data?.is_favorite);
    const [, setCartSources] = useState<OptionType[]>();
    const [, setIsInCart] = useState(false);

    useEffect(() => {
      if (toJS(cartStore.cart)) {
        if (Object.keys(toJS(cartStore.cart!)).length !== 0) {
          const newCart = toJS(cartStore.getActiveCart());
          newCart?.basket_project_service?.forEach((service) => {
            if (service.service.id === data.id) {
              setIsInCart(true);
              setCartSources(service.source);
            }
          });
        }
      }
    }, [cartStore.cart]);

    useEffect(() => {
      setIsFavorite(data?.is_favorite);
    }, [data]);

    const changeFavorites = useFavorites(data.id, isFavorite, setIsFavorite);

    const checkDiscount =
      data?.has_active_discounts &&
      Number(data.cost) !== Number(data.cost_with_discounts)
        ? Number(data.cost_with_discounts)
        : Number(data.cost);

    const checkCurrency = useCheckCurrency();

    const formattedPrice = (price?: number | string, withMinus?: boolean) => (
      <FormatPrice
        currency={userStore.user?.currency?.symbol}
        price={price}
        withMinus={withMinus}
      />
    );

    const client = userStore.client;

    const bonusCount = calculateBonusCount(
      client?.level?.name,
      data.cost_with_discounts || data.cost
    );

    const isDiscount =
      data?.has_active_discounts &&
      Number(data.cost) !== Number(data.cost_with_discounts) &&
      !data.discounts?.find(
        (item: any) => item.cost_change_type === 'PRICE_NEW'
      );

    return (
      <>
        <div
          className={clsx(
            className && classes[className],
            classes['price-block'],
            deals.active && activeDeals && !featureDeals && classes.priceDeals
          )}
        >
          <div className={classes.price}>
            <div className={classes.text}>
              <PriceText data={data} t={t} asteriskSymbol />
            </div>

            {data?.is_free ? (
              <div className={classes.cost}>{t('Free')}</div>
            ) : (
              <div className={classes.price__wrapper}>
                <div className={clsx(classes.cost, checkCurrency)}>
                  <span className={classes.current_price}>
                    {formattedPrice(checkDiscount, false)}{' '}
                  </span>
                  {isDiscount && (
                    <span className={clsx(classes.discount, checkCurrency)}>
                      {formattedPrice(data.cost, false)}
                    </span>
                  )}
                </div>
                {bonusCount && (
                  <div className={classes.bonusCount}>
                    <BonusCount size="m" type="filled" count={bonusCount} />
                  </div>
                )}
              </div>
            )}
          </div>

          {deals?.active && activeDeals && !featureDeals && !isMediaTablet && (
            <div>
              <div className={classes.tooltipWrap}>
                <span className={classes.closeTimeInner}>
                  {t('Closing time')}:{' '}
                  <span className={classes.closeTime}>
                    {deals.dateDealDate} {deals.monthEndDate}{' '}
                    {deals.yearEndDate}{' '}
                  </span>
                </span>

                <div className={classes.closeTimeTooltip}>
                  <TooltipContainer
                    text={t(
                      'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                    )}
                    position={'top'}
                    className={classes.tooltipContainer}
                    classNameTooltip={classes.tooltip}
                  >
                    <InfoIcon fill="#A09CAC" width="18px" height="18px" />
                  </TooltipContainer>
                </div>
              </div>
            </div>
          )}

          <div className={classes.buttons} data-step-solutions_details-3>
            <AddToCartButton data={data} />

            <div className={classes.favored}>
              <Button
                className={classes.favorite}
                theme="icon-default"
                onClick={changeFavorites}
              >
                {isFavorite ? <FilledHeartIcon /> : <HeartIcon />}
              </Button>
            </div>
          </div>

          {!data?.is_free && (
            <div className={classes.priceDesciption}>
              *{' '}
              {t(
                "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our "
              )}{' '}
              <NavLink
                to={'/pricing-disclaimer/'}
                state={{ name: nameSource }}
                className={classes.disclaimer}
              >
                {t('Pricing Disclaimer')}
              </NavLink>
            </div>
          )}
        </div>
      </>
    );
  }
);
