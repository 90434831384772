import clsx from 'clsx';

export const Tooltip = (props) => (
  <div className={clsx('common__tooltip', props.position, props.className)}>
    <span className={clsx('common__tooltip-inner', props.tooltipInner)}>
      <span className={clsx('common__tooltip-text common__tooltip-text')}>
        {props.children}
      </span>
    </span>
  </div>
);
