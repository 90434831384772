import { Tooltip } from './Tooltip';
import { t } from 'i18next';
import React from 'react';

export const TooltipContainer = (props) => {
  const [active, setActive] = React.useState(false);

  const onShowTooltip = React.useCallback(() => {
    setActive(true);
  }, []);

  const onHideTooltip = React.useCallback(() => {
    setActive(false);
  }, []);

  return (
    <div className={props.className}>
      {props.children}

      <span
        className={'common__tooltip--hidden'}
        onMouseOver={onShowTooltip}
        onMouseOut={onHideTooltip}
      ></span>

      {active && (
        <Tooltip
          {...props}
          className={props.classNameTooltip}
          position={props.position}
        >
          {t(props.text)}
        </Tooltip>
      )}
    </div>
  );
};
