import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const CartGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="url(#paint0_linear_7355_6181)"
      fillRule="evenodd"
      d="M13.247 33h21.3c3.66 0 6.86-2.96 7.08-6.62l1.08-15c.16-2.02-.52-3.92-1.86-5.38-1.36-1.44-3.22-2.24-5.24-2.24h-24.98c-.22-.78-.62-1.48-1.16-2.08A5.207 5.207 0 0 0 5.647 0h-3.48c-.82 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5h3.46c.62 0 1.2.24 1.62.7.42.46.64 1.06.58 1.68L6.167 25.3c-.16 1.98.52 3.94 1.86 5.4a7.103 7.103 0 0 0 5.22 2.3Zm-4.08-7.46 1.56-18.8v-.02h24.9c1.18 0 2.26.46 3.04 1.3.78.82 1.16 1.94 1.08 3.12l-.062.86H16.167c-.82 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5h23.302l-.802 11.14c-.16 2.08-2.04 3.84-4.12 3.84h-21.3c-1.16 0-2.24-.46-3.02-1.32a4.067 4.067 0 0 1-1.06-3.12ZM26.667 39c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4-4 1.8-4 4Zm3 0c0-.56.44-1 1-1s1 .44 1 1-.44 1-1 1-1-.44-1-1Zm-15 4c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4Zm0-5c-.56 0-1 .44-1 1s.44 1 1 1 1-.44 1-1-.44-1-1-1Z"
      clipRule="evenodd"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_7355_6181"
          x1="14.2564"
          y1="2.5"
          x2="40.4908"
          y2="42.6854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E47267" />
          <stop offset="1" stopColor="#EB5945" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_7355_6181"
          x1={31.845}
          x2={28.827}
          y1={0}
          y2={43.495}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65B2FF" />
          <stop offset={1} stopColor="#6638F4" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
