import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const ProjectIcon = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = React.useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        {RUSSIAN_LOCALE ? (
          <linearGradient
            id={`${gradientId}`}
            x1="-2.96239"
            y1="-4.88"
            x2="14.4776"
            y2="14.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFB3D2"/>
            <stop offset="1" stop-color="#FF4848"/>
          </linearGradient>
        ) : (
          <linearGradient id={`${gradientId}`} gradientTransform="rotate(140)">
            <stop offset="0%" stopColor="#6e3cff" />
            <stop offset="100%" stopColor="#4ba5ff" />
          </linearGradient>
        )}
      </defs>

      <path
        d="M20.28 6.79024H12.2025L9.56325 4.05366C9.52967 4.01958 9.4855 4.00043 9.4395 4H3.72C3.32175 4 3 4.34878 3 4.78049V19.2195C3 19.6512 3.32175 20 3.72 20H20.28C20.6782 20 21 19.6512 21 19.2195V7.57073C21 7.13902 20.6782 6.79024 20.28 6.79024ZM19.38 18.2439H4.62V5.7561H8.86125L11.5522 8.54634H19.38V18.2439Z"
        fill={`url(#${gradientId})`}
      />
    </svg>
  );
};
