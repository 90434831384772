import { RUSSIAN_LOCALE } from 'utils';
import classes from './OurTeam.module.scss';
import clsx from 'clsx';

interface OurTeamProps {
  data: '';
  title: string;
}

export const OurTeam = ({ data, title }: OurTeamProps) => (
  <div className={clsx(classes.wrapper, RUSSIAN_LOCALE && classes.wrapper__ru)}>
    <div className={classes.title}>{title}</div>
    <div dangerouslySetInnerHTML={{ __html: data }} />
  </div>
);
