import BonusCount from 'components/shared/BonusCount/BonusCount';
import style from './BonusCountBasket.module.scss';
import { BonusCountBasketProps } from './BonusCountBasket.props';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BonusCountBasket = ({ count }: BonusCountBasketProps) => {
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <div className={style.title}>{t('Bonus points')}</div>
      <div className={style.description}>
        <span>
          {t('We will add points for the order')}{' '}
          <Link to={'/programma-rosta'} className={style.link}>
            {t('in the Growth Program')}.
          </Link>
        </span>
        <span>{t('You will be able to write them off in 6 months')}</span>
      </div>

      <BonusCount count={count} type="transparent" size="m" isTooltip={false} />
    </div>
  );
};

export default BonusCountBasket;
