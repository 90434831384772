import { Modal } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import clsx from 'clsx';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import classes from '../../CartCard.module.scss';
import { useTranslation } from 'react-i18next';
import { useBreakPoints } from '../../useBreakPoints';
import { useUserStore } from 'contexts/UserContext';

interface PriceInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  itemCount: number;
  cardCostWithText: string;
  hasDiscount: boolean;
}

export const PriceInfoModal = ({
  isOpen,
  onClose,
  itemCount,
  cardCostWithText,
  hasDiscount,
}: PriceInfoModalProps) => {
  const { t } = useTranslation();
  const { isTablet } = useBreakPoints();
  const userStore = useUserStore();

  const currency = userStore?.user?.currency?.symbol;

  const modalContent = () => {
    const text = t(
      'You’ll get the final price after consultation with a manager'
    );
    const words = text.split(' ');
    const firstPart = words.slice(0, 5).join(' ');
    const secondPart = words.slice(5).join(' ');

    return `${firstPart}<br />${secondPart}`;
  };

  return (
    <Modal title={''} isPriceModal isOpen={isOpen} onClose={onClose}>
      <div
        className={clsx(classes.modalPriceContainer, classes.modalContainer)}
      >
        <div className={classes.modalInner}>
          {isTablet && itemCount > 1 && cardCostWithText && hasDiscount ? (
            <p className={clsx(classes.modalText)}>
              <FormatPrice currency={currency?.symbol || ''} />
              {cardCostWithText}
            </p>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: modalContent() }}
              className={classes.modalText}
            />
          )}
        </div>

        <div className={classes.buttonModalWrap}>
          <Button
            theme="primary"
            onClick={onClose}
            className={classes.buttonModal}
          >
            {t('I understood')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
