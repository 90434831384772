import { Request } from 'tools/request';

export async function GetCurrencies({ access }: { access: string }) {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/currencies/?all=${true}`,
    isProtected: true,
    access: access,
  });

  return result;
}
