import classes from './Map.module.scss';
import './YandexMapStyles.scss';
import {
  Map as YandexMap,
  Placemark,
  ZoomControl,
} from '@pbe/react-yandex-maps';

interface MapProps {
  addressName: string;
  coordinates: number[];
  zoom?: number;
  zoomPosition: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

const Map = ({
  addressName,
  coordinates,
  zoom = 16,
  zoomPosition,
}: MapProps) => (
  <div className={classes.map}>
    <span className={classes.map__title}>Адрес</span>
    <span className={classes.map__address}>{addressName}</span>
    <div className={classes.map__container}>
      <YandexMap
        state={{
          center: coordinates,
          zoom: zoom,
        }}
        width={'100%'}
        height={'100%'}
        defaultState={{
          center: [55.7543158643834, 37.620670853964285], //Red Square
          zoom: zoom,
        }}
      >
        <ZoomControl
          options={{
            position: zoomPosition,
            size: 'small',
          }}
        />
        <Placemark
          modules={['geoObject.addon.hint']}
          defaultGeometry={[55.7543158643834, 37.620670853964285]}
          geometry={coordinates}
          properties={{
            hintContent: addressName,
          }}
        />
      </YandexMap>
    </div>
  </div>
);

export default Map;
