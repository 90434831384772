import * as React from 'react';
import { SVGProps } from 'react';

export const ChatMessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="250"
    height="200"
    viewBox="0 0 250 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z"
      fill="#F7F5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.577 126.075C166 128 163.488 129.81 160.644 131.374L160.809 145.457C160.822 146.562 159.937 147.467 158.832 147.48C158.383 147.486 157.945 147.339 157.589 147.066L144.926 137.323C140.18 138.416 135.173 139 130 139C99.6243 139 75 118.853 75 94C75 69.1472 99.6243 49 130 49C160.376 49 185 69.1472 185 94C185 100.879 183.114 107.397 179.741 113.227C179.119 114.303 178.446 115.355 177.726 116.381C177.726 116.381 176.317 118.5 173.5 121.5C170.683 124.5 168.577 126.075 168.577 126.075Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.577 126.075C166 128 163.488 129.81 160.644 131.374L160.809 145.457C160.822 146.562 159.937 147.467 158.832 147.48C158.383 147.486 157.945 147.339 157.589 147.066L144.926 137.323C140.18 138.416 135.173 139 130 139C99.6243 139 75 118.853 75 94C75 69.1472 99.6243 49 130 49C160.376 49 185 69.1472 185 94C185 100.879 183.114 107.397 179.741 113.227C179.119 114.303 178.446 115.355 177.726 116.381C177.726 116.381 176.317 118.5 173.5 121.5C170.683 124.5 168.577 126.075 168.577 126.075Z"
      stroke="#8362F3"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.853 81.5895C168.157 81.5895 173.266 80.957 178.051 79.7868C179.961 84.1364 181 88.8232 181 93.7074C181 100.043 179.251 106.047 176.123 111.417C175.547 112.408 174.923 113.376 174.255 114.322C173.2 115.815 172.448 116.869 171.785 117.714C170.219 119.713 169.155 120.544 165.771 123.25C163.515 125.055 161.052 126.691 158.415 128.131L158.568 141.103C158.58 142.12 158.889 143.988 157.865 144C157.449 144.005 155.913 142.837 155.583 142.584L145.373 133.467C140.972 134.473 134.797 135.156 130 135.156C101.833 135.156 79 116.599 79 93.7074C79 74.961 94.3133 59.1215 115.328 54C120.832 69.8745 140.023 81.5895 162.853 81.5895ZM110.291 88.1483C106.986 88.1483 104.307 90.8448 104.307 94.1711C104.307 97.4974 106.986 100.194 110.291 100.194C113.596 100.194 116.275 97.4974 116.275 94.1711C116.275 90.8448 113.596 88.1483 110.291 88.1483ZM129.241 88.1483C125.936 88.1483 123.257 90.8448 123.257 94.1711C123.257 97.4974 125.936 100.194 129.241 100.194C132.546 100.194 135.225 97.4974 135.225 94.1711C135.225 90.8448 132.546 88.1483 129.241 88.1483ZM148.191 88.1483C144.886 88.1483 142.207 90.8448 142.207 94.1711C142.207 97.4974 144.886 100.194 148.191 100.194C151.496 100.194 154.176 97.4974 154.176 94.1711C154.176 90.8448 151.496 88.1483 148.191 88.1483Z"
      fill="#F2ECFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M110 88C113.314 88 116 90.6863 116 94C116 97.3137 113.314 100 110 100C106.686 100 104 97.3137 104 94C104 90.6863 106.686 88 110 88ZM129 88C132.314 88 135 90.6863 135 94C135 97.3137 132.314 100 129 100C125.686 100 123 97.3137 123 94C123 90.6863 125.686 88 129 88ZM148 88C151.314 88 154 90.6863 154 94C154 97.3137 151.314 100 148 100C144.686 100 142 97.3137 142 94C142 90.6863 144.686 88 148 88Z"
      fill="white"
      stroke="#8362F3"
      strokeWidth="2.5"
    />
    <path
      d="M89.6619 79.3112C88.7539 80.8431 87.967 82.4329 87.3115 84.0722"
      stroke="#DDC8FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M108.022 63.0732C101.469 66.2006 95.9373 70.6244 91.9395 75.9203"
      stroke="#DDC8FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);
