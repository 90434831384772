import * as React from 'react';
import { SVGProps } from 'react';

export const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M11.8996 8.16676V8.77343C11.8996 9.62284 11.8996 10.0476 11.7216 10.372C11.565 10.6574 11.3151 10.8894 11.0078 11.0348C10.6584 11.2001 10.201 11.2001 9.28628 11.2001H4.71294C3.79819 11.2001 3.34081 11.2001 2.99142 11.0348C2.68409 10.8894 2.43423 10.6574 2.27763 10.372C2.09961 10.0476 2.09961 9.62284 2.09961 8.77343V8.16676M9.72183 5.63899L6.99961 8.16676M6.99961 8.16676L4.27739 5.63899M6.99961 8.16676V2.1001"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
