import { useTranslation } from 'react-i18next';
import { StarIcon } from '../Icons/StarIcon';
import style from './BonusCount.module.scss';
import { BonusCountProps } from './BonusCount.props';
import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const BonusCount = ({
  type = 'default',
  count,
  children,
  size = 's',
  isTooltip = true,
}: BonusCountProps) => {
  const { t } = useTranslation();
  const formattedCount = count.toLocaleString('ru-RU');

  const [isTooltipAbove, setIsTooltipAbove] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePosition = () => {
      if (tooltipRef.current) {
        const rect = tooltipRef.current.getBoundingClientRect();
        const tooltipWidth = 205;
        const arrowWidth = 6;
        const tooltipRightEdge = rect.right + tooltipWidth + arrowWidth;
        const viewportWidth = window.innerWidth;

        if (tooltipRightEdge > viewportWidth) {
          setIsTooltipAbove(true);
        } else {
          setIsTooltipAbove(false);
        }
      }
    };

    handlePosition();
    window.addEventListener('resize', handlePosition);
    return () => {
      window.removeEventListener('resize', handlePosition);
    };
  }, []);

  return (
    <div
      className={clsx(
        style[type],
        style[size],
        style.tooltip,
        isTooltip && style.tooltipActive,
        isTooltipAbove && style.tooltipAbove
      )}
      ref={tooltipRef}
      onClick={(event) => event.stopPropagation()}
    >
      <StarIcon className={style[`icon-${size}`]} />

      {formattedCount}

      {isTooltip && (
        <span className={style['tooltip-bonus']}>
          {children ? (
            children
          ) : (
            <span>
              {t('We will add points for ordering a solution')}{' '}
              <Link className={style.link} to={'/programma-rosta'}>
                {t('in the Growth Program')}.
              </Link>{' '}
              {t('You will be able to write it off in 6 months')}
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default BonusCount;
