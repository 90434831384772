import ColorTag, { ColorTagProps } from 'components/shared/ColorTag';
import classes from './MainBlock.module.scss';
import { formatHtmlText } from 'tools/formatHtmlText';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import IconWithDescription from 'components/shared/IconWithDescription';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import { Button } from 'components/shared/Button';
import { useIsVisible } from 'hooks/useIsVisible';
import { useRef } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { observer } from 'mobx-react-lite';

interface MainBlockProps {
  imgSrc?: string;
  tags?: Omit<ColorTagProps, 'className'>[];
  title: string;
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  buttonName: string;
  description: string;
  showFloatButton?: boolean;
  onShowMap?: () => void;
  onSubscribeEvent?: () => void;
  disabledEventButton?: boolean;
  isLoadingSubscribe?: boolean;
}

const MainBlock = (props: MainBlockProps) => {
  const mainButtonRef = useRef<HTMLButtonElement | null>(null);
  const isVisibleMainButton = useIsVisible(mainButtonRef);
  const { width } = useWindowWidth();

  return (
    <div className={classes.mainBlock}>
      {props.imgSrc && (
        <div className={classes.mainBlock__imgBox}>
          <img src={props.imgSrc} alt="" />
        </div>
      )}
      <div className={classes.mainBlock__header}>
        <div className={classes['mainBlock__header-tags']}>
          {props.tags?.map((tag) => (
            <ColorTag
              key={_.uniqueId()}
              color={tag.color}
              text={tag.text}
              icon={tag.icon}
            />
          ))}
        </div>
        <span className={classes['mainBlock__header-title']}>
          {props.title}
        </span>
      </div>
      <div className={classes.mainBlock__info}>
        <IconWithDescription
          icon={<PurpleTimeIcon />}
          title={'Когда'}
          description={props.eventDate}
        />
        {props.eventLocation && (
          <div>
            <IconWithDescription
              icon={<PinIcon />}
              title="Где"
              description={props.eventLocation}
            />
            <Button
              theme="text"
              className={classes['mainBlock__info-textButton']}
              onClick={props.onShowMap}
            >
              Карта и схема проезда
            </Button>
          </div>
        )}
        {props.eventLink && (
          <Link
            to={props.eventLink}
            className={classes['mainBlock__info-link']}
          >
            <LinkIcon />
            {'Подключиться'}
          </Link>
        )}
        {props.disabledEventButton ? (
          <Button
            theme="primary-light"
            className={clsx(
              classes['mainBlock__info-mainButton'],
              classes.disabled
            )}
          >
            Участвую
            <CheckIcon />
          </Button>
        ) : (
          <Button
            ref={mainButtonRef}
            theme="primary"
            className={classes['mainBlock__info-mainButton']}
            onClick={props.onSubscribeEvent}
            disabled={props.isLoadingSubscribe}
          >
            Буду участвовать
          </Button>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={formatHtmlText(
          DOMPurify.sanitize(props.description)
        )}
        className={classes.mainBlock__description}
      />

      {width < 600 &&
        !isVisibleMainButton &&
        !props.disabledEventButton &&
        props.showFloatButton && (
          <Button
            theme="primary"
            className={clsx(
              classes['mainBlock__info-mainButton'],
              classes.float
            )}
            onClick={props.onSubscribeEvent}
            disabled={props.isLoadingSubscribe}
          >
            Буду участвовать
          </Button>
        )}
    </div>
  );
};

export default observer(MainBlock);
