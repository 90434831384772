import React, { forwardRef } from 'react';
import classes from './Button.module.scss';
import clsx from 'clsx';
import { ButtonProps } from './ButtonProps.props';
import { RUSSIAN_LOCALE } from 'utils';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className: additionalClassName,
      size,
      theme,
      children,
      onClick,
      disabled,
      label,
      type,
    },
    ref
  ) => {
    const className = clsx(
      classes.btn,
      theme && classes[theme],
      size && classes[size],
      RUSSIAN_LOCALE && classes.ru,
      additionalClassName
    );

    return (
      <button
        ref={ref}
        className={className}
        disabled={disabled}
        onClick={onClick}
        type={type}
        aria-label={label}
      >
        {children}
      </button>
    );
  }
);
