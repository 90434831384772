import clsx from 'clsx';
import { User } from 'components/common/User';
import { BasketGradientIcon } from 'components/shared/Icons/BasketGradientIcon';
import { HeartGradienIcon } from 'components/shared/Icons/HeartGradienIcon';
import { MarketplaceIcon } from 'components/shared/Icons/MarketplaceIcon';
import { ProjectIcon } from 'components/shared/Icons/ProjectIcon';
import { routers } from 'constants/routers';
import { useCartStore } from 'contexts/CartContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from '../Header.module.scss';
import { HeaderButtonsProps } from './HeaderButtons.props';
import { authStore } from '../../../../stores/authStore';

const HeaderButtons = observer(
  ({
    bottomMenuSize,
    isActiveLink,
    isSuccessLocalPreferences,
    isDisableHeader,
    path,
    userName,
    allowLogout,
  }: HeaderButtonsProps) => {
    const { t } = useTranslation();
    const { width } = useWindowWidth();
    const marketPlaceStore = useMarketPlaceStore();
    const cartStore = useCartStore();
    const userStore = useUserStore();

    const favoriteCount = toJS(marketPlaceStore).favoriteCount;
    const countCart = cartStore.cart ? cartStore.cart.services_count : 0;
    const deals = cartStore.deals || [];

    const menuItems = [
      {
        to: routers.solutions,
        icon: <MarketplaceIcon />,
        text: t('Solutions'),
        counter: null,
        extraClasses: classes.linkWrap,
        show: true,
      },
      {
        to: routers.favorites,
        icon: <HeartGradienIcon />,
        text: t('Favorites'),
        counter:
          favoriteCount !== undefined && favoriteCount !== 0 ? (
            <div className={classes['header__counter-count']}>
              <span>{favoriteCount}</span>
            </div>
          ) : null,
        show: true,
      },
      {
        to: routers.cart,
        icon: <BasketGradientIcon />,
        text: t('Cart'),
        counter:
          countCart !== 0 && countCart !== undefined ? (
            <div className={classes['header__counter-count']}>
              <span>{countCart}</span>
            </div>
          ) : null,
        show: true,
      },
      {
        to: routers.projects,
        icon: <ProjectIcon />,
        text: t('Projects'),
        counter:
          deals?.length > 0 ? (
            <div className={classes['header__counter-count']}>
              <span>{deals.length}</span>
            </div>
          ) : null,
        show: width > 1024, // Show only if the width is greater than 1024
      },
    ];

    return (
      <div
        style={{
          zIndex: 102,
          height: bottomMenuSize,
        }}
        className={clsx(classes['header__bottom-menu'], classes['bottom-menu'])}
      >
        <ul className={clsx(classes['bottom-menu__list'])}>
          {menuItems
            .filter((item) => item.show) // Filtering items based on the "show" condition
            .map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.to}
                  className={(navData) =>
                    clsx(
                      classes['bottom-menu__item'],
                      item.extraClasses,
                      navData.isActive && classes.active,
                      navData.isActive && RUSSIAN_LOCALE && classes.active_ru,
                      isDisableHeader && classes.disabled
                    )
                  }
                >
                  <div className={classes['header__counter']}>
                    {item.icon}
                    {item.counter}
                  </div>
                  <div className={classes['bottom-menu__item-text']}>
                    {item.text}
                  </div>
                </NavLink>
              </li>
            ))}
          <li
            className={clsx(
              isDisableHeader && !allowLogout && classes.disabled,
              authStore.isServerError && classes.disabled
            )}
          >
            <NavLink
              to={path}
              className={clsx(
                classes['bottom-menu__item'],
                classes['bottom-menu__item--user'],
                isActiveLink && classes.active,
                (!isSuccessLocalPreferences && !allowLogout) ||
                  (!userStore?.user?.email_confirmed && classes.disabled)
              )}
            >
              <User name={userName} />
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
);

export default HeaderButtons;
