import { DEAL_STAGES } from 'constants/enums';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useMemo, useState } from 'react';
import { userStore } from 'stores/userStore';
import { StageType } from './DealStage.props';
import { DealStage, ProjectProps } from 'types/project';

export const useDealStage = (project: ProjectProps) => {
  const { isMediaTablet } = useWindowWidth();
  const { CANCELLED, PAUSED } = DEAL_STAGES;
  const projectAllStage = project?.deal_stages;
  const systemStages = project?.all_stages;
  const pausedStage = projectAllStage.find(
    (stage: DealStage) => stage?.type?.toLowerCase() === PAUSED
  );
  const cancelledStage = projectAllStage.find(
    (stage: DealStage) => stage?.type?.toLowerCase() === CANCELLED
  );
  const [internalStageList, setInternalStageList] = useState<DealStage[]>([]);
  const userLocale = userStore.user?.language?.lang_code;
  const isInnerDataLoading = userStore?.isInnerDataLoading;

  const stageArray = useMemo(
    () =>
      systemStages
        ?.map((stage?: DealStage) => {
          if (
            stage?.type?.toLowerCase() === CANCELLED.toLowerCase() ||
            stage?.type?.toLowerCase() === PAUSED.toLowerCase()
          ) {
            return null;
          } else {
            return {
              name: stage?.name,
              type: stage?.type,
            };
          }
        })
        .filter((stage): stage is StageType => stage !== null), // Filter out null values
    [CANCELLED, PAUSED, systemStages]
  );

  const orderStages = () => {
    const orderedStages = stageArray?.map((stage: DealStage) => {
      const findStage = projectAllStage.find(
        (projectStage: DealStage) =>
          stage?.type?.toLowerCase() === projectStage?.type?.toLowerCase()
      );
      if (findStage) {
        return { ...findStage };
      } else {
        return { ...stage, id: undefined };
      }
    });

    let lastIndex = -1;
    for (let i = 0; i < orderedStages?.length; i++) {
      if (orderedStages[i]?.id !== undefined) {
        lastIndex = i;
      }
    }

    // Вставляем стадии "Paused" и "Cancelled" на соответствующие позиции
    if (pausedStage) {
      orderedStages.splice(lastIndex + 1, 0, pausedStage);
    }

    if (cancelledStage) {
      const index = pausedStage ? 2 : 1;
      orderedStages.splice(lastIndex + index, 0, cancelledStage);
    }

    return orderedStages?.slice(0, isMediaTablet ? 5 : 6);
  };

  useEffect(() => {
    setInternalStageList(orderStages());
  }, [pausedStage, cancelledStage, userLocale, projectAllStage]);

  const formatDate = (dateString: string) => {
    if (dateString) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      };
      const date: Date = new Date(dateString);
      return date?.toLocaleDateString('en-US', options);
    }
  };

  return {
    PAUSED,
    CANCELLED,
    formatDate,
    isMediaTablet,
    internalStageList,
    isInnerDataLoading,
  };
};
