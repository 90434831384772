import { Button } from 'components/shared/Button';
import classes from './RenderCardsList.module.scss';

interface RenderCardsListProps {
  id: string;
  title: string;
  list: unknown[];
  render: (item: unknown, index: number) => React.ReactNode;
}

const RenderCardsList = ({ id, title, list, render }: RenderCardsListProps) => (
  <div id={id} className={classes.wrapper}>
    <div className={classes.header}>
      <span className={classes.header__title}>{title}</span>
      <Button theme="text" className={classes.header__more}>
        Смотреть все
      </Button>{' '}
    </div>
    <ul className={classes.list}>{list.map(render)}</ul>
  </div>
);

export default RenderCardsList;
