import * as React from 'react';
import { SVGProps } from 'react';

export const RocketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20 17.2336C20 14.6262 18.5136 12.3738 16.3636 11.3248V7.45093C16.3636 7.09813 16.2432 6.75701 16.0205 6.48832L12.5568 2.26636C12.4114 2.08879 12.2045 2 12 2C11.7955 2 11.5886 2.08879 11.4432 2.26636L7.97955 6.48832C7.75833 6.75756 7.63678 7.0985 7.63636 7.45093V11.3248C5.48636 12.3738 4 14.6262 4 17.2336H7.55682C7.50455 17.4019 7.47727 17.5841 7.47727 17.7897C7.47727 18.3061 7.65 18.8107 7.96364 19.2103C8.21964 19.537 8.55869 19.7845 8.94318 19.9252C9.46818 21.1869 10.6614 22 12 22C12.6614 22 13.3023 21.7991 13.85 21.4206C14.3864 21.0514 14.8023 20.535 15.0545 19.9252C15.4389 19.7854 15.778 19.5387 16.0341 19.2126C16.3481 18.8095 16.5196 18.3086 16.5205 17.7921C16.5205 17.5958 16.4955 17.4089 16.45 17.236H20V17.2336ZM17.6886 14.5561C17.9023 14.8972 18.075 15.264 18.2 15.6449H16.2727V13.0911C16.8397 13.4715 17.3222 13.9707 17.6886 14.5561ZM9.18182 11.3248V7.48598L12 4.0514L14.8182 7.48598V15.6449H9.18182V11.3248ZM5.8 15.6449C5.925 15.264 6.09773 14.8972 6.31136 14.5561C6.68182 13.9673 7.16364 13.4696 7.72727 13.0911V15.6449H5.8ZM14.6386 18.3598C14.5205 18.4299 14.3841 18.4579 14.25 18.4393L13.8068 18.3832L13.7432 18.8364C13.6205 19.722 12.8705 20.3902 12 20.3902C11.1295 20.3902 10.3795 19.722 10.2568 18.8364L10.1932 18.3808L9.75 18.4393C9.61527 18.4558 9.47895 18.4271 9.36136 18.3575C9.16364 18.2407 9.04091 18.0234 9.04091 17.7874C9.04091 17.5397 9.175 17.3341 9.37273 17.2313H14.6295C14.8295 17.3364 14.9614 17.5421 14.9614 17.7874C14.9591 18.0257 14.8364 18.2453 14.6386 18.3598ZM10.9091 9.38318C10.9091 9.68062 11.024 9.96587 11.2286 10.1762C11.4332 10.3865 11.7107 10.5047 12 10.5047C12.2893 10.5047 12.5668 10.3865 12.7714 10.1762C12.976 9.96587 13.0909 9.68062 13.0909 9.38318C13.0909 9.08574 12.976 8.80048 12.7714 8.59016C12.5668 8.37984 12.2893 8.26168 12 8.26168C11.7107 8.26168 11.4332 8.37984 11.2286 8.59016C11.024 8.80048 10.9091 9.08574 10.9091 9.38318Z" />
  </svg>
);
