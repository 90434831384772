import * as React from 'react';
import { SVGProps } from 'react';

export const KrestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.385.166h1.234c.107 0 .17.093.105.16L7.34 5.999l5.384 5.674c.064.067.002.16-.105.16h-1.234a.296.296 0 0 1-.212-.083L6.5 6.827 1.828 11.75a.296.296 0 0 1-.213.083H.382c-.107 0-.17-.093-.107-.16l5.384-5.674L.275.326c-.063-.067 0-.16.107-.16h1.233c.084 0 .163.031.213.083L6.5 5.172 11.173.25a.296.296 0 0 1 .212-.083Z"
      fill="#F5222D"
    />
  </svg>
);
