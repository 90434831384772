import { createContext, ReactNode, useContext } from 'react';
import { marketPlaceStore } from 'stores/marketPlaceStore';

const MarketPlaceContext = createContext<typeof marketPlaceStore | null>(null);

export const MarketPlaceProvider = ({ children }: { children: ReactNode }) => (
  <MarketPlaceContext.Provider value={marketPlaceStore}>
    {children}
  </MarketPlaceContext.Provider>
);

export const useMarketPlaceStore = () => {
  const context = useContext(MarketPlaceContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with MarketPlaceProvider'
    );
  }
  return context;
};
