import * as React from 'react';
import { SVGProps } from 'react';

export const USDIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="usd">
      <path
        id="$"
        d="M11.1197 20V18.0529C8.98944 17.7481 7.44014 16.4952 7 14.7005L9.04225 13.9894C9.35915 15.4116 10.4683 16.1735 12.2113 16.1735C13.8838 16.1735 14.8521 15.581 14.8521 14.5312C14.8521 13.7862 14.3415 13.363 13.1796 13.0921L10.7852 12.5672C8.8662 12.1439 7.73944 10.9757 7.73944 9.38413C7.73944 7.65714 9.09507 6.35344 11.1197 5.98095V4H13.1444V5.93016C15.1162 6.18413 16.4542 7.30159 16.8592 8.96085L14.8345 9.65503C14.5704 8.48677 13.6373 7.82646 12.2113 7.82646C10.7676 7.82646 9.88732 8.41905 9.88732 9.40106C9.88732 10.0952 10.3451 10.5185 11.331 10.7386L13.743 11.2804C15.8556 11.7376 17 12.855 17 14.4974C17 16.3767 15.4683 17.7481 13.1444 18.0529V20H11.1197Z"
        fill="#9C93AA"
      />
    </g>
  </svg>
);
