import clsx from 'clsx';
import { Grid, GridItem } from 'components/common/Grid/index';
import { Button } from 'components/shared/Button';
import { CookieIcon } from 'components/shared/Icons/CookieIcon';
import { Type } from 'components/shared/Type/index';
import { defaultLanguage } from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './CookieNotice.module.scss';
import { useCookieNotice } from './useCookieNotice';

export const CookieNotice = () => {
  const { t, i18n, handleAcceptCookies, handleDeclineCookies, isLoading } =
    useCookieNotice();

  return (
    <div className={clsx(classes['cookie-notice'])}>
      <div className={clsx(classes['cookie-notice__container'])}>
        <div className={clsx(classes['cookie-notice__head'])}>
          <div className={clsx(classes['cookie-notice__icon'])}>
            <CookieIcon />
          </div>
          <Type tag="h1" size="md" className={classes['cookie-notice__title']}>
            {t('Cookie Notice')}
          </Type>
        </div>

        <Grid direction="row">
          <GridItem col={{ default: 12, sm: 7, md: 8 }}>
            <div className={classes['cookie-notice__text']}>
              {t(
                'We use cookies to analyse and improve your experience with KIT Global website and platform and to provide our services at their best. To learn more about how and for what purposes KIT Global uses personal information, please read our'
              )}{' '}
              <a
                className={classes['cookie-notice__link']}
                href={
                  RUSSIAN_LOCALE
                    ? process.env.REACT_APP_PRIVACY_POLICY_URL || ''
                    : `https://kit.global/${
                        i18n.language !== defaultLanguage
                          ? `${i18n.language}/`
                          : ''
                      }privacy-policy/`
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('Privacy Policy')}
              </a>
              {'. '}
              {t('For more information about Cookies please see our')}{' '}
              <a
                className={classes['cookie-notice__link']}
                href={
                  RUSSIAN_LOCALE
                    ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ''
                    : `https://kit.global/${
                        i18n.language !== defaultLanguage
                          ? `${i18n.language}/`
                          : ''
                      }cookie-policy/`
                }
                target="_blank"
                rel="noreferrer"
              >
                {t('Cookie Policy')}
              </a>
              {'. '}
              {t('Are you happy to allow cookies?')}
            </div>
          </GridItem>

          <GridItem
            col={{ default: 12, sm: 5, md: 4 }}
            className={classes['cookie-notice__block-btn']}
          >
            <Button
              className={classes['cookie-notice__btn']}
              theme="primary-light"
              size="middle"
              onClick={handleDeclineCookies}
              disabled={isLoading}
            >
              {t('Accept necessary')}
            </Button>

            <Button
              className={classes['cookie-notice__btn']}
              theme="primary"
              size="middle"
              onClick={handleAcceptCookies}
              disabled={isLoading}
            >
              {t('Accept all')}
            </Button>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};
