import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import 'styles/main.scss';
import { NeedConfirmPageContainer } from 'containers/NeedConfirmPageContainer';

export const NeedConfirm = () => (
  <div className={'layout-container--inside'}>
    <TitleAndDescription pageName="needconfirm" />

    <div className="layout-container-inner">
      <NeedConfirmPageContainer />
    </div>
  </div>
);
