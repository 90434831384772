import * as React from 'react';
import { SVGProps } from 'react';

export const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path d="m4.907 4.786.837.837a.29.29 0 0 0 .199.09l5.559-.063.848.848-.063 5.559c0 .067.032.141.089.198l.837.838c.073.072.177.053.178-.033l.091-7.965c.003-.288-.29-.58-.577-.577L4.94 4.61c-.087 0-.106.104-.033.177Z" />
    <path d="m11.502 5.65-6.897 6.896a.3.3 0 0 0 0 .424l.425.424a.3.3 0 0 0 .424 0l6.896-6.896-.848-.848Z" />
  </svg>
);
