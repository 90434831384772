import classes from './ClientLevel.module.scss';
import { CLIENT_LEVEL } from 'constants/enums';
import clsx from 'clsx';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { useLayoutEffect, useRef } from 'react';
import clamp from 'clamp-js';
import { Link } from 'react-router-dom';

interface ClientLevelProps {
  title: string;
  level: CLIENT_LEVEL;
  description: string;
  linkName: string;
}

const ClientLevel = ({
  title,
  level,
  description,
  linkName,
}: ClientLevelProps) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      clamp(textRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <div className={clsx(classes.clientLevel, classes[level])}>
      <div className={classes.clientLevel__header}>
        <span className={classes.clientLevel__title}>{title}</span>
        <span className={classes.clientLevel__name}>{level}</span>
        <p ref={textRef} className={classes.clientLevel__description}>
          {description}
        </p>
      </div>
      <Link
        to={`/programma-rosta/rules#kak-rabotaet`}
        className={classes.clientLevel__link}
      >
        {linkName}
        <LeftArrowIcon />
      </Link>
    </div>
  );
};

export default ClientLevel;
