import { createContext, ReactNode, useContext } from 'react';
import { authStore } from 'stores/authStore';

const AuthContext = createContext<typeof authStore | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => (
  <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
);

export const useAuthStore = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with AuthProvider'
    );
  }
  return context;
};
