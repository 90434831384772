import { StarIcon } from 'components/shared/Icons/StarIcon';
import classes from './AmountCell.module.scss';
import clsx from 'clsx';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';

interface AmountCellProps {
  type: 'INCOME' | 'OUTCOME';
  value: number;
  className?: string;
}

const AmountCell = ({ type, value, className }: AmountCellProps) => (
  <div
    className={clsx(
      classes.amount,
      type === 'INCOME' ? classes.green : classes.red,
      className
    )}
  >
    <span className={classes.amount__symbol}>
      {type === 'INCOME' ? '+' : '-'}
    </span>
    <StarIcon />
    <FormatPrice currency="" price={value} />
  </div>
);

export default AmountCell;
