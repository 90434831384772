import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import GrowthProgramService from 'services/growth-program';
import { ShowNotification } from 'tools/showNotification';

export class GrowthProgramStore {
  currentEventSubscribed = false;
  isLoadingSubscribe = false;
  isLoadingSubscribeStatus = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingSubscribe(status: boolean) {
    this.isLoadingSubscribe = status;
  }

  setLoadingSubscribeStatus(status: boolean) {
    this.isLoadingSubscribeStatus = status;
  }

  public async subscribeEvent(eventId: number) {
    this.setLoadingSubscribe(true);

    try {
      await GrowthProgramService.subscribe(eventId);

      runInAction(() => {
        this.currentEventSubscribed = true;
        this.setLoadingSubscribe(false);
        ShowNotification({
          type: 'success_second',
          children:
            'Ваш запрос отправлен менеджеру. Он свяжется с вами в ближайшее время для подтверждения деталей',
          title: 'Запрос принят',
        });
      });
    } catch (err) {
      this.setLoadingSubscribe(false);
      if (err instanceof AxiosError) {
        ShowNotification({ type: 'error', children: err.message });
      }
    }
  }

  public getSubscribeStatus = async (eventId: number) => {
    this.setLoadingSubscribeStatus(true);

    try {
      const response = await GrowthProgramService.getSubscribe(eventId);

      runInAction(() => {
        if (response.data?.event_id) {
          this.currentEventSubscribed = true;
        }
        this.setLoadingSubscribeStatus(false);
      });
    } catch (err) {
      runInAction(() => {
        this.currentEventSubscribed = false;
        this.setLoadingSubscribeStatus(false);
      });
    }
  };

  clearStore() {
    runInAction(() => {
      this.currentEventSubscribed = false;
      this.isLoadingSubscribe = false;
      this.isLoadingSubscribeStatus = false;
    });
  }
}

export const growthStore = new GrowthProgramStore();
