import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const FilledHeartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      d="M15 27L13.26 25.4894C7.08 20.145 3 16.6087 3 12.2943C3 8.75804 5.904 6 9.6 6C11.688 6 13.692 6.92698 15 8.38038C16.308 6.92698 18.312 6 20.4 6C24.096 6 27 8.75804 27 12.2943C27 16.6087 22.92 20.145 16.74 25.4894L15 27Z"
      fill={RUSSIAN_LOCALE ? '#EA4A4A' : '#EA4A70'}
    />
  </svg>
);
