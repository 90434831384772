import React, { FC, useState, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import clsx from 'clsx';
import classes from './DashboardContainer.module.scss';
import Select from '../../components/shared/SelectComponent';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import emptyDashboard from 'assets/img/Dashboard/EmptyDashboard.svg';
import emptyDashboard_ru from 'assets/img/Dashboard/EmptyDashboard_ru.svg';
import { TitleAndDescription } from '../../components/shared/TitleAndDescription/TitleAndDescription';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { userStore } from 'stores/userStore';
import { GetDashboardParams } from 'services/api/getDashboardParams';
import { GetDashboardProjects } from 'services/api/getDashboardProjects';
import { RUSSIAN_LOCALE } from 'utils';
import { useUserStore } from 'contexts/UserContext';

interface DashboardContainerProps {
  title: string;
}

export const DashboardContainer: FC<DashboardContainerProps> = ({ title }) => {
  const { t } = useTranslation();
  const { user } = userStore || {};

  const [dashboardUserProjects, setDashboardUserProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [openSelect, setOpenSelect] = useState(false);
  const { isMediaTablet } = useWindowWidth();
  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  useEffect(() => {
    setSelectedProject(dashboardUserProjects?.[0]);
  }, [dashboardUserProjects]);

  useEffect(() => {
    const getDashboardProjects = async () => {
      try {
        const response = await GetDashboardProjects();
        return response;
      } catch (error) {
        console.log(error, 'Get DashboardParams Error');
      }
    };
    const response = getDashboardProjects();
    const json = response.then((projects) => {
      console.log(projects.results, 'Dashboard projects');
      setDashboardUserProjects(projects.results);
    });
  }, []);

  const getDashBoardParams = async (projectID: string, workspaceID: string) => {
    try {
      const response = await GetDashboardParams({
        access: cookies.access,
        refresh: cookies.refresh,
        projectID: projectID,
        workspaceId: workspaceID,
      });
      return response;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
    }
  };

  const getGuestToken = async (projectID: string, workspaceID: string) => {
    try {
      const response = await GetDashboardParams({
        access: cookies.access,
        refresh: cookies.refresh,
        projectID: projectID,
        workspaceId: workspaceID,
      });
      return response?.guest_token;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
    }
  };

  const createImprovadoDashboard = async (selectedProject: any) => {
    const currentUserRoles = JSON.parse(JSON.stringify(user?.roles));
    const currentUserEmail = JSON.parse(JSON.stringify(user?.email));

    if (selectedProject?.improvado_workspace_id) {
      const projectID = selectedProject?.id;
      const workspaceID = selectedProject?.improvado_workspace_id;
      const dashboardParams = await getDashBoardParams(projectID, workspaceID);
      const agencyUID = dashboardParams?.current_agency_uuid;
      const dashboardID = dashboardParams?.dashboard_id;
      const workspaceSchemaName = dashboardParams?.workspace_schema_name;
      const dashboardHTMLElement = document.getElementById(
        'improvado_dashboard'
      )!;

      embedDashboard({
        id: dashboardID,
        supersetDomain: 'https://superset-embedded.tools.improvado.io',
        mountPoint: dashboardHTMLElement,
        fetchGuestToken: () => getGuestToken(projectID, workspaceID),
        dashboardUiConfig: {
          urlParams: {
            current_agency_uuid: agencyUID,
            current_workspace_ids: [selectedProject?.improvado_workspace_id],
            workspace_schema_name: workspaceSchemaName,
          },
        },
      });
      const embededDashboardElement = document.querySelector<HTMLElement>(
        "[title='Embedded Dashboard']"
      );
      if (embededDashboardElement) {
        embededDashboardElement.style.width = '100%';
        embededDashboardElement.style.height = '500px';
        embededDashboardElement.setAttribute('frameBorder', '0');
      }
    }
  };

  useEffect(() => {
    createImprovadoDashboard(selectedProject);
  }, [selectedProject]);

  const emptyPage = (
    <div className={clsx(classes.emptyPage, 'empty-background')}>
      <div className={classes.emptyPage__icon}>
        <img
          src={RUSSIAN_LOCALE ? emptyDashboard_ru : emptyDashboard}
          alt={t('This information is only available on the desktop version')}
          className={classes.emptyPage__image}
        />
      </div>
      <div className={classes.emptyPage__text}>
        {t('This information is only available on the desktop version')}
      </div>
    </div>
  );
  return (
    <>
      {isMediaTablet && <div className={classes.background} />}

      <div className={clsx(classes.wrapper)}>
        <TitleAndDescription pageName="Dashboard" />
        <div className={classes.title}>{title}</div>
        {!isMediaTablet ? (
          <div className={classes.selectContainer}>
            <Select
              select={selectedProject}
              setSelect={setSelectedProject}
              options={dashboardUserProjects}
              open={openSelect}
              setOpen={setOpenSelect}
              title={t('Projects')}
              boxClassName={classes.selectBox}
              wrapperClassName={classes.selectWrapper}
            />
          </div>
        ) : (
          emptyPage
        )}
      </div>
      {!isMediaTablet && (
        <div
          id="improvado_dashboard"
          className={classes.improvadoDashboard}
        ></div>
      )}
    </>
  );
};
