import { AxiosResponse } from 'axios';
import api from './http';

interface createBasketInterface {
  serviceId: number[];
}

interface addOrRemoveBasketInterface {
  serviceId: number;
  count: number;
  sourcePage: string;
  select: boolean;
}

interface deleteServicesInterface {
  serviceProjectId: number[];
}

interface updateServicesInterface {
  serviceId: number[];
  select: boolean;
}

interface updateSelectedServicesInterface {
  serviceIds?: number[];
  serviceId?: number;
  select: boolean;
}

interface createDealInterface {
  serviceSourceIds: number[];
  clientId: number;
}

interface AddToSelectedService {
  service_ids?: number[];
  service_id?: number;
  select: boolean;
}

export default class BasketService {
  static async getBasket(): Promise<AxiosResponse> {
    return api.get('/api/v1/basket/');
  }

  static async createBasket({
    serviceId,
  }: createBasketInterface): Promise<AxiosResponse> {
    const data: {
      service_id: number[];
    } = {
      service_id: serviceId,
    };

    return api.post('/api/v1/basket/', data);
  }

  static async addToBasket({
    serviceId,
    count = 1,
    sourcePage = '',
    select = true,
  }: addOrRemoveBasketInterface): Promise<AxiosResponse> {
    const data: {
      service_id: number;
      count: number;
      source_page: string;
      select: boolean;
    } = {
      service_id: serviceId,
      count,
      source_page: sourcePage,
      select: select,
    };

    return api.put(`/api/v1/basket/update/`, data);
  }

  static async removeFromBasket({
    serviceId,
    count = 1,
    sourcePage = '',
    select = false,
  }: addOrRemoveBasketInterface): Promise<AxiosResponse> {
    const data: {
      service_id: number;
      count: number;
      source_page: string;
      select: boolean;
    } = {
      service_id: serviceId,
      count,
      source_page: sourcePage,
      select: select,
    };

    return api.put(`/api/v1/basket/remove/`, data);
  }

  static async deleteServices({
    serviceProjectId,
  }: deleteServicesInterface): Promise<AxiosResponse> {
    const data: {
      service_project_id: number[];
    } = {
      service_project_id: serviceProjectId,
    };
    return api.put('/api/v1/basket/remove/', data);
  }

  static async updateServices({
    serviceId,
  }: updateServicesInterface): Promise<AxiosResponse> {
    const data: {
      service_id: number[];
    } = {
      service_id: serviceId,
    };
    return api.put('/api/v1/basket/update/', data);
  }

  static async addToSelectedService({
    serviceIds,
    serviceId,
    select = true,
  }: updateSelectedServicesInterface): Promise<AxiosResponse> {
    const data: AddToSelectedService = { select }; // всегда включаем select, так как он обязателен

    if (serviceIds) {
      data.service_ids = serviceIds;
    }
    if (serviceId) {
      data.service_id = serviceId;
    }

    return api.put(`/api/v1/basket/update_selected/`, data);
  }

  static async createDeal({
    serviceSourceIds,
    clientId,
  }: createDealInterface): Promise<AxiosResponse> {
    return api.put('/api/v1/basket/convert_to_deal/', {
      client_id: clientId,
      service_project_id: serviceSourceIds,
    });
  }
}
