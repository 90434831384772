import { toast, ToastOptions } from 'react-toastify';
import { Toast } from 'components/shared/Toast';
import { cartStore } from 'stores/cartStore';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: 'success' | 'success_second' | 'warning' | 'error';
  children?: ReactNode;
  toastProps?: ToastOptions;
  title?: string;
}

export function ShowNotification({ type, children, toastProps, title }: Props) {
  const { t } = useTranslation();
  const cartButtonSize = cartStore.fixedButtonSize;
  const position = window.innerWidth < 768 ? 'top-center' : 'top-right';
  const errorMessage =
    (type === 'error' && t('Unknown error. Please, try again later')) || '';

  return toast(
    <Toast title={title} type={type}>
      {String(children).length ? String(children) : (errorMessage as string)}
    </Toast>,
    {
      ...toastProps,
      position,
      className:
        cartButtonSize !== 120 ? 'toast-container' : 'toast-container-big',
    }
  );
}
