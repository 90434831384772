import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from 'contexts/AuthContext';
import { observer } from 'mobx-react-lite';
import { CookieNotice } from './CookieNotice';
import { useCookies } from 'react-cookie';
import { ErrorPageContainer } from './ErrorPageContainer';
import { useTranslation } from 'react-i18next';

export const RedirectAuth = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const { isServerError, isAuth, setServerError } = authStore || {};
  const [cookies] = useCookies(['cookie_consent']);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuth) {
      navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, { replace: true });
      setServerError(false);
    }
  }, [isAuth]);

  return (
    <>
      {isServerError && !isAuth ? (
        <ErrorPageContainer title={t('Error 500')} isNotAuth />
      ) : (
        <Outlet />
      )}

      {!isServerError && !cookies.cookie_consent && <CookieNotice />}
    </>
  );
});
