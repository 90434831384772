import classes from './Type.module.scss';
import clsx from 'clsx';
import { TypeProps } from './Type.props';

export const Type = ({
  size,
  tag,
  children,
  color,
  className: additionalClassName,
}: TypeProps) => {
  const Tag = tag;

  return (
    <Tag
      className={clsx(size && classes[size], additionalClassName)}
      style={{ color: color }}
    >
      {children}
    </Tag>
  );
};
