import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { cartStore } from 'stores/cartStore';
import { userStore } from 'stores/userStore';
import classes from '../Cart.module.scss';
import totalClasses from '../Total/Total.module.scss';
import { FixedButtonProps } from './FixedButton.props';
import { useCheckCurrency } from 'hooks/useCheckCurrency';

const formattedPrice = (price?: number | string, withMinus?: boolean) => (
  <FormatPrice
    currency={userStore.user.currency?.symbol}
    price={price}
    withMinus={withMinus}
  />
);

const FixedButton = observer(
  ({ total, totalWithDiscounts, onCreateDeal }: FixedButtonProps) => {
    const { t } = useTranslation();
    const selectedItems = cartStore.selectedItems;
    onCreateDeal;

    const checkCurrency = useCheckCurrency();

    return (
      <div className={classes.fixedButton}>
        <span
          className={clsx(
            totalClasses.costBig,
            checkCurrency && totalClasses['costBig--small']
          )}
        >
          {formattedPrice(
            Number(total) !== Number(totalWithDiscounts)
              ? totalWithDiscounts
              : total
          )}
        </span>

        <Button
          theme={'primary'}
          size={'big'}
          className={classes.button}
          disabled={!selectedItems?.length}
          onClick={onCreateDeal}
        >
          <span>{t('Start projects')}</span>
        </Button>
      </div>
    );
  }
);

export default FixedButton;
