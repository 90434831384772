import { useCartStore } from 'contexts/CartContext';
import { useFavorites, useHotDeals } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICartData } from 'types';
import { priceTextContent } from 'components/shared/PriceText';
import { useBreakPoints } from './useBreakPoints';
import { DiscountType, ProductType } from 'types/solutions';
import { LocalizedNames } from 'types/util-types';
import { getLangName } from 'tools/utils';

const getDisplayName = (name: ProductType | string | undefined): string => {
  if (
    typeof name === 'string' ||
    typeof name === 'number' ||
    typeof name === 'boolean'
  ) {
    return String(name);
  }
  if (Array.isArray(name) && name.length > 0) {
    return name[0].name || '';
  }
  if (typeof name === 'object' && name !== null && 'name' in name) {
    return name.name;
  }
  return 'Unknown';
};

export const useCartCard = (item: ICartData) => {
  const { t, i18n } = useTranslation();
  const cartStore = useCartStore();

  const name = getDisplayName(getLangName(item.service, 'name', i18n));
  const total = Number(item.service?.is_free ? '0' : item.total_cost);
  const totalWithDiscounts = Number(item.total_cost_with_discounts);
  const {
    product: { name: productName },
    is_new: isNew,
    slug: slug,
    is_free: free,
    is_favorite: favorite,
    id: id,
    discounts: discounts,
  } = item.service;

  const [isFavorite, setIsFavorite] = useState(favorite);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isDisabledButton, setDisabledButton] = useState(false);

  const { getHotDealText } = useHotDeals(t);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { isTablet, isHovered, setIsHovered } = useBreakPoints();
  const [disabled, setDisabled] = useState(cartStore?.isSelected);

  const data = {
    slug,
    id,
  };

  const priceToText =
    priceTextContent(item.service, t, false, true)
      ?.split('_')
      .join(' ')
      .toLowerCase() || '';

  const changeFavorites = useFavorites(
    Number(data.id),
    isFavorite,
    setIsFavorite
  );

  const deals = discounts.find(
    (item: DiscountType) => item.type === 'HOT_DEAL' && item.is_active === true
  );

  const utcDateDeal = new Date(`${deals?.deal_end_date}`);
  const dayEndDeal = utcDateDeal.getDate();
  const monthEndDeal = utcDateDeal.toLocaleString(i18n.language, {
    month: 'long',
  });
  const yearEndDeal = utcDateDeal.getFullYear();

  useEffect(() => {
    setDisabled(cartStore?.isSelected);
  }, [cartStore?.isSelected]);

  useEffect(() => {
    if (item?.service && deals)
      setHotDealDiscountText(getHotDealText(deals, item.service));
  }, [deals, item]);

  const handleOpenModal = () => {
    setOpenPriceModal(true);
  };

  const handleCloseModal = () => {
    setOpenPriceModal(false);
  };

  const addToCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.addToCart({ serviceId: Number(item.service.id) });
    } finally {
      setDisabledButton(false);
    }
  };

  const removeFromCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.removeFromCart({ serviceId: Number(item.service.id) });
    } finally {
      setDisabledButton(false);
    }
  };

  const deleteFromCart = async () => {
    setDisabledButton(true);
    try {
      await cartStore.removeFromCart({
        serviceId: Number(item.service.id),
        count: item.count,
      });
      setOpenModal(false);
    } finally {
      setDisabledButton(false);
    }
  };

  const totalNoDecimal =
    total < 1 && total > 0 ? Math.ceil(total) : Math.floor(total);
  const discountNoDecimal =
    totalWithDiscounts < 1 && totalWithDiscounts > 0
      ? Math.ceil(totalWithDiscounts)
      : Math.floor(totalWithDiscounts);

  const hasDiscount = Number(discountNoDecimal) !== Number(totalNoDecimal);

  const itemCount = item?.count || 1;
  const priceUnitText =
    itemCount > 1 ? `${priceToText === 'all' ? '' : priceToText}` : priceToText;

  const cardCostWithText =
    Number(totalNoDecimal) && Number(discountNoDecimal)
      ? ` / ${priceUnitText}`
      : ` / ${priceUnitText}`;

  let totalCostNumber = 0;
  let totalCostWithNoDiscountNumber = 0;

  cartStore?.cart?.basket_project_service?.forEach((service: ICartData) => {
    if (service.service.id === id) {
      totalCostWithNoDiscountNumber += Number(service.total_cost);
      totalCostNumber += Number(service.total_cost_with_discounts);
    }
  });

  cartStore.updateCardCost(totalNoDecimal, item.service, discountNoDecimal);

  const handleShowButtons = (toShow: boolean) => {
    if (!isTablet) {
      setIsHovered(toShow);
    }
  };

  return {
    handleShowButtons,
    setDisabled,
    disabled,
    cartStore,
    deals,
    id,
    hotDealDiscountText,
    isNew,
    free,
    name,
    t,
    slug,
    productName,
    yearEndDeal,
    monthEndDeal,
    dayEndDeal,
    changeFavorites,
    isFavorite,
    isHovered,
    isDisabledButton,
    setOpenModal,
    removeFromCart,
    addToCart,
    handleOpenModal,
    total,
    totalCostNumber,
    totalCostWithNoDiscountNumber,
    itemCount,
    priceToText,
    totalNoDecimal,
    discountNoDecimal,
    deleteFromCart,
    openModal,
    hasDiscount,
    cardCostWithText,
    handleCloseModal,
    openPriceModal,
  };
};
