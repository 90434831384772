import { SVGProps } from 'react';

export const ExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M8.73595 0.85908L11.8561 1.05854L11.0308 12.7831L8.06163 12.5933L8.73595 0.85908ZM7.31319 16.7918C7.34505 16.2933 7.55696 15.8768 7.94892 15.5421C8.35765 15.2085 8.83041 15.0589 9.36722 15.0932C9.90402 15.1275 10.3539 15.3361 10.7168 15.719C11.0797 16.102 11.2453 16.5427 11.2134 17.0411C11.1815 17.5396 10.9612 17.9556 10.5525 18.2892C10.1438 18.6228 9.67101 18.7725 9.13421 18.7381C8.61418 18.7049 8.1727 18.4968 7.80978 18.1139C7.44685 17.731 7.28132 17.2903 7.31319 16.7918Z"
      fill="white"
    />
  </svg>
);
