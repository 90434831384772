import classnames from 'classnames';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { Fader } from 'components/shared/Fader';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import {
  HeartBoldFilledIcon,
  HeartBoldIcon,
} from 'components/shared/Icons/HeartIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { MinusIcon } from 'components/shared/Icons/MinusIcon';
import { PlusIcon } from 'components/shared/Icons/PlusIcon';
import { TrashIcon } from 'components/shared/Icons/TrashIcon';
import { WhiteFireIcon } from 'components/shared/Icons/WhiteFireIcon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { HotDealsCounter } from 'containers/CardsContainer/HotDeals/HotDealsCounter';
import { useUserStore } from 'contexts/UserContext';
import { Link } from 'react-router-dom';
import classes from './CartCard.module.scss';
import { CartCardProps } from './CartCard.props';
import CartCardPrice from './CartCardPrice/CartCardPrice';
import { DeleteConfirmationModal } from './ui/modals/DeleteConfirmationModal';
import { PriceInfoModal } from './ui/modals/PriceInfoModal';
import { useCartCard } from './useCartCard';

export const CartCard = ({ hotDeals, item, selectedItems }: CartCardProps) => {
  const userStore = useUserStore();
  const {
    handleShowButtons,
    setDisabled,
    disabled,
    cartStore,
    deals,
    id,
    hotDealDiscountText,
    isNew,
    free,
    name,
    t,
    slug,
    productName,
    yearEndDeal,
    monthEndDeal,
    dayEndDeal,
    changeFavorites,
    isFavorite,
    isHovered,
    isDisabledButton,
    setOpenModal,
    removeFromCart,
    addToCart,
    handleOpenModal,
    total,
    totalCostNumber,
    totalCostWithNoDiscountNumber,
    itemCount,
    priceToText,
    totalNoDecimal,
    discountNoDecimal,
    deleteFromCart,
    openModal,
    hasDiscount,
    cardCostWithText,
    handleCloseModal,
    openPriceModal,
  } = useCartCard(item);

  const currency = userStore?.user?.currency;
  const hideDiscount = !!item?.service?.discounts?.find(
    (item) => item.cost_change_type === 'PRICE_NEW'
  );

  return (
    <div
      className={classes.item}
      onMouseOver={() => handleShowButtons(true)}
      onMouseOut={() => handleShowButtons(false)}
    >
      <div className={classes.left}>
        <Checkbox
          className={classes.checkbox}
          onChange={() => {
            setDisabled(true);
            cartStore.handleSelectedItem(item);
          }}
          checked={selectedItems.some(({ service }) => service.id === id)}
          disabled={disabled}
        />

        <div className={classes.leftBlock}>
          <div className={classes.box}>
            <div className={classes.tags}>
              {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
                <div className={clsx(classes.tag, classes['hit-tag'])}>
                  <div className={classes.hitIcon}>
                    <WhiteFireIcon />
                  </div>
                  <span className={classes.tagInner}>
                    {deals.cost_change_type === 'MONEY' && (
                      <FormatPrice currency={currency?.symbol} withMinus />
                    )}
                    {hotDealDiscountText}
                  </span>
                </div>
              )}

              {isNew && (
                <div className={clsx(classes.tag, classes.new)}>{t('NEW')}</div>
              )}

              {free && (
                <div className={clsx(classes.tag, classes.free)}>
                  {t('FREE')}
                </div>
              )}
              <div className={classes.tag}>{productName}</div>
            </div>

            <Link to={`/solutions/${slug}`} className={classes.link}>
              {name}
            </Link>

            {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
              <div>
                <div className={classes.tooltipWrap}>
                  <span className={classes.closeTimeInner}>
                    {t('Closing time')}:{' '}
                    <span className={classes.closeTime}>
                      {dayEndDeal} {monthEndDeal} {yearEndDeal}{' '}
                    </span>
                  </span>

                  <div className={classes.closeTimeTooltip}>
                    <TooltipContainer
                      text={t(
                        'Closing time is a deadline date for signing an agreement for the service, after this date the discount will be canceled'
                      )}
                      position={'top'}
                      className={classes.tooltipContainer}
                      classNameTooltip={classes.tooltip}
                    >
                      <InfoIcon
                        fill="rgba(137, 137, 137, .6)"
                        width="18px"
                        height="18px"
                      />
                    </TooltipContainer>
                  </div>
                </div>
              </div>
            )}

            {hotDeals.activeDeals && !hotDeals.featureDeals && deals && (
              <div>
                {hotDeals.activeDeals && (
                  <div className={classes.timer}>
                    <HotDealsCounter inner small />{' '}
                  </div>
                )}
              </div>
            )}
          </div>

          <Fader active={isDisabledButton}>
            <div className={classnames(classes.buttons)}>
              {isHovered && (
                <>
                  <Button
                    theme={'text'}
                    className={clsx(
                      classes.favButton,
                      isFavorite && classes.inFav
                    )}
                    onClick={changeFavorites}
                    label={t('Add to favorites')}
                  >
                    {isFavorite ? (
                      <div className={classes.favButtonInner}>
                        <div
                          className={clsx(
                            classes.favIcon,
                            classes.favIconScale
                          )}
                        >
                          <HeartBoldFilledIcon />
                        </div>
                      </div>
                    ) : (
                      <div className={classes.favButtonInner}>
                        <div className={classes.favIcon}>
                          <HeartBoldIcon />
                        </div>
                      </div>
                    )}
                  </Button>

                  <Button
                    theme={'text'}
                    className={classes.delete}
                    label={t('Delete solution')}
                    onClick={() => setOpenModal(true)}
                    disabled={isDisabledButton}
                  >
                    <div className={classes.deleteIcon}>
                      <TrashIcon width="21.6" height="21.6" />
                    </div>
                  </Button>
                </>
              )}
            </div>
          </Fader>
        </div>
      </div>

      <Fader active={isDisabledButton}>
        <div className={classnames(classes.right)}>
          <div className={classes.counter}>
            <button
              className={classes.counter__btn}
              disabled={isDisabledButton}
              onClick={removeFromCart}
            >
              <MinusIcon />
            </button>

            <input
              type="number"
              className={classes.counter__input}
              value={item.count}
              readOnly
            />

            <button
              className={classes.counter__btn}
              disabled={isDisabledButton}
              onClick={addToCart}
            >
              <PlusIcon />
            </button>
          </div>

          <CartCardPrice
            discountNoDecimal={discountNoDecimal}
            totalNoDecimal={totalNoDecimal}
            priceToText={priceToText}
            itemCount={itemCount}
            totalCostWithNoDiscountNumber={totalCostWithNoDiscountNumber}
            free={free}
            totalCostNumber={totalCostNumber}
            total={total}
            handleOpenModal={handleOpenModal}
            hideDiscount={hideDiscount}
          />
        </div>
      </Fader>

      <DeleteConfirmationModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        onDelete={deleteFromCart}
        isDisabled={isDisabledButton}
        itemName={name}
      />

      <PriceInfoModal
        isOpen={openPriceModal}
        onClose={handleCloseModal}
        itemCount={itemCount}
        cardCostWithText={cardCostWithText}
        hasDiscount={hasDiscount}
      />
    </div>
  );
};
