import { AxiosResponse } from 'axios';
import api from './http';
import { DataSet } from 'types/solutions';

export default class Service {
  static async getServiceTypes(): Promise<AxiosResponse> {
    return api.get('/api/v1/services/service_types/');
  }

  static async getBusinessTypes(): Promise<AxiosResponse> {
    return api.get('/api/v1/services/business_types/');
  }

  static async getFavorites(): Promise<AxiosResponse> {
    return api.get('/api/v1/services/favorites/count/');
  }

  static async consultRequest(
    service: string,
    project_description: string,
    question: string
  ): Promise<AxiosResponse> {
    return api.post('/api/v1/services/consult_request/', {
      service,
      project_description,
      question,
    });
  }

  static async getServices(params = '', data: DataSet = 'full'): Promise<AxiosResponse> {
    return api.get(`/api/v1/services/?data=${data}&${params}`);
  }

  static async getRecommendedServices(params = ''): Promise<AxiosResponse> {
    return api.get(`/api/v1/services/recommended_services/?${params}`);
  }

  static async addServiceToFavorite(serviceId: number) {
    return api.post(`/api/v1/services/${serviceId}/to_favorites/`);
  }

  static async removeServiceFromFavorite(serviceId: number) {
    return api.delete(`/api/v1/services/${serviceId}/to_favorites/`);
  }

  static async getService(slug: string) {
    return api.get(`/api/v1/services/${slug}/`);
  }

  static async IncrementViews(slug: string) {
    return api.get(`/api/v1/services/${slug}/increment/`);
  }
}
