import * as React from 'react';
import { SVGProps } from 'react';

export const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.799 3.87C3.544 4.917 2.665 6.148 2.221 6.85a7.655 7.655 0 0 0-.09.149v.002c.004.009.012.022.023.04l.067.108c.444.703 1.323 1.934 2.578 2.982C6.052 11.178 7.631 12 9.5 12c1.87 0 3.449-.823 4.702-1.87 1.255-1.047 2.133-2.278 2.577-2.981a7.188 7.188 0 0 0 .091-.148V7a7.188 7.188 0 0 0-.091-.148c-.444-.704-1.322-1.935-2.577-2.982C12.949 2.824 11.37 2 9.5 2 7.631 2 6.052 2.825 4.8 3.87ZM3.731 2.59C5.171 1.388 7.116.334 9.501.334c2.384 0 4.328 1.054 5.769 2.256 1.438 1.2 2.425 2.59 2.919 3.371l.02.032c.098.154.225.355.29.635a1.8 1.8 0 0 1 0 .745c-.065.28-.192.481-.29.635l-.02.032c-.494.782-1.48 2.17-2.92 3.371-1.44 1.202-3.384 2.256-5.769 2.256-2.384 0-4.328-1.054-5.769-2.256-1.438-1.2-2.425-2.589-2.919-3.371l-.02-.032a1.949 1.949 0 0 1-.29-.635 1.799 1.799 0 0 1 0-.745 1.949 1.949 0 0 1 .31-.667c.494-.782 1.48-2.17 2.919-3.37Zm5.77 2.744a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333ZM6.166 7.001a3.333 3.333 0 1 1 6.667 0 3.333 3.333 0 0 1-6.667 0Z"
      fill="#A09CAC"
    />
  </svg>
);
