import { Grid, GridItem } from 'components/common/Grid';
import styles from './CartSkeleton.module.scss';
import { useTranslation } from 'react-i18next';
import YouMayAlsoNeedSkeleton from 'components/shared/Skeleton/YouMayAlsoNeedSkeleton/YouMayAlsoNeedSkeleton';

const CartSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('Cart')}</div>

      <Grid direction="row">
        <GridItem col={{ default: 12, xsup: 8 }} className={styles.left}>
          <div className={styles['left-title']} />
          <ul className={styles.list}>
            <li className={styles.item}>
              <div className={styles['item-content']} />
              <div className={styles['item-content']} />
            </li>
            <li className={styles.item}>
              <div className={styles['item-content']} />
              <div className={styles['item-content']} />
            </li>
            <li className={styles.item}>
              <div className={styles['item-content']} />
              <div className={styles['item-content']} />
            </li>
          </ul>
        </GridItem>

        <GridItem col={{ default: 12, xsup: 4 }} className={styles.right}>
          <div className={styles['right-title']}>
            <div className={styles['title-item']} />
            <ul className={styles['title-content']}>
              <li className={styles['content-item']} />
              <li className={styles['content-item']} />
              <li className={styles['content-item']} />
            </ul>
          </div>

          <div className={styles['button']} />
        </GridItem>
      </Grid>

      <div className={styles['wrapper-other']}>
        <h3 className={styles['title-other']}>{t('Others usually choose')}</h3>

        <YouMayAlsoNeedSkeleton />
      </div>
    </div>
  );
};

export default CartSkeleton;
