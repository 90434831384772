import { yupResolver } from '@hookform/resolvers/yup';
import { urlCheckReg } from 'constants/matches';
import { useUserStore } from 'contexts/UserContext';
import { userStore as userStores } from 'stores/userStore';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Service from 'services/service';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { getLangName } from 'tools/utils';
import * as yup from 'yup';
import { DefaultValue } from './Business.props';
import {
  BusinessSelectedType,
  BusinessType,
  CompanySizeType,
} from 'types/solutions';
import { FormBusinessInformationValues } from 'types/forms';
import { DICTIONARY_FIELDS } from 'constants/enums';
import { toJS } from 'mobx';

export const useBusiness = (
  defaultValue: DefaultValue,
  getParams: () => Promise<void>
) => {
  const { t, i18n } = useTranslation();
  const userStore = useUserStore();

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('This field is required')),
    url: yup
      .string()
      .matches(urlCheckReg, t('Invalid link'))
      .required(t('This field is required')),
    size: yup.string(),
    industry: yup.string(),
    companyId: yup.string().optional(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    setValue,
    trigger,
  } = useForm<FormBusinessInformationValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const [companyName, setCompanyName] = useState<string>(
    defaultValue?.companyName || ''
  );

  const [companyId, setCompanyId] = useState<string>(
    defaultValue?.companyId || '-'
  );

  const { companySize = '', businessType = '' } = defaultValue ?? {};
  const [companyLink, setCompanyLink] = useState<string>(
    defaultValue?.companyLink || ''
  );
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [selectedCompanySize, setSelectedCompanySize] =
    useState<CompanySizeType>();

  const [selectedBusinessType, setSelectedBusinessType] =
    useState<BusinessSelectedType>();
  const [isFieldEmpty, setIsFieldEmpty] = useState({
    size: false,
    industry: false,
  });
  const [notice, setNotice] = useState({ type: '', text: '' });
  const [disableSubmit, setDisableSubmit] = useState(true);

  const getBusinessTypesInfo = useCallback(async () => {
    try {
      const response = await Service.getBusinessTypes();
      const data = response?.data?.results.map((item: BusinessType) => ({
        id: item.id,
        name: getLangName(item, 'name', i18n),
      }));
      setBusinessTypes(data);

      if (defaultValue.businessType) {
        const businessType = response?.data?.results?.find(
          (item: BusinessType) => item.id === defaultValue.businessType
        );
        setSelectedBusinessType(businessType);
        setValue('industry', getLangName(businessType, 'name', i18n));

        trigger();
      }
    } catch (error) {
      setBusinessTypes([]);
    }
  }, []);

  useEffect(() => {
    notice && getParams();

    userStores.loadDictionary(DICTIONARY_FIELDS.company_size);
  }, []);

  useEffect(() => {
    if (
      defaultValue &&
      companyName &&
      companyLink &&
      selectedCompanySize &&
      selectedBusinessType &&
      (defaultValue.companyName !== companyName ||
        defaultValue.companyLink !== companyLink ||
        defaultValue.companySize !== selectedCompanySize.id ||
        defaultValue.businessType !== selectedBusinessType.id)
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [
    defaultValue,
    companyName,
    companyLink,
    selectedCompanySize,
    selectedBusinessType,
  ]);

  useEffect(() => {
    if (!companyName)
      setError('companyName', {
        type: 'server',
        message: t('This field is required'),
      });
    if (!companyLink)
      setError('url', { type: 'server', message: t('This field is required') });
  }, [companyLink, companyName, setError, t]);

  useEffect(() => {
    setIsFieldEmpty({
      size: !selectedCompanySize && !companySize,
      industry: !selectedBusinessType && !businessType,
    });
  }, [selectedCompanySize, selectedBusinessType, companySize, businessType]);

  useEffect(() => {
    getBusinessTypesInfo();
    setValue('companyName', defaultValue.companyName);
    setValue('url', defaultValue.companyLink);
  }, []);

  const submit = async () => {
    const formValues = new FormData();

    formValues.append('company_name', companyName);
    formValues.append('company_link', companyLink);
    (selectedCompanySize?.id || companySize) &&
      formValues.append('company_size', selectedCompanySize?.id || companySize);
    (selectedBusinessType?.id || businessType) &&
      formValues.append(
        'company_industry',
        String(selectedBusinessType?.id || businessType)
      );

    try {
      const result = await Request({
        type: 'PUT',
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      userStore.addUser(result?.user);
      ShowNotification({
        type: 'success',
        children: 'Profile successfully changed',
      });
      setDisableSubmit(true);
    } catch {
      ShowNotification({ type: 'error', children: 'Something went wrong' });
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)

      .catch((error) => {
        console.error('Ошибка копирования:', error);
      });
  };

  useEffect(() => {
    if (defaultValue.companySize) setValue('size', defaultValue.companySize);
    if (defaultValue.businessType)
      setValue('industry', defaultValue.businessType.toString());
  }, [defaultValue]);

  return {
    t,
    handleSubmit,
    submit,
    errors,
    setCompanyName,
    companyName,
    companyId,
    control,
    setCompanyLink,
    companyLink,
    setSelectedBusinessType,
    businessTypes,
    setSelectedCompanySize,
    notice,
    handleCopy,
  };
};
