import classes from './RegistrationFormRu.module.scss';
import clsx from 'clsx';
import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { FC } from 'react';
import { useRegistrationFormRu } from './useRegistrationFormRu';
import { companySizeList } from 'constants/companySizeList';
import { observer } from 'mobx-react-lite';

interface Props {
  kidUserInfo: { [key: string]: any } | null; // No data example provided
}

export const RegistrationFormRu: FC<Props> = observer(({ kidUserInfo }) => {
  const {
    formRef,
    handleSubmit,
    submit,
    t,
    setcompanyNameSelect,
    selectedBusinessType,
    isStepOneValid,
    businessTypes,
    setSelectedBusinessType,
    i18n,
    setSelectedCompanySize,
    errors,
    control,
    setcompanyLink,
    selectedCompanySize,
    isInvite,
  } = useRegistrationFormRu(kidUserInfo);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(submit)}
      className={classes.form}
    >
      <div className={classes.headerWrapper}>
        <h3 className={classes.headerTitle}>
          {isInvite
            ? t(`Agreement with the platform's documents`)
            : t('You are welcome!')}
        </h3>
        <p className={classes.headerDescription}>
          {isInvite
            ? t(
                'Review the user documentation of the platform and confirm your consent to registration'
              )
            : t('Tell us about your business to see personalized solutions')}
        </p>
      </div>

      {!isInvite && (
        <div className={classes['top-fields']}>
          <div
            className={clsx(
              classes['input-field'],
              classes['input-field--full']
            )}
          >
            <Controller
              render={({ field }) => (
                <Input
                  type={'text'}
                  placeholder={t('Enter company name')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    setcompanyNameSelect(e);
                  }}
                  error={!!errors?.companyName}
                  onPaste={(e: any) => {
                    e.preventDefault();
                    const currentValue = field.value || '';
                    const pastedText = e.clipboardData.getData('text');

                    const availableSpace = 200 - currentValue.length;
                    const truncatedText = pastedText.slice(0, availableSpace);

                    field.onChange(currentValue + truncatedText);
                    setcompanyNameSelect(currentValue + truncatedText);
                  }}
                />
              )}
              control={control}
              name="companyName"
            />
            {errors?.companyName && (
              <TooltipContainer
                text={errors?.companyName?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>
          <div
            className={clsx(
              classes['input-field'],
              classes['input-field--full']
            )}
          >
            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  type={'text'}
                  placeholder={t('Website, LinkedIn, Facebook, etc.')}
                  className={classes.email}
                  value={field.value}
                  error={!!errors?.url}
                  onChange={(e) => {
                    field.onChange(e);
                    setcompanyLink(e);
                  }}
                />
              )}
              name="url"
            />
            {errors?.url && (
              <TooltipContainer
                text={errors.url?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}
          </div>
          <div
            className={clsx(
              classes['input-field'],
              classes['input-field--full']
            )}
          >
            <Controller
              name="size"
              control={control}
              render={() => (
                <MultiselectComponent
                  data={companySizeList(t)}
                  defaultSelected={selectedCompanySize?.id || null}
                  placeholder={t('Company size')}
                  setMultiselect={setSelectedCompanySize}
                  search={false}
                  isCompanySize
                />
              )}
            />
          </div>
          <div
            className={clsx(
              classes['input-field'],
              classes['input-field--full']
            )}
          >
            <Controller
              name="industry"
              control={control}
              render={() => (
                <MultiselectComponent
                  data={businessTypes}
                  defaultSelected={selectedBusinessType?.id || null}
                  placeholder={t('Industry of the company')}
                  setMultiselect={setSelectedBusinessType}
                  search={false}
                />
              )}
            />
          </div>
        </div>
      )}

      <div className={classes['checkbox-block']}>
        <Controller
          render={({ field }) => (
            <>
              <Checkbox onChange={field.onChange} checked={field.value} />
              <div className={classes.text}>
                <span>{t('I accept the terms')} </span>
                <a
                  className={classes.link}
                  href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {t('privacy Policy')}
                </a>
                <span>{t('and')}</span>
                <a
                  className={classes.link}
                  href={`${process.env.REACT_APP_USER_AGREEMENT}`}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {t('user agreement')}
                </a>
              </div>
            </>
          )}
          name="iAgree"
          control={control}
        />
      </div>

      <div className={classes.submit}>
        <Button
          type="submit"
          className={clsx(classes.button, classes['submit-button'])}
          theme="primary"
          disabled={!isStepOneValid}
        >
          {isInvite ? t('Continue') : t('Create account')}
        </Button>
      </div>
    </form>
  );
});
