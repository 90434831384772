import React, { SVGProps } from 'react';

const FilterMobileTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M4.66667 2.66669V13.3334M4.66667 13.3334L2 10.6667M4.66667 13.3334L7.33333 10.6667M11.3333 13.3334V2.66669M11.3333 2.66669L8.66667 5.33335M11.3333 2.66669L14 5.33335"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterMobileTableIcon;
