import { DealStageType } from 'constants/dealStatuses';
import { DEAL_STAGES } from 'constants/enums';
import 'containers/CardsContainer/IntroTooltip.css';
import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectProps } from 'types/project';

export const useProjects = () => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const userLocale = userStore.user?.language?.lang_code;
  let projects = userStore?.projects || [];

  const { PAUSED, COMPLETED, CANCELLED, ACTIVE, ALL } = DEAL_STAGES;

  const nonActiveStage = [PAUSED, COMPLETED, CANCELLED];
  const [projectsToPaginate, setProjectsToPaginate] =
    useState<ProjectProps[]>(projects);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<DealStageType>(ALL as DealStageType);
  const chunkSize = 3;
  const [chunks, setChunks] = useState<ProjectProps[][]>([]);

  const allCount = projects?.length;
  const activeCount = projects?.filter(
    (project: ProjectProps) =>
      !nonActiveStage.includes(
        project?.deal_stage?.type?.toLowerCase() as DEAL_STAGES
      )
  )?.length;
  const prodCount = projects.filter(
    (project: ProjectProps) =>
      project?.deal_stage?.type?.toLowerCase() === COMPLETED
  ).length;
  const pausedCount = projects.filter(
    (project: ProjectProps) =>
      project.deal_stage?.type?.toLowerCase() === PAUSED
  ).length;
  const cancelledCount = projects.filter(
    (project: ProjectProps) =>
      project.deal_stage?.type?.toLowerCase() === CANCELLED
  ).length;

  const filteredProjects = (projects: ProjectProps[]) => {
    switch (filter) {
      case ALL:
        return projects;
      case COMPLETED:
        return projects?.filter(
          (project: ProjectProps) =>
            project?.deal_stage?.type?.toLowerCase() === COMPLETED
        );
      case PAUSED:
        return projects?.filter(
          (project: ProjectProps) =>
            project?.deal_stage?.type?.toLowerCase() === PAUSED
        );
      case CANCELLED:
        return projects.filter(
          (project: ProjectProps) =>
            project?.deal_stage?.type?.toLowerCase() === CANCELLED
        );
      case ACTIVE:
        return projects.filter(
          (project: ProjectProps) =>
            !nonActiveStage.includes(
              project?.deal_stage?.type?.toLowerCase() as DEAL_STAGES
            )
        );
      default:
        return projects;
    }
  };

  projects = filteredProjects(projects);

  useEffect(() => {
    setProjectsToPaginate(filteredProjects(projects));
  }, [currentPage, filter, userLocale, userStore.projects]);

  useEffect(() => {
    if (JSON.stringify(projectsToPaginate) !== JSON.stringify(chunks.flat())) {
      const chunkedProjects = [];
      for (let i = 0; i < projectsToPaginate.length; i += chunkSize) {
        chunkedProjects.push(
          projectsToPaginate.length > 2
            ? projectsToPaginate.slice(i, i + chunkSize)
            : projectsToPaginate
        );
      }
      setChunks(chunkedProjects);
    }
  }, [projectsToPaginate, chunkSize, chunks]);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    projects,
    t,
    setFilter,
    filter,
    cancelledCount,
    pausedCount,
    prodCount,
    allCount,
    activeCount,
    chunks,
    projectsToPaginate,
    currentPage,
    goToTop,
    setCurrentPage,
  };
};
