import React from 'react';
import classes from './Acts.module.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'classnames';
import Table from 'components/shared/Table';
import { ActsProps } from './Acts.props';
import { useActsTable } from './useActsTable';

const Acts = ({ className, ...props }: ActsProps) => {
  const { t } = useTranslation();

  const {
    data,
    columns,
    actsLength,
    // tableActive,
    // getDataByPage,
    // itemsPerPage,
    // customPageCount,
    // filter,
    // handleShowPaymentBillModal,
  } = useActsTable();

  return (
    <div className={className} {...props}>
      {/* {actsLength > 1 ? ( */}
      {true ? (
        <div className={clsx(classes.empty)}>
          <div className={classes.empty__header}>
            {t("You don't have any acts")}
          </div>

          <div className={classes.empty__text}>
            {t('To start a Project please select a Solution')}
          </div>

          <a href="/solutions" className={classes.empty__back}>
            {t('Choice to Solutions')}
          </a>
        </div>
      ) : (
        <Table
          additionalClassNames={classes.table}
          data={data}
          columns={columns}
          // tableActive={tableActive}
          // customPageCount={customPageCount}
          // getDataByPage={getDataByPage}
          // defaultPageSize={itemsPerPage}
          // mobileModeTitle={t('New ones first')}
          // filter={filter}
          // handleShowModalOnButton={handleShowPaymentBillModal}
        />
      )}
    </div>
  );
};

export default Acts;
