import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import { PageLoader } from 'components/shared/PageLoader';

export const LoaderWrapper = ({ children }: { children: React.ReactNode }) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<PageLoader />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
