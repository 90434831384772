import { NotFoundPageContainer } from 'containers/NotFoundPageContainer';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import 'styles/main.scss';

export const NotFoundPage = () => (
  <>
    <TitleAndDescription pageName="404" />

    <NotFoundPageContainer />
  </>
);
