import clsx from 'clsx';
import EmptyProducts from 'components/shared/EmptyProducts/EmptyProducts';
import { Fader } from 'components/shared/Fader';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import { PersonIcon } from 'components/shared/Icons/PersonIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { InvoiceCardContainer } from '../Finances/InvoiceCard';
import { ProjectsDealStagesContainer } from './DealStage';
import { ProjectGoalsContainer } from './Goals';
import classes from './ProjectsDetail.module.scss';
import { tableHeaders, tabsId, useProjectsDetail } from './useProjectsDetail';
import { ProjectSubInvoice } from 'types/project';
import { RUSSIAN_LOCALE } from 'utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { LABELS } from 'constants/onboarding';
import ProjectDocuments from './ProjectDocuments';

export const ProjectsDetailContainer = observer(() => {
  const {
    project,
    projectName,
    t,
    isTablet,
    shortProjectName,
    source,
    tabs,
    shortSource,
    tabid3,
    tabid2,
    tabid1,
    activeTab,
    setActiveTab,
    projectInvoices,
    formattedMessage,
    totalDue,
    tableActive,
    fetchPaginationData,
    currentPage,
    totalPages,
    updateProject,
    manager_img,
  } = useProjectsDetail();

  const projectManager = project?.manager;
  const projectNumber = project?.number;
  const legalEntity = project?.legal_entity;
  const projectCost = project?.cost?.[0]?.cost;
  const projectCurrencySymbol = project.currency?.symbol;

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'PROJECTS_DETAILS';
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('PROJECTS_DETAILS').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <>
      <TitleAndDescription pageName="ProjectsDetail" title={projectName} />

      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          onChange={(step: number) => {
            if (step === 0 || step === 1 || step === 2) {
              setActiveTab(step + 1);
            } else {
              setActiveTab(1);
            }
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <div className={classes.wrapper}>
        <ul className={classes.breadcrumbs}>
          <li className={classes.breadcrumbs__item}>
            <NavLink to={`/projects/`} className={classes.breadcrumbs__link}>
              {t('Projects')}
            </NavLink>
          </li>
          <li className={classes.breadcrumbs__item}>
            <span className={classes.breadcrumbs__link}>
              {isTablet ? shortProjectName : projectName}
            </span>
          </li>
        </ul>

        <h1 className={classes.project__header}>{projectName}</h1>

        <div
          className={clsx(classes.project__link, !isTablet && classes.desktop)}
        >
          <div className={classes.project__title__description}>
            <>
              <div
                className={clsx(
                  classes.project__statusProject,
                  classes[project.status]
                )}
              >
                {t(project.status)}
              </div>

              <div className={classes.dot} />
            </>

            <div
              className={clsx(
                classes.project__id,
                source && classes.project__id__source
              )}
            >
              ID {projectNumber}
            </div>

            {source && (
              <>
                <div className={classes.dot} />
                <NavLink to={source} target="_blank">
                  {shortSource}
                  <ArrowUpRight className={classes.arrowIcon} />
                </NavLink>
              </>
            )}
          </div>
        </div>

        <div className={classes.grid}>
          <div
            className={classes.grid__main}
            data-step-projects_details-0
            data-step-projects_details-1
            data-step-projects_details-2
          >
            <div className={classes.project__tabs} id={tabsId}>
              {tabs.map((tab) => (
                <label key={tab.id} className={classes.project__tab}>
                  <input
                    type="radio"
                    name="project_tab"
                    id="stages"
                    onChange={() => {
                      setActiveTab(tab.id);
                    }}
                    checked={activeTab === tab.id}
                  />
                  <div>
                    {tab.label}
                    {/* {tab.id === tabid3 && (
                      <span>{projectInvoices?.results?.all_count}</span>
                    )} */}
                  </div>
                </label>
              ))}
            </div>

            {activeTab === tabid1 && (
              <ProjectsDealStagesContainer project={project} />
            )}

            {/* {activeTab === tabid3 && (
              <div className={classes.invoices}>
                <div className={classes.invoices__cards}>
                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Project cost')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={project?.solution?.is_free ? 0 : projectCost}
                        withMinus={false}
                      />
                    </div>
                  </div>

                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Total due')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={totalDue}
                      />
                    </div>
                    <div className={classes.invoices__card__text}>
                      {formattedMessage}
                    </div>
                  </div>
                </div>

                {projectInvoices?.results?.sub_invoices?.length ? (
                  <Fader active={tableActive}>
                    <div className={classes.invoices__table}>
                      <div className={classes.invoices__table__row}>
                        <div className={classes.invoices__table__cell}>
                          <button className={classes.invoices__table__search}>
                            <SearchIcon />
                          </button>
                        </div>

                        {tableHeaders.map(({ label, icon }) => (
                          <div
                            key={label}
                            className={classes.invoices__table__cell}
                          >
                            <span>{t(label)}</span>
                            {icon && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4 8H12M2 4H14M6 12H10"
                                  stroke="#9C93AA"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        ))}
                      </div>

                      {projectInvoices?.results?.sub_invoices.map(
                        (invoice: ProjectSubInvoice) => (
                          <InvoiceCardContainer
                            invoice={invoice}
                            className={classes.mini}
                          />
                        )
                      )}

                      {totalPages > 1 && (
                        <div className={classes.pagination}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setPageNumber={fetchPaginationData}
                          />
                        </div>
                      )}
                    </div>
                  </Fader>
                ) : (
                  <EmptyProducts
                    bottomText={t("You don't have any invoices")}
                  />
                )}
              </div>
            )} */}

            {activeTab === tabid2 && (
              <ProjectGoalsContainer
                project={project}
                updateProject={updateProject}
                tabsId={tabsId}
              />
            )}

            {activeTab === tabid3 &&
              (project?.files?.length ||
              projectInvoices?.results?.sub_invoices?.length ||
              project?.agreement_file ||
              project?.commercial_proposal_file ? (
                <ProjectDocuments
                  project={project}
                  t={t}
                  projectInvoices={projectInvoices}
                />
              ) : (
                <EmptyProducts bottomText={t("You don't have any documents")} />
              ))}
          </div>

          <div className={classes.grid__side}>
            {legalEntity && (
              <div className={classes.grid__side__card}>
                <div className={classes.grid__side__card__top}>
                  <PersonIcon />
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header}>
                      {legalEntity.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Legal entity')}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className={classes.grid__side__card}>
              {projectManager ? (
                <div className={classes.grid__side__card__top}>
                  {!projectManager.image ? (
                    <img
                      src={manager_img}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  ) : (
                    <img
                      src={projectManager.image}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  )}
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header_mini}>
                      {projectManager.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={classes.grid__side__card__top}>
                  <img
                    src={manager_img}
                    alt=""
                    className={classes.card__grid__card__img}
                  />
                  <div className={classes.no_manager}>
                    <p className={classes.no_manager_title}>
                      {t('Assigning manager')}
                    </p>
                    <p className={classes.no_manager_description}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
