import { Button } from 'components/shared/Button';
import classes from './SolutionsStand.module.scss';
import { useNavigate } from 'react-router-dom';

interface SolutionsStandProps {
  title: string;
}

const SolutionsStand = ({ title }: SolutionsStandProps) => {
  const navigate = useNavigate();

  return (
    <div id={'showcase'} className={classes.stand}>
      <span className={classes.stand__title}>{title}</span>
      <div className={classes.stand__empty}>
        <span className={classes['stand__empty-title']}>Нет данных</span>
        <span className={classes['stand__empty-description']}>
          Здесь будут отображаться все персональные предложения
        </span>
        <Button
          onClick={() => navigate('/solutions')}
          theme="primary"
          className={classes['stand__empty-button']}
        >
          Перейти в каталог решений
        </Button>
      </div>
    </div>
  );
};

export default SolutionsStand;
