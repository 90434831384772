import ClientPoints from 'containers/GrowthProgramMain/components/ClientList/ClientPoints';
import classes from './BonusHistory.module.scss';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import BonusTableContainer from './BonusTableContainer';
import { useUserStore } from 'contexts/UserContext';
import { observer } from 'mobx-react-lite';

const BonusHistoryContainer = () => {
  const userStore = useUserStore();

  return (
    <div className={classes.wrapper}>
      <BreadCrumbs
        link="/programma-rosta/"
        linkName="Программа роста"
        className={classes.breadcrumbs}
        items={['История начислений']}
      />

      <div className={classes.title}>
        <span>{'История начислений'}</span>
      </div>

      <div className={classes.content}>
        <ClientPoints
          className={classes.pointsWrapper}
          classNamePoints={classes.pointsWrapper__points}
          title={'Баллы'}
          points={userStore.user?.available_bonus_balance || 0}
          secondLinkName="Правила бонусной программы"
        />

        <BonusTableContainer />
      </div>
    </div>
  );
};

export default observer(BonusHistoryContainer);
