import { Request } from 'tools/request';
import { OnSaveLocalPreferencesData } from 'types/user';

interface Props {
  access: string;
  data: Omit<OnSaveLocalPreferencesData, 'country'> & {
    country?: { id: number };
  };
}

export async function ChangeUser({ access, data }: Props) {
  return await Request({
    type: 'PUT',
    url: `${process.env.REACT_APP_API}/api/v1/users/`,
    isProtected: true,
    access: access,
    data: data,
  });
}
