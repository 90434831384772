import * as React from 'react';
import { SVGProps } from 'react';

export const PaymentFailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10607_46738)">
      <path
        d="M61.1996 34.8L47.9996 48M47.9996 48L34.7996 61.2M47.9996 48L34.7996 34.8M47.9996 48L61.1996 61.2M92 48C92 72.3005 72.3005 92 48 92C23.6995 92 4 72.3005 4 48C4 23.6995 23.6995 4 48 4C72.3005 4 92 23.6995 92 48Z"
        stroke="url(#paint0_linear_10607_46738)"
        strokeWidth="6.62069"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10607_46738"
        x1="-38.68"
        y1="-44.84"
        x2="57.24"
        y2="61.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB3D2" />
        <stop offset="1" stopColor="#FF4848" />
      </linearGradient>
      <clipPath id="clip0_10607_46738">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
