export const Icon = (props) => (
  <div
    style={{
      width: props.width,
      height: props.height,
      minWidth: props.width,
    }}
    className="common__icon"
  >
    <svg className={`icon icon-${props.name}`} fill={props.iconFill}>
      <use xlinkHref={`#${props.name}`} />
    </svg>
  </div>
);
