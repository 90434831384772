import * as React from 'react';
import { SVGProps } from 'react';

export const USDMexicanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="14"
    viewBox="0 0 22 14"
    fill="none"
  >
    <path
      d="M0 11.858L1.414 1.358H3.22L6.412 9.394L9.59 1.358H11.41L12.81 11.858H11.088L10.108 4.55L7.21 11.858H5.614L2.702 4.564L1.722 11.858H0Z"
      fill="#9C93AA"
    />
    <path
      d="M17.1334 13.23V11.62C15.4394 11.368 14.2074 10.332 13.8574 8.848L15.4814 8.26C15.7334 9.436 16.6154 10.066 18.0014 10.066C19.3314 10.066 20.1014 9.576 20.1014 8.708C20.1014 8.092 19.6954 7.742 18.7714 7.518L16.8674 7.084C15.3414 6.734 14.4454 5.768 14.4454 4.452C14.4454 3.024 15.5234 1.946 17.1334 1.638V0H18.7434V1.596C20.3114 1.806 21.3754 2.73 21.6974 4.102L20.0874 4.676C19.8774 3.71 19.1354 3.164 18.0014 3.164C16.8534 3.164 16.1534 3.654 16.1534 4.466C16.1534 5.04 16.5174 5.39 17.3014 5.572L19.2194 6.02C20.8994 6.398 21.8094 7.322 21.8094 8.68C21.8094 10.234 20.5914 11.368 18.7434 11.62V13.23H17.1334Z"
      fill="#9C93AA"
    />
  </svg>
);
