import 'styles/main.scss';
import { DashboardContainer } from 'containers/DashboardContainer';
import { DashboardContainerRU } from 'containers/DashboardContainerRU';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';

export const DashboardPage = () => {
  const { t } = useTranslation();

  return RUSSIAN_LOCALE ? (
    <DashboardContainerRU title={t('Dashboard')} />
  ) : (
    <DashboardContainer title={t('Dashboard')} />
  );
};
