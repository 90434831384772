import React from 'react';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { useCartStore } from 'contexts/CartContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryParamsHandler } from '../../CardsContainer/utils';
import { CartCard } from '../CartCard';
import classes from './CartList.module.scss';
import { CartListProps } from './CardList.props';

export const CartList = observer(
  ({ data, cartId, selectedItems }: CartListProps) => {
    const marketPlaceStore = useMarketPlaceStore();
    const cartStore = useCartStore();
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(cartStore?.isSelected);

    useEffect(() => {
      setDisabled(cartStore?.isSelected);
    }, [cartStore?.isSelected]);

    const handleAllCheckboxChange = useCallback(
      (event: boolean) => {
        setDisabled(!disabled);
        cartStore.handleAllItemsInSelectedItem(data, event);
      },
      [data]
    );

    const handleRemoveSelected = () => {
      cartStore.handleRemoveSelected();
    };

    useEffect(() => {
      const params = queryParamsHandler({ isHot: true });
      marketPlaceStore.loadHotDeals(params);
    }, []);

    return (
      <div className={classes.list} data-step-cart-1>
        <div className={classes.top}>
          <Checkbox
            className={classes.checkbox}
            onChange={handleAllCheckboxChange}
            checked={selectedItems.length === data.length}
            label={t('Select all')}
            disabled={disabled}
          />

          <button className={classes.top__reset} onClick={handleRemoveSelected}>
            {t('Remove selected')}
          </button>
        </div>

        <div className={classes.cards}>
          {data?.map((item) => (
            <CartCard
              hotDeals={marketPlaceStore.dataTimeHotDeals}
              cartId={cartId}
              item={item}
              key={item.service.id}
              selectedItems={selectedItems}
            />
          ))}
        </div>
      </div>
    );
  }
);
