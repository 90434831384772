import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'types/finances';

import 'containers/CardsContainer/IntroTooltip.css';
import { columnsArray, queryParamsHandler, tableData } from './tableConfig';
import { ProjectActResult, ProjectInvoiceResult } from 'types/project';
import { PaginationResponse } from 'types';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { toJS } from 'mobx';

export const useActsTable = () => {
  const userStore = useUserStore();
  const { isMediaTablet } = useWindowWidth();
  // const { setBillsForPayment } = financesStore;

  const projectActs = userStore.projectActs;
  const results = projectActs.results;
  // const invoices = results.sub_invoices;
  // const allInvoices = results.all_count ?? 0;
  // const paidInvoices = results.paid_count ?? 0;
  // const toPayInvoices = results.to_pay_count ?? 0;
  // const cancelledInvoices = results.cancelled_count ?? 0;

  const { t } = useTranslation();

  const [acts, setActs] = useState<ProjectActResult[]>(results);

  const [tableActive, setTableActive] = useState(false);
  // const [internalInvoices, setInternalInvoices] = useState<Invoice[]>(invoices);
  const itemsPerPage = isMediaTablet ? 5 : 10;
  // const [] = useState()
  const [filter, setFilter] = useState<Filter>('all');
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(
    undefined
  );

  // const [dataLength, setDataLength] = useState(allInvoices);

  // useEffect(() => {
  //   if (filter === 'all') {
  //     // setDataLength(allInvoices);
  //   } else if (filter === 'paid') {
  //     // setDataLength(paidInvoices);
  //   } else if (filter === 'topay') {
  //     // setDataLength(toPayInvoices);
  //   } else if (filter === 'cancelled') {
  //     // setDataLength(cancelledInvoices);
  //   }
  // }, [filter]);

  // useEffect(() => {
  //   if (dataLength) {
  //     setCustomPageCount(Math.ceil(dataLength / itemsPerPage));
  //   }
  // }, [dataLength]);

  // useEffect(() => {
  // setInternalInvoices(invoices);
  // }, [filter, invoices, results]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getDataByPage = async (currentPage: number | undefined) => {
    const params = queryParamsHandler({
      page: currentPage,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore
        .loadProjectActs(params)
        .then((response: PaginationResponse<ProjectInvoiceResult>) => {
          // setDataLength(response?.count);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
      handleScrollToTop();
    }
  };

  const getDataByFilter = async (filter: Filter) => {
    const params = queryParamsHandler({
      page: 1,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore.loadInvoices(params);
      setFilter(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const columns = useMemo(() => columnsArray(t), [t]);

  const data = useMemo(() => tableData(acts, t), [acts, t]);

  const actsLength = useMemo(() => acts?.length || 0, [acts]);

  // const [showTopUpModal, setShowTopUpModal] = useState(false);
  // const [showPaymentBillModal, setShowPaymentBillModal] = useState(false);

  // const handleShowTopUpModal = () => {
  //   setShowTopUpModal(true);
  // };

  // const handleCloseTopUpModal = () => {
  //   setShowTopUpModal(false);
  // };

  // const handleShowPaymentBillModal = (items: Bill[]) => {
  //   setBillsForPayment(items);
  //   setShowPaymentBillModal(true);
  // };

  // const handleClosePaymentBillModal = () => {
  //   setShowPaymentBillModal(false);
  // };

  // for the balance replenishment button on mobiles when scrolling
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.scrollY;
  //   if (position > 320) {
  //     setScrollPosition(321);
  //   } else {
  //     setScrollPosition(0);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // ______

  return {
    data,
    columns,
    actsLength,
    // acts,
    // scrollPosition,
    // activeTab,
    // setActivTab,
    // tabs,
    // internalInvoices,
    // handleClosePaymentBillModal,
    // showPaymentBillModal,
    // handleShowPaymentBillModal,
    // filter,
    // itemsPerPage,
    // getDataByPage,
    // customPageCount,
    // tableActive,
    // columns,
    // getDataByFilter,
    // cancelledInvoices,
    // toPayInvoices,
    // paidInvoices,
    // allInvoices,
  };
};
