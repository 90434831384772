import * as React from 'react';
import { SVGProps } from 'react';

export const PaymentSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="48"
      cy="48"
      r="44.6897"
      stroke="url(#paint0_linear_10607_46722)"
      strokeWidth="6.62069"
    />
    <path
      d="M28.9648 47.971L42.1882 61.5862L69.5166 33.4482"
      stroke="url(#paint1_linear_10607_46722)"
      strokeWidth="6.62069"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10607_46722"
        x1="-46.56"
        y1="-53.28"
        x2="58.08"
        y2="62.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB3D2" />
        <stop offset="1" stopColor="#FF4848" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10607_46722"
        x1="9.29726"
        y1="17.8317"
        x2="37.0563"
        y2="62.0581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB3D2" />
        <stop offset="1" stopColor="#FF4848" />
      </linearGradient>
    </defs>
  </svg>
);
