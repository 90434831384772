import React, { ReactNode } from 'react';
import { growthStore, GrowthProgramStore } from 'stores/growthProgram';

const GrowthProgram = React.createContext<GrowthProgramStore | null>(null);

export const GrowthProgramProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <GrowthProgram.Provider value={growthStore}>
    {children}
  </GrowthProgram.Provider>
);

export const useGrowthProgramStore = () => {
  const context = React.useContext(GrowthProgram);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with GrowthProgramProvider'
    );
  }
  return context;
};
