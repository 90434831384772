import { EmptyIcon } from 'components/shared/Icons/EmptyIcon';
import classes from './EmptyProducts.module.scss';
import { EmptyProductsProps } from './EmptyProducts.props';

const EmptyProducts = ({
  title,
  noIcon = false,
  bottomText,
}: EmptyProductsProps) => (
  <div className={classes['empty__container']}>
    {title && <span className={classes['empty__title']}>{title}</span>}

    {!noIcon && <EmptyIcon />}

    {bottomText && (
      <span className={classes['empty__bottom-text']}>{bottomText}</span>
    )}
  </div>
);

export default EmptyProducts;
