export const getFormattedDate = (date: Date | null): string => {
  if (!date) return '';

  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = String(date.getFullYear()).slice(-2); // We use the last two digits of the year

  return `${day}.${month}.${year}`;
};

export const getFormattedTime = (date: Date): string => {
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
};

export const formatRuFullDate = (dateString: string): string => {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
    .format(date)
    .replace('г.', 'г');
};

const monthNamesShort: { [key: number]: string } = {
  0: 'Янв.',
  1: 'Февр.',
  2: 'Март', // March, May, June, July are not shortened
  3: 'Апр.',
  4: 'Май',
  5: 'Июнь',
  6: 'Июль',
  7: 'Авг.',
  8: 'Сент.',
  9: 'Окт.',
  10: 'Нояб.',
  11: 'Дек.',
};

export const formatRuShortDate = (dateString: string): string => {
  // Parse the date string
  const date = new Date(dateString);

  const day = date.getDate(); // Get the day
  const month = date.getMonth(); // Get the month number (0-11)
  const year = date.getFullYear(); // Get the year

  // Return the formatted string using the shortened month names
  return `${monthNamesShort[month]} ${day}.  ${year} г`;
};
