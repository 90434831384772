import { CLIENT_LEVEL, COMPANY_SIZE } from 'constants/enums';
import { LocalizedNames } from './util-types';

export type Country = {
  cca2: string;
  flag: string;
  id: number;
  name: string;
};

export type Language = {
  id: number;
  lang_code: string;
  name: string;
};

export type OnSaveLocalPreferencesData = {
  language: string;
  country: { id: number };
  currency: { id: number };
};

export enum CLIENT_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  VIEWER = 'VIEWER',
}

export enum CLIENT_STATUS {
  REVIEW = 'REVIEW',
  MODERATED = 'MODERATED',
  REJECTED = 'REJECTED',
  ACTIVE_ON_PLATFORM = 'ACTIVE_ON_PLATFORM',
  ARCHIEVED = 'ARCHIEVED',
}

export type ClientCompanySizeType = {
  type: string;
  name: string;
};

export type ClientBusinessType = LocalizedNames & {
  bitrix_id: string;
  id: number;
  name: string;
};

export type PermissionType = LocalizedNames & {
  type: CLIENT_ROLE;
};

export type LegalEntitiesType = {
  name: string;
  tax_id: string;
};

export type Client = {
  bitrix_id?: number;
  id: number;
  status: CLIENT_STATUS;
  name: string;
  link: string;
  company_size: ClientCompanySizeType;
  business_type: ClientBusinessType;
  permission: PermissionType;
  legal_entities: LegalEntitiesType[];
  level?: {
    id: number;
    name: CLIENT_LEVEL;
  };
};

export type UserOnClient = {
  id: number;
  full_name: string;
  email: string;
  permission: PermissionType;
};

export type CompanySizeDictionary = LocalizedNames & {
  type: COMPANY_SIZE;
};
