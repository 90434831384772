import { userStore } from 'stores/userStore';
import i18next from 'i18next';
import { authStore } from 'stores/authStore';

type Onboarding = {
  id: number;
  system_name: string;
  path: string;
  onboarded: boolean;
};

type Step = {
  id: number;
  step: number;
  description_en: string;
  description_hi: string;
  description_es: string;
  description_es_mx: string;
  description_ind: string;
  description_ru: string;
};

type OnboardingSteps = {
  id: number;
  system_name: string;
  steps_count: number;
  steps: Step[];
};

type StepsProps = {
  element?: string | HTMLElement | Element;
  intro: string | React.ReactNode;
  position?: string;
  title?: string;
  tooltipClass?: string;
  highlightClass?: string;
};

const initOnboarding = async () => {
  const isAccessToken = authStore.accessToken;
  const isEnableOnboarding = process.env.REACT_APP_ONBOARDING === 'true'
  
  if (isAccessToken && isEnableOnboarding) {
    const res = await userStore.loadAllOnboardings();
    if (res && res?.results) {
      saveAllOnboarding(res.results);
    }
  }
};

const saveAllOnboarding = (data: Onboarding[]) => {
  try {
    const needOnboardingItems = data.filter((item) => !item.onboarded);

    localStorage.setItem(
      'needOnboardingItems',
      JSON.stringify(needOnboardingItems)
    );
  } catch (e) {
    return;
  }
};

const findOnbordingLS = (path: string): null | Onboarding => {
  try {
    const needOnboardingItemsJSON =
      localStorage.getItem('needOnboardingItems') || '';

    const needOnboardingItems: Onboarding[] = JSON.parse(
      needOnboardingItemsJSON
    );

    if (!needOnboardingItems) {
      return null;
    }

    const findedItem = needOnboardingItems.find((item) => item.path === path);

    return findedItem || null;
  } catch (e) {
    return null;
  }
};

const findOnboardingSteps = async (
  path: string
): Promise<OnboardingSteps | null> => {
  try {
    const onboarding = findOnbordingLS(path);
    if (!onboarding) {
      return null;
    }

    const info: OnboardingSteps = await userStore.loadOnboardingSteps(
      onboarding.id.toString()
    );

    return info || null;
  } catch (e) {
    return null;
  }
};

const formatSteps = (path: string, steps: Step[]): StepsProps[] => {
  const currentLanguage = i18next.language;

  const filteredSteps = steps.filter(
    (step) =>
      !(
        path.toLowerCase() === 'profile' &&
        currentLanguage === 'ru' &&
        (step.step === 3 || step.step === 4)
      )
  );

  return filteredSteps.map((step) => {
    let position = 'bottom';

    switch (path.toLowerCase()) {
      case 'cart':
        switch (step.step) {
          case 1:
            position = 'right';
            break;
          case 2:
            position = 'left';
            break;
          default:
            position = 'bottom';
        }
        break;

      case 'solutions':
        if (step.step === 0) {
          position = 'left';
        }
        break;

      case 'solutions_details':
        if (step.step === 1) {
          position = 'left';
        }
        break;

      case 'projects':
        if (step.step === 1) {
          position = 'right';
        }
        break;

      case 'locale':
        if (step.step === 1) {
          position = 'right';
        }
        break;

      case 'projects_details':
        if (step.step === 0 || step.step === 2) {
          position = 'right';
        }
        break;

      case 'profile':
        if (step.step === 1 || step.step === 2 || step.step === 3) {
          position = 'right';
        }
        break;

      default:
        position = 'bottom';
    }

    return {
      element: `[data-step-${path.toLowerCase()}-${step.step}]`,
      intro:
        step.step === 0
          ? `<div class="introjs-tooltip-title">${getDescription(currentLanguage, step)}</div>`
          : getDescription(currentLanguage, step),
      position,
    };
  });
};

const getDescription = (currentLanguage: string, step: Step) => {
  switch (currentLanguage) {
    case 'en':
      return step.description_en;
    case 'es':
      return step.description_es;
    case 'ind':
      return step.description_ind;
    case 'ru':
      return step.description_ru;
    default:
      return step.description_en;
  }
};

const completeOnboarding = async (onboardingId?: number) => {
  if (!onboardingId) {
    return;
  }

  await userStore.completeOnboarding(onboardingId.toString()).then(async () => {
    await initOnboarding();
  });
};

export { initOnboarding, findOnboardingSteps, formatSteps, completeOnboarding };
export type { OnboardingSteps };
