import { useEventListener } from 'hooks/useEventListener';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Search from 'services/search';
import { ShowNotification } from 'tools/showNotification';
import { ProjectProps } from 'types/project';
import { SolutionType } from 'types/solutions';

export const useSearchComponent = (setOpenSearch: (val: boolean) => void) => {
  const { isMediaTablet } = useWindowWidth();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [searchSolutions, setSearchSolutions] = useState<SolutionType[]>([]);
  const [searchProjects, setSearchProjects] = useState<ProjectProps[]>([]);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [responsible, setResponsible] = useState(false);

  useEffect(() => {
    const preventScroll = (event: TouchEvent) => {
      event.preventDefault();
    };

    if (isMediaTablet) {
      if (focus) {
        document.addEventListener('touchmove', preventScroll, {
          passive: false,
        });
      } else {
        document.removeEventListener('touchmove', preventScroll);
      }
    }

    return () => {
      document.removeEventListener('touchmove', preventScroll);
    };
  }, [focus, isMediaTablet]);

  const getSearchResults = async () => {
    try {
      setResponsible(false);

      // Encoding the '&' symbol in the search string
      const encodedSearch = search.replace(/&/g, '%26');
      const searchQueries = encodedSearch.split('&').map((q) => q.trim());
      let combinedSolutions: SolutionType[] = [];
      let combinedProjects: ProjectProps[] = [];

      if (searchQueries?.length) {
        for (const query of searchQueries) {
          const [responseSolutions, responseProjects] = await Promise.all([
            query && Search.getSearchResults('solutions', query, 1, 3),
            query && Search.getSearchResults('projects', query, 1, 3),
          ]);

          if (responseSolutions) {
            combinedSolutions = [
              ...combinedSolutions,
              ...responseSolutions?.data?.results,
            ];
          }

          if (responseProjects) {
            combinedProjects = [
              ...combinedProjects,
              ...responseProjects?.data?.results,
            ];
          }
        }
      } else {
        const [responseSolutions, responseProjects] = await Promise.all([
          Search.getFastSearchResults(search.trim()),
          Search.getFastSearchResults(search.trim()),
        ]);

        if (responseSolutions) {
          combinedSolutions = responseSolutions?.data?.results;
        }

        if (responseProjects) {
          combinedProjects = responseProjects?.data?.results;
        }
      }

      setSearchSolutions(combinedSolutions);
      setSearchProjects(combinedProjects);

      if (combinedSolutions.length || combinedProjects.length) {
        setResponsible(true);
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    }
  };

  useEffect(() => {
    if (search) {
      getSearchResults();
    }
  }, [search]);

  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current?.contains(e.target as Node) && !focus) {
      setOpen(false);
    }
  };
  useEventListener('click', handleClick);

  const searchNavigate = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && responsible && search) {
      navigate(
        `/search-results#from=${encodeURIComponent(
          window.location.pathname
        )}&search=${encodeURIComponent(search)}`
      );
      setOpen(false);
      setOpenSearch(false);
    }
  };

  const clearSearch = () => {
    setSearch('');
    getSearchResults();
    setOpen(false);
    setResponsible(false);
  };

  return {
    ref,
    search,
    setSearch,
    t,
    setOpen,
    setFocus,
    searchNavigate,
    clearSearch,
    open,
    searchSolutions,
    setSearchSolutions,
    searchProjects,
    isMediaTablet,
  };
};
