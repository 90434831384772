import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LocalePageContainer } from 'containers/Locale';
import 'styles/main.scss';

export const LocalePage = () => (
  <>
    <TitleAndDescription pageName="localepage" />

    <LocalePageContainer />
  </>
);
