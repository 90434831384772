import clsx from 'clsx';
import classes from './ColorTag.module.scss';
import { StickerColor } from '../ColorSticker';

export type ColorTagProps = {
  color: StickerColor;
  text: string;
  className?: string;
  icon?: React.ReactNode;
};

const getColorClass = function (color: StickerColor) {
  switch (color) {
    case 'purple':
      return 'tag_purple';
    case 'yellow':
      return 'tag_yellow';
    case 'green':
      return 'tag_green';
    default:
      return 'tag_purple';
  }
};

function ColorTag({ color, text, className, icon }: ColorTagProps) {
  return (
    <div
      className={clsx(classes.tag, classes[getColorClass(color)], className)}
    >
      {icon}
      <span className={classes.tag__text}>{text}</span>
    </div>
  );
}

export default ColorTag;
