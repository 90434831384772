import classes from './classes.module.scss';
import clsx from 'clsx';
import { GridItemProps, GridProps } from './Grid.props';

export const Grid = ({
  children,
  direction,
  className: additionalClassName,
  jc,
}: GridProps) => {
  const className = clsx(
    classes.gridContainer,
    direction && classes[`direction-${direction}`],
    jc && classes[`jc-${jc}`],
    additionalClassName
  );

  return <div className={className}>{children}</div>;
};

export const GridItem = ({
  col,
  children,
  className: additionalClassName,
}: GridItemProps) => {
  const className = clsx(
    classes.gridItem,
    col?.default && classes[`default-${col.default}`],
    col?.xxs && classes[`xxs-${col.xxs}`],
    col?.xsup && classes[`xsUp-${col.xsup}`],
    col?.xs && classes[`xs-${col.xs}`],
    col?.sm && classes[`sm-${col.sm}`],
    col?.md && classes[`md-${col.md}`],
    col?.lg && classes[`lg-${col.lg}`],
    col?.xl && classes[`xl-${col.xl}`],
    col?.xxl && classes[`xxl-${col.xxl}`],
    col?.extra && classes[`extra-${col.extra}`],
    additionalClassName
  );

  return <div className={className}>{children}</div>;
};
