import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const StarAdminIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3L13.5154 6.93997C13.878 7.88269 14.0593 8.35405 14.3412 8.75054C14.591 9.10194 14.8981 9.40896 15.2495 9.65882C15.6459 9.94075 16.1173 10.122 17.06 10.4846L21 12L17.06 13.5154C16.1173 13.878 15.6459 14.0593 15.2495 14.3412C14.8981 14.591 14.591 14.8981 14.3412 15.2495C14.0593 15.6459 13.878 16.1173 13.5154 17.06L12 21L10.4846 17.06C10.122 16.1173 9.94075 15.6459 9.65882 15.2495C9.40896 14.8981 9.10194 14.591 8.75054 14.3412C8.35405 14.0593 7.88269 13.878 6.93997 13.5154L3 12L6.93997 10.4846C7.88269 10.122 8.35405 9.94075 8.75054 9.65882C9.10194 9.40896 9.40896 9.10194 9.65882 8.75054C9.94075 8.35405 10.122 7.88269 10.4846 6.93997L12 3Z"
      stroke="url(#paint0_linear_7145_16345)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7145_16345"
        x1="-5.73"
        y1="-6.99"
        x2="13.89"
        y2="14.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB3D2" />
        <stop offset="1" stop-color="#FF4848" />
      </linearGradient>
    </defs>
  </svg>
);
