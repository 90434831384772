import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useEventListener } from 'hooks/useEventListener';
import { useTranslation } from 'react-i18next';
import { Option } from './MultiselectComponent.props';

export const useMultiselectComponent = <T extends Option>(
  setMultiselect: (val: T) => void,
  data: T[],
  defaultSelected: number | string | null,
  isCompanySize?: boolean,
  placeholder?: string,
  langSelect?: boolean,
  isCurrency?: boolean
) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<T>();
  const [sourcesToShow, setSourcesToShow] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  useEffect(() => {
    if (data) {
      if (isCompanySize) {
        const companySizes = _.sortBy(data, ['size']);
        setSourcesToShow(companySizes);
        if (defaultSelected) {
          const item = data?.find((elem) => elem.id === defaultSelected);

          if (item) {
            setCurrentValue(item);
            setMultiselect(item);
          }
        }
      } else {
        const uniqueSources = _.uniqBy(_.filter(data, 'name'), 'id');
        const sortedSources = _.sortBy(uniqueSources, (source) =>
          source.name.toLowerCase()
        );
        setSourcesToShow(sortedSources);
      }
    }
  }, [defaultSelected, data, isCompanySize]);

  function filterElements(inputValue: string) {
    const filtered = data.filter((elem: { name: string }) =>
      elem.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSourcesToShow(filtered.length ? filtered : []);
  }

  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  };

  useEventListener('click', handleClick);

  function getDataAttribute() {
    switch (placeholder) {
      case 'lang':
        return 'name';
      case 'country':
        return 'name';
      case 'currency':
        return 'char_code';
      default:
        return 'name';
    }
  }

  function getPlacehoderMultiselect() {
    switch (placeholder) {
      case 'lang':
        return t('Choose your language');
      case 'country':
        return t('Choose your Country');
      case 'currency':
        return t('Choose Currency');
      default:
        return '';
    }
  }

  function getPlacehoderDropdown() {
    switch (placeholder) {
      case 'lang':
        return t('Enter language');
      case 'country':
        return t('Enter Country');
      case 'currency':
        return t('Enter Currency');
      default:
        return t('Enter element');
    }
  }

  const firstUp = (str: string) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (defaultSelected) {
      const item = data?.find((elem) => elem.id === defaultSelected);

      if (item) {
        setCurrentValue(item);
        setMultiselect(item);
      }
    }
  }, [defaultSelected, inputValue]);

  useEffect(() => {
    if (inputValue) {
      filterElements(inputValue);
    } else {
      setSourcesToShow(data);
    }
  }, [inputValue]);

  useEffect(() => {
    if (!currentValue && !defaultSelected) {
      setIsPlaceholderVisible(true); // Show placeholder, if not value
    } else {
      setIsPlaceholderVisible(false); // Hidden placeholder, if have selected value
    }
  }, [currentValue, defaultSelected]);

  function getDefaultValueMultiselect() {
    if (!currentValue && !defaultSelected) {
      return '';
    }

    if (langSelect) {
      return firstUp(currentValue?.lang_code || '');
    }

    if (!currentValue) {
      if (isCurrency) {
        return (
          data?.find((elem) => elem.id === defaultSelected)?.char_code || ''
        );
      } else
        return data?.find((elem) => elem.id === defaultSelected)?.name || '';
    } else {
      if (currentValue.char_code && isCurrency) return currentValue.char_code;

      if (currentValue['description']) {
        return `${currentValue[getDataAttribute()]} (${
          currentValue['description']
        })`;
      }

      return currentValue[getDataAttribute()];
    }
  }

  return {
    ref,
    setOpen,
    open,
    currentValue,
    sourcesToShow,
    inputValue,
    setInputValue,
    t,
    getPlacehoderDropdown,
    getDefaultValueMultiselect,
    getPlacehoderMultiselect,
    isPlaceholderVisible,
    setCurrentValue,
    getDataAttribute,
  };
};
