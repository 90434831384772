import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { authStore } from '../stores/authStore';
import { ShowNotification } from 'tools/showNotification';
import { HashFromString } from 'tools/hashFromString';
import i18n from 'i18n/i18n';
import { userStore } from '../stores/userStore';
import i18next from 'i18next';

export const API_URL = process.env.REACT_APP_API || '';

const api = axios.create({
  baseURL: API_URL,
  headers: { 'Request-Origin': 'Platform-Frontend' },
});

export const getAccessToken = (): string | null =>
  Cookies.get('access') || localStorage.getItem('access');

export const getRefreshToken = (): string | null =>
  Cookies.get('refresh') || localStorage.getItem('refresh');

export const setAccessToken = (token: string): void => {
  if (token === '') {
    Cookies.remove('access');
    localStorage.removeItem('access');
  } else {
    Cookies.set('access', token, { sameSite: 'strict', expires: 365 });
    localStorage.setItem('access', token);
  }
};

export const setRefreshToken = (token: string): void => {
  if (token === '') {
    Cookies.remove('refresh');
    localStorage.removeItem('refresh');
  } else {
    Cookies.set('refresh', token, { sameSite: 'strict', expires: 365 });
    localStorage.setItem('refresh', token);
  }
};

api.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  config.headers['Platform-Language'] = i18n.language;

  if (process.env.REACT_APP_PLATFORM_TYPE) {
    config.headers['Platform-Type'] = process.env.REACT_APP_PLATFORM_TYPE;
  }

  return config;
});

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    const urlHash = await HashFromString({ string: originalRequest.url });
    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(`${API_URL}/refresh/`, {
          refresh: getRefreshToken(),
        });
        setAccessToken(response.data.access);
        originalRequest.headers.Authorization = `Bearer ${getAccessToken()}`;
        return await api.request(originalRequest);
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          if (e?.response?.status === 401 || e?.response?.status === 400) {
            setAccessToken('');
            setRefreshToken('');
            authStore.setAuth(false);
            authStore.setServerError(false);
            return;
          } else {
            const errorMessage =
              error?.message ||
              i18next.t('Unknown error. Please, try again later');
            ShowNotification({
              type: 'error',
              children: errorMessage,
              toastProps: { toastId: urlHash },
            });
            throw error;
          }
        }
      }
    } else if (error.response?.status === 400) {
      let errorMessage = 'Bad Request';

      if (error.response?.data) {
        if (error.response.data?.form_errors) {
          return Promise.reject(error);
        } else {
          const errorMessages = Object.values(error.response.data as object)
            .flat()
            .map((msg: string) => {
              if (msg?.length) {
                return msg?.replace(/^\['(.+)'\]$/, '$1'); // Удаляем "['" и "']" с каждой стороны строки
              }
            });
          errorMessage = errorMessages?.length
            ? errorMessages?.join(', ')
            : i18next.t('Unknown error. Please, try again later');
          ShowNotification({
            type: 'error',
            children: errorMessage,
            toastProps: { toastId: urlHash },
          });
        }
      }
      throw error;
    } else if (error?.response?.status >= 500) {
      authStore.setServerError(true);
      userStore.isComplete = true;
    } else if (
      (error?.response?.status < 500 || !error?.response?.status) &&
      (error?.message?.includes('ERR_CONNECTION_RESET') ||
        error?.code?.includes('ERR_CONNECTION_RESET') ||
        error?.message?.includes('ERR_NETWORK') ||
        error?.code?.includes('ERR_NETWORK'))
    ) {
      ShowNotification({
        type: 'error',
        children: i18next.t('Connection reset detected, retrying...'),
        toastProps: { toastId: urlHash },
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(api(error.config));
        }, 10000);
      });
    } else if (!error.response) {
      ShowNotification({
        type: 'error',
        children: i18next.t('Unknown error. Please, try again later'),
        toastProps: { toastId: urlHash },
      });
    }
    throw error;
  }
);

export default api;
