import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useAnchorScroll(position?: ScrollLogicalPosition) {
  const location = useLocation();
  const [currentAnchor, setCurrentAnchor] = useState<string>(
    location.hash || ''
  );

  useEffect(() => {
    setCurrentAnchor(location.hash || '');
  }, [location.hash]);

  useEffect(() => {
    const targetElement = document.getElementById(currentAnchor?.slice(1));
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: position || 'center',
      });
    }
  }, [currentAnchor]);

  return { currentAnchor, setCurrentAnchor };
}
