import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { Notice } from 'components/shared//Notice';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useRef } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import classes from './Business.module.scss';
import { BusinessProps } from './Business.props';
import { useBusiness } from './useBusiness';
import { FormBusinessInformationValues } from 'types/forms';
import BusinessFooter from './BusinessFooter/BusinessFooter';
import BusinessHeader from './BusinessHeader/BusinessHeader';
import { CLIENT_ROLE, CompanySizeDictionary } from 'types/user';
import { SelectNew } from 'components/shared/SelectNew/SelectNew';
import { OptionTypeString } from 'types/util-types';
import { BusinessSelectedType, CompanySizeType } from 'types/solutions';
import { userStore } from 'stores/userStore';
import { toJS } from 'mobx';

export const Business = ({
  defaultValue,
  getParams,
  client,
}: BusinessProps) => {
  const formRef = useRef(null);
  const {
    t,
    handleSubmit,
    submit,
    errors,
    setCompanyName,
    companyName,
    companyId,
    control,
    setCompanyLink,
    companyLink,
    setSelectedBusinessType,
    businessTypes,
    setSelectedCompanySize,
    notice,
    handleCopy,
  } = useBusiness(defaultValue, getParams);

  const companySizesDictionary = toJS(userStore).companySizesDictionary;

  return (
    <>
      <TitleAndDescription pageName="Account/business" />

      <div className={classes.wrapper}>
        <BusinessHeader client={client} />

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(submit)}
        >
          <div className={classes.box}>
            <Controller
              name="companyName"
              control={control}
              render={({
                field,
              }: {
                field: ControllerRenderProps<
                  FormBusinessInformationValues,
                  'companyName'
                >;
              }) => (
                <Input
                  bigInput
                  inputLabel={true}
                  onChange={(e) => {
                    setCompanyName(e);
                    field.onChange(e);
                  }}
                  type={'text'}
                  placeholder={t('Company Name')}
                  value={companyName || field.value}
                  disabled
                  isBackground
                  error={!!errors.companyName}
                  className={classes.oneInput}
                />
              )}
            />
            {errors?.companyName && (
              <TooltipContainer
                text={errors.companyName?.message}
                customClasses={'kit-ui-block'}
                position={'top'}
                className={classes.error}
              >
                <InfoIcon fill="#F5222D" width="18px" height="18px" />
              </TooltipContainer>
            )}

            <div className={classes['two-inputs']}>
              <div className={classes['two-inputs--input']}>
                <Controller
                  name="companyId"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<
                      FormBusinessInformationValues,
                      'companyId'
                    >;
                  }) => (
                    <Input
                      bigInput
                      inputLabel={true}
                      onChange={(e) => {
                        setCompanyName(e);
                        field.onChange(e);
                      }}
                      type={'text'}
                      placeholder={t('Company ID')}
                      value={
                        defaultValue.bitrix_id?.toString() ||
                        companyId ||
                        field.value ||
                        ''
                      }
                      disabled
                      isBackground
                      error={!!errors.companyId}
                      helperText={t(
                        'Helps uniquely recognize your company, reducing data handling errors and avoiding confusion with other entities'
                      )}
                      className={classes.mainInput}
                    />
                  )}
                />
                {errors?.companyName && (
                  <TooltipContainer
                    text={errors.companyName?.message}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </div>

              <div className={classes['two-inputs--input']}>
                <Controller
                  name="url"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<
                      FormBusinessInformationValues,
                      'url'
                    >;
                  }) => (
                    <Input
                      bigInput
                      inputLabel={true}
                      onChange={(e) => {
                        setCompanyLink(e);
                        field.onChange(e);
                      }}
                      type={'text'}
                      placeholder={t('Company website')}
                      value={field.value || companyLink}
                      disabled
                      isBackground
                      error={!!errors.url}
                    />
                  )}
                />
                {errors?.url && (
                  <TooltipContainer
                    text={errors.url?.message}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </div>
            </div>

            <Controller
              name="size"
              control={control}
              render={({ field }) => (
                <SelectNew
                  select={companySizesDictionary.find(
                    (item: CompanySizeDictionary) => item.type === field.value
                  )}
                  isShowDescription
                  className={classes.selectSize}
                  title={t('Company size')}
                  placeholder={t('Select company size')}
                  options={companySizesDictionary}
                  onSelect={(item: any) => {
                    field.onChange(item.type);
                    setSelectedCompanySize(item as CompanySizeType);
                  }}
                  isSolutionForm
                  disabled
                  endIcon={{
                    endIconName: 'QuestionIcon',
                    endIconTooltipPosition: 'top',
                    endIconTooltipText:
                      'Helps in categorizing your company for tailored services and offers ',
                    endIconTooltipClassName: classes.helperTextTooltip,
                    endIconClassName: classes.questionIcon,
                  }}
                  isType
                />
              )}
            />

            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <SelectNew
                  select={businessTypes.find(
                    (item) => String(item.id) === field.value
                  )}
                  className={classes.selectSize}
                  title={t('Company industry')}
                  placeholder={t('Company industry')}
                  options={
                    businessTypes.map((type) => ({
                      ...type,
                      id: String(type.id),
                    })) as OptionTypeString[]
                  }
                  onSelect={(item: OptionTypeString) => {
                    field.onChange(item.id);
                    setSelectedBusinessType(
                      item as unknown as BusinessSelectedType
                    );
                  }}
                  isSolutionForm
                  disabled
                  endIcon={{
                    endIconName: 'QuestionIcon',
                    endIconTooltipPosition: 'top',
                    endIconTooltipText: `Ensures that communications, offers, and services are relevant to your company's field`,
                    endIconTooltipClassName: classes.helperTextTooltip,
                    endIconClassName: classes.questionIcon,
                  }}
                />
              )}
            />
          </div>

          {/* {!RUSSIAN_LOCALE && (
            <div className={classes.submit}>
              <Button
                className={classes.button}
                theme="primary"
                disabled={disableSubmit || !isValid}
                size="middle"
                type="submit"
              >
                {t('Save changes')}
              </Button>
            </div>
          )} */}
        </form>

        {client?.legal_entities?.length &&
        client?.permission?.type === CLIENT_ROLE.ADMIN ? (
          <BusinessFooter client={client} handleCopy={handleCopy} />
        ) : null}

        {notice.type !== '' && (
          <div className={classes.notice}>
            <Notice type={notice.type}>{notice.text}</Notice>
          </div>
        )}
      </div>
    </>
  );
};
