import classes from './SearchComponent.module.scss';

const CustomText = ({ text, search }: { text: string; search: string }) => {
  const trimmedText = text.trim();
  const searchQueries =
    (search !== '&' && search.split('&').map((q) => q.trim())) || [];
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  if (searchQueries?.length) {
    searchQueries.forEach((query) => {
      const queryIndex = trimmedText
        .toLocaleUpperCase()
        .indexOf(query.toLocaleUpperCase(), lastIndex);

      if (queryIndex !== -1) {
        if (queryIndex > lastIndex) {
          parts.push(trimmedText.slice(lastIndex, queryIndex));
        }

        parts.push(
          <span key={queryIndex} className={classes['search__search-text']}>
            {trimmedText.slice(queryIndex, queryIndex + query.length)}
          </span>
        );

        lastIndex = queryIndex + query.length;
      }
    });
  } else {
    const queryIndex = trimmedText
      .toLocaleUpperCase()
      .indexOf(search.toLocaleUpperCase(), lastIndex);

    if (queryIndex !== -1) {
      if (queryIndex > lastIndex) {
        parts.push(trimmedText.slice(lastIndex, queryIndex));
      }

      parts.push(
        <span key={queryIndex} className={classes['search__search-text']}>
          {trimmedText.slice(queryIndex, queryIndex + search.length)}
        </span>
      );

      lastIndex = queryIndex + search.length;
    }
  }

  if (lastIndex < trimmedText.length) {
    parts.push(trimmedText.slice(lastIndex));
  }

  return <div>{parts}</div>;
};

export default CustomText;
