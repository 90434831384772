import { Request } from 'tools/request';

interface Props {
  access: string;
  refresh: string;
  projectID: string;
  workspaceId?: string;
  reportID?: string;
}

export async function GetDashboardParams({
  access,
  refresh,
  projectID,
  workspaceId,
  reportID,
}: Props) {
  let url = `${process.env.REACT_APP_API}/api/v1/dashboards/params/?project_id=${projectID}`;
  if (process.env.REACT_APP_PLATFORM_TYPE === 'ru') {
    url = url + `&report_id=${reportID}`;
  } else {
    url = url + `&workspace_id=${workspaceId}`;
  }
  const result = await Request({
    type: 'GET',
    url: url,
    isProtected: true,
  });

  return result;
}
