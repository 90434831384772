import * as React from 'react';
import { SVGProps } from 'react';

export const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_4797_521)">
      <path
        d="M7.00005 9.16673C7.28635 9.54948 7.65162 9.86618 8.07109 10.0954C8.49055 10.3245 8.95439 10.4608 9.43115 10.4949C9.90791 10.5291 10.3864 10.4603 10.8343 10.2932C11.2821 10.1262 11.6888 9.86477 12.0267 9.52673L14.0267 7.52673C14.6339 6.89805 14.9699 6.05604 14.9623 5.18205C14.9547 4.30807 14.6041 3.47202 13.9861 2.854C13.3681 2.23597 12.532 1.88541 11.6581 1.87781C10.7841 1.87022 9.94206 2.2062 9.31339 2.81339L8.16672 3.95339M9.66672 7.83339C9.38042 7.45064 9.01515 7.13394 8.59569 6.90477C8.17622 6.67559 7.71238 6.53931 7.23562 6.50517C6.75886 6.47103 6.28033 6.53981 5.83249 6.70687C5.38465 6.87393 4.97798 7.13535 4.64005 7.47339L2.64005 9.47339C2.03286 10.1021 1.69688 10.9441 1.70447 11.8181C1.71207 12.6921 2.06263 13.5281 2.68066 14.1461C3.29868 14.7641 4.13473 15.1147 5.00871 15.1223C5.8827 15.1299 6.72471 14.7939 7.35339 14.1867L8.49339 13.0467"
        stroke="#F14B4B"
        stroke-width="1.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4797_521">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.333374 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
