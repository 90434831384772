export const useFilters = () => {
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    callback: (value: string) => void
  ) => {
    if (event.key === 'Enter') {
      callback((event.target as HTMLInputElement).value);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    callback: (value: string) => void
  ) => {
    callback((event.target as HTMLInputElement).value);
  };

  return { handleKeyDown, handleBlur };
};
