import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const LockGradientIcon = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = React.useId();

  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        {RUSSIAN_LOCALE ? (
          <linearGradient id={gradientId} gradientTransform="rotate(150)">
            <stop offset="8.91%" stopColor="#e47267" />
            <stop offset="93.43%" stopColor="#eb5945" />
          </linearGradient>
        ) : (
          <linearGradient id={gradientId} gradientTransform="rotate(140)">
            <stop offset="0%" stopColor="#6e3cff" />
            <stop offset="100%" stopColor="#4ba5ff" />
          </linearGradient>
        )}
      </defs>

      <path
        d="M21 7V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.71569 20.2843 1.40973 19.908 1.21799C19.4802 1 18.9201 1 17.8 1H4.2C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V7.8C1 8.9201 1 9.48016 1.21799 9.90798C1.40973 10.2843 1.71569 10.5903 2.09202 10.782C2.51984 11 3.0799 11 4.2 11H10M11 6H11.005M16 6H16.005M6 6H6.005M18.25 13V11.25C18.25 10.2835 17.4665 9.5 16.5 9.5C15.5335 9.5 14.75 10.2835 14.75 11.25V13M11.25 6C11.25 6.13807 11.1381 6.25 11 6.25C10.8619 6.25 10.75 6.13807 10.75 6C10.75 5.86193 10.8619 5.75 11 5.75C11.1381 5.75 11.25 5.86193 11.25 6ZM16.25 6C16.25 6.13807 16.1381 6.25 16 6.25C15.8619 6.25 15.75 6.13807 15.75 6C15.75 5.86193 15.8619 5.75 16 5.75C16.1381 5.75 16.25 5.86193 16.25 6ZM6.25 6C6.25 6.13807 6.13807 6.25 6 6.25C5.86193 6.25 5.75 6.13807 5.75 6C5.75 5.86193 5.86193 5.75 6 5.75C6.13807 5.75 6.25 5.86193 6.25 6ZM14.6 17H18.4C18.9601 17 19.2401 17 19.454 16.891C19.6422 16.7951 19.7951 16.6422 19.891 16.454C20 16.2401 20 15.9601 20 15.4V14.6C20 14.0399 20 13.7599 19.891 13.546C19.7951 13.3578 19.6422 13.2049 19.454 13.109C19.2401 13 18.9601 13 18.4 13H14.6C14.0399 13 13.7599 13 13.546 13.109C13.3578 13.2049 13.2049 13.3578 13.109 13.546C13 13.7599 13 14.0399 13 14.6V15.4C13 15.9601 13 16.2401 13.109 16.454C13.2049 16.6422 13.3578 16.7951 13.546 16.891C13.7599 17 14.0399 17 14.6 17Z"
        stroke={`url(#${gradientId})`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
