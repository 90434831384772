import React from 'react';
import classes from './Cases.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Case } from 'types/solutions';
import SingleCase from './SingleCase';

interface CasesProps {
  data: Case[];
}

export const Cases = observer(({ data }: CasesProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('Cases')}</div>
      <div className={classes.cases}>
        {data?.map((item: Case) => (
          <SingleCase singleCase={item} key={item?.id} t={t} i18n={i18n} />
        ))}
      </div>
    </div>
  );
});
