import { useUserStore } from 'contexts/UserContext';
import { cartStore } from 'stores/cartStore';
import { ICartData } from 'types';

export const useTotal = (selectedItems: ICartData[], total: number) => {
  const userStore = useUserStore();

  const selectedBPS = cartStore.selectedBPS;
  const currentCurrency = userStore.user.currency.char_code;
  const currentCurrencySymbol = userStore.user.currency.symbol;

  const couponDiscount = cartStore.cart?.basket_project_service?.reduce(
    (accumulator: number, item: ICartData) => {
      const serviceIds = selectedItems.map(
        (item: ICartData) => item.service.id
      );
      if (serviceIds.includes(item.service.id)) {
        return Number(accumulator) + Number(item.total_coupon_discount_value);
      }
      return accumulator;
    },
    0
  );

  const totalWithDiscounts = selectedBPS?.reduce(
    (accumulator: number, item: ICartData) => {
      const realCost = item.service?.is_free
        ? 0
        : Number(item.total_cost_with_discounts);
      return accumulator + realCost;
    },
    0
  ) as number;

  const realDiscount = Number(~~total) - Number(~~totalWithDiscounts);

  return {
    currentCurrencySymbol,
    currentCurrency,
    realDiscount,
    totalWithDiscounts,
    couponDiscount,
  };
};
