import { LocalPreferencesForm } from 'components/common/Header/LocalPreferences';
import { languages } from 'constants/languages';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { LABELS } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import 'containers/CardsContainer/IntroTooltip.css';
import classes from './LocalePageContainer.module.scss';
import { useLocale } from './useLocale';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';

export const LocalePageContainer = () => {
  const {
    userStore,
    t,
    handleSaveLocalPreferences,
    countries,
    currencies,
    setIsDisabled,
    isDisabled,
    i18n,
    userCurrency,
    userCountry,
  } = useLocale();

  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const isMobile = useWindowWidth().isSmallTablet;

  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'LOCALE';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('LOCALE').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <div className={classes.container}>
      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <h2 className={classes.title}>
        {t('Please, confirm your local preferences')}
      </h2>

      {userStore.user && (
        <div className={classes.preference}>
          <LocalPreferencesForm
            onClose={() => {}}
            onSave={handleSaveLocalPreferences}
            noticeText={t(
              'Local settings affect the interface language and prices you see on the platform'
            )}
            onlySaveButton={true}
            disabled={isDisabled}
            setIsDisabled={setIsDisabled}
            countries={countries}
            currencies={currencies}
            languages={languages}
            currentCountry={userCountry}
            currentCurrency={userCurrency}
            currentLanguage={
              languages.find((item) => item?.lang_code === i18n.language)!
            }
          />
        </div>
      )}
    </div>
  );
};
