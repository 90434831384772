import * as React from 'react';
import { SVGProps } from 'react';

export const PurpleEmptyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      data-color="svg-fill-stroke"
      d="M5.78725 3.69501C7.03433 2.86175 8.50049 2.41699 10.0003 2.41699C10.9962 2.41699 11.9823 2.61314 12.9023 2.99424C13.8224 3.37534 14.6584 3.93392 15.3626 4.6381C16.0667 5.34228 16.6253 6.17826 17.0064 7.09831C17.3875 8.01836 17.5837 9.00447 17.5837 10.0003C17.5837 11.5002 17.1389 12.9663 16.3056 14.2134C15.4724 15.4605 14.288 16.4325 12.9023 17.0064C11.5167 17.5804 9.99192 17.7306 8.5209 17.4379C7.04987 17.1453 5.69865 16.4231 4.6381 15.3626C3.57755 14.302 2.85531 12.9508 2.56271 11.4798C2.2701 10.0087 2.42028 8.48398 2.99424 7.09831C3.56821 5.71264 4.54018 4.52828 5.78725 3.69501Z"
      strokeWidth="1.5"
    />
  </svg>
);
