import { ServiceDetailContainer } from 'containers/ServiceDetail';
import 'styles/main.scss';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { NotFoundPage } from './NotFoundPage';

// todo add localization feature
export const ServiceDetail = () => {
  const marketPlaceStore = useMarketPlaceStore();
  const { isErrorServices } = marketPlaceStore || {};

  return !isErrorServices ? <ServiceDetailContainer /> : <NotFoundPage />;
};
