import * as React from 'react';
import { SVGProps } from 'react';

export const AttentionIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill="url(#paint0_linear_5064_1626)" />
    <path
      d="M14.7359 6.85899L17.856 7.05845L17.0307 18.783L14.0615 18.5932L14.7359 6.85899ZM13.3131 22.7917C13.345 22.2933 13.5569 21.8767 13.9488 21.542C14.3576 21.2084 14.8303 21.0588 15.3671 21.0931C15.9039 21.1274 16.3538 21.336 16.7167 21.719C17.0796 22.1019 17.2452 22.5426 17.2133 23.041C17.1814 23.5395 16.9611 23.9555 16.5524 24.2891C16.1437 24.6227 15.6709 24.7724 15.1341 24.7381C14.6141 24.7048 14.1726 24.4967 13.8097 24.1138C13.4468 23.7309 13.2812 23.2902 13.3131 22.7917Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5064_1626"
        x1="2.22027e-07"
        y1="2"
        x2="32"
        y2="27.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BC6EF3" stop-opacity="0.4" />
        <stop offset="1" stop-color="#BC6EF3" />
      </linearGradient>
    </defs>
  </svg>
);
