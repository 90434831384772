import { CLIENT_LEVEL } from 'constants/enums';
import * as React from 'react';
import { SVGProps } from 'react';

type LoyaltyIconProps = {
  level: CLIENT_LEVEL;
} & SVGProps<SVGSVGElement>;

export const LoyaltyIcon = ({ level, ...props }: LoyaltyIconProps) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {(level === CLIENT_LEVEL.SIGNATURE || level === CLIENT_LEVEL.GOLD) && (
      <>
        <g clip-path="url(#clip0_3203_65993)">
          <path
            d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
            fill="url(#paint0_linear_3203_65993)"
          />
          <path
            d="M13.6006 23.7928C13.3244 23.7928 13.1006 23.569 13.1006 23.2928V21.1472C13.1006 20.8711 13.3244 20.6472 13.6006 20.6472H20.1136C20.3897 20.6472 20.6136 20.8711 20.6136 21.1472V23.2928C20.6136 23.569 20.3897 23.7928 20.1136 23.7928H13.6006ZM13.6006 27.1204C13.3244 27.1204 13.1006 26.8965 13.1006 26.6204V25.9046C13.1006 25.6285 13.3244 25.4046 13.6006 25.4046H21.5174C21.7935 25.4046 22.0174 25.6285 22.0174 25.9046V26.6204C22.0174 26.8965 21.7935 27.1204 21.5174 27.1204H13.6006ZM15.5243 29.98C15.2482 29.98 15.0243 29.7561 15.0243 29.48V12.0225C15.0243 11.7463 15.2482 11.5225 15.5243 11.5225H21.7574C22.6586 11.5225 23.4645 11.6958 24.1751 12.0424C24.903 12.389 25.5183 12.8569 26.0209 13.4462C26.5408 14.0355 26.9394 14.694 27.2167 15.4219C27.494 16.1498 27.6326 16.8951 27.6326 17.6576C27.6326 18.6975 27.3987 19.6854 26.9307 20.6212C26.4801 21.5571 25.8302 22.3197 24.981 22.9089C24.1318 23.4982 23.1092 23.7928 21.9134 23.7928H19.0859C18.8097 23.7928 18.5859 24.0167 18.5859 24.2928V29.48C18.5859 29.7561 18.362 29.98 18.0859 29.98H15.5243ZM18.5859 20.1472C18.5859 20.4234 18.8097 20.6472 19.0859 20.6472H21.6794C22.2167 20.6472 22.6586 20.5259 23.0053 20.2833C23.3519 20.0407 23.6032 19.694 23.7592 19.2434C23.9325 18.7755 24.0191 18.2469 24.0191 17.6576C24.0191 17.0164 23.9151 16.4705 23.7072 16.0199C23.5165 15.5693 23.2306 15.2313 22.8493 15.006C22.4853 14.7807 22.0434 14.668 21.5235 14.668H19.0858C18.8097 14.668 18.5859 14.8919 18.5859 15.168V20.1472Z"
            fill="url(#paint1_linear_3203_65993)"
          />
          <circle
            cx="19.91"
            cy="20.4386"
            r="16.3972"
            transform="rotate(12.525 19.91 20.4386)"
            stroke="url(#paint2_linear_3203_65993)"
            stroke-width="2"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_3203_65993"
            x1="4.88013e-08"
            y1="-0.5"
            x2="33"
            y2="40.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFF8BB" stop-opacity="0.4" />
            <stop offset="1" stop-color="#FFE16A" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3203_65993"
            x1="13.1006"
            y1="11.061"
            x2="28.6844"
            y2="26.305"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.6" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3203_65993"
            x1="2.51282"
            y1="2.17159"
            x2="31.2181"
            y2="37.8358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.6" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <clipPath id="clip0_3203_65993">
            <path
              d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </>
    )}
    {level === CLIENT_LEVEL.PLATINUM && (
      <>
        <g clip-path="url(#clip0_5904_129628)">
          <path
            d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
            fill="url(#paint0_linear_5904_129628)"
          />
          <path
            d="M13.6006 23.7928C13.3244 23.7928 13.1006 23.569 13.1006 23.2928V21.1472C13.1006 20.8711 13.3244 20.6472 13.6006 20.6472H20.1136C20.3897 20.6472 20.6136 20.8711 20.6136 21.1472V23.2928C20.6136 23.569 20.3897 23.7928 20.1136 23.7928H13.6006ZM13.6006 27.1204C13.3244 27.1204 13.1006 26.8965 13.1006 26.6204V25.9046C13.1006 25.6285 13.3244 25.4046 13.6006 25.4046H21.5174C21.7935 25.4046 22.0174 25.6285 22.0174 25.9046V26.6204C22.0174 26.8965 21.7935 27.1204 21.5174 27.1204H13.6006ZM15.5243 29.98C15.2482 29.98 15.0243 29.7561 15.0243 29.48V12.0225C15.0243 11.7463 15.2482 11.5225 15.5243 11.5225H21.7574C22.6586 11.5225 23.4645 11.6958 24.1751 12.0424C24.903 12.389 25.5183 12.8569 26.0209 13.4462C26.5408 14.0355 26.9394 14.694 27.2167 15.4219C27.494 16.1498 27.6326 16.8951 27.6326 17.6576C27.6326 18.6975 27.3987 19.6854 26.9307 20.6212C26.4801 21.5571 25.8302 22.3197 24.981 22.9089C24.1318 23.4982 23.1092 23.7928 21.9134 23.7928H19.0859C18.8097 23.7928 18.5859 24.0167 18.5859 24.2928V29.48C18.5859 29.7561 18.362 29.98 18.0859 29.98H15.5243ZM18.5859 20.1472C18.5859 20.4234 18.8097 20.6472 19.0859 20.6472H21.6794C22.2167 20.6472 22.6586 20.5259 23.0053 20.2833C23.3519 20.0407 23.6032 19.694 23.7592 19.2434C23.9325 18.7755 24.0191 18.2469 24.0191 17.6576C24.0191 17.0164 23.9151 16.4705 23.7072 16.0199C23.5165 15.5693 23.2306 15.2313 22.8493 15.006C22.4853 14.7807 22.0434 14.668 21.5235 14.668H19.0858C18.8097 14.668 18.5859 14.8919 18.5859 15.168V20.1472Z"
            fill="url(#paint1_linear_5904_129628)"
          />
          <circle
            cx="19.9095"
            cy="20.4386"
            r="16.3972"
            transform="rotate(12.525 19.9095 20.4386)"
            stroke="url(#paint2_linear_5904_129628)"
            stroke-width="2"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_5904_129628"
            x1="4.88013e-08"
            y1="-0.5"
            x2="33"
            y2="40.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4D4D4D" stop-opacity="0.4" />
            <stop offset="1" stop-color="#272727" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5904_129628"
            x1="13.1006"
            y1="11.061"
            x2="28.6844"
            y2="26.305"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.6" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5904_129628"
            x1="2.51233"
            y1="2.17159"
            x2="31.2177"
            y2="37.8358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.6" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
          <clipPath id="clip0_5904_129628">
            <path
              d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </>
    )}
  </svg>
);
