import { pagesTitlesAndDescriptions } from 'constants/titleAndDescriptionConstants';
import { useTranslation } from 'react-i18next';

// A function that uses a textarea element to decode HTML entities into a string. This approach ensures the correct display of characters such as &, ', ", /, and others in the title and meta tags.
const decodeHtmlEntity = (str: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
};

export const useTitleAndDescription = (pageName: string, title?: string) => {
  const { t, i18n } = useTranslation();

  const pageDetails = pagesTitlesAndDescriptions.find(
    (el) => el.page === pageName
  );
  const decodedTitle = decodeHtmlEntity(
    t(pageDetails?.title || '', { title: title })
  );
  const decodedDescription = decodeHtmlEntity(
    t(pageDetails?.description || '')
  );

  return { i18n, decodedTitle, decodedDescription };
};
