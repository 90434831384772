import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Filters } from 'components/common/Filters';
import UiIcon from 'components/shared/Icon';
import { FilterIcon } from 'components/shared/Icons/FilterIcon';
import { MobileFilters } from 'components/common/MobileFilters';
import { Modal } from 'components/shared/Modal';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { LABELS } from 'constants/onboarding';
import { RecommendedService } from 'containers/RecommendedService';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import classes from './CardsContainer.module.scss';
import { HotDealsCounter } from './HotDeals/HotDealsCounter';
import './IntroTooltip.css';
import { ProductList } from './ProductList';
import { useCardsContainer } from './useCardContainer';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';

export const CardsContainer = observer(({ title }: { title: string }) => {
  const {
    businessTypes,
    setBusinessTypes,
    isFavoritesPage,
    budget,
    priceMin,
    setPriceMin,
    t,
    pageName,
    serviceTypes,
    setServiceTypes,
    serviceTypesOfMarketPlace,
    businessTypesOfMarketplace,
    setBudget,
    sortSelect,
    topFilters,
    pageSize,
    setProductsToShow,
    setCurrentPage,
    setIsFirstRender,
    setClearBusinessTypes,
    clearBusinessTypes,
    setIsHot,
    setIsNew,
    setIsFree,
    isFirstRender,
    currentPage,
    productsToShow,
    setIsTopUp,
    setIsSale,
    chunkSize,
    setSortSelect,
    setPageSize,
    moreButtonClicked,
    setMoreButtonClicked,
    seeMorePage,
    setSeeMorePage,
    clearSeeMore,
  } = useCardsContainer(title);

  const { isMediaTablet } = useWindowWidth();
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const isLoadingSolutions = marketPlaceStore.isLoadingServicePages;
  const isLoadingService = marketPlaceStore.isLoadingServices;
  const { count, maxCost, allCount, favoriteCount } = marketPlaceStore || {};

  const [showFilters, setShowFilters] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);

  const onboardingPath = 'SOLUTIONS';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('SOLUTIONS').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  const stepsCondition =
    !isMobile && onboarding && !isLoadingSolutions && !isLoadingService;

  return (
    <>
      <TitleAndDescription pageName={pageName} />

      {stepsCondition ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
            scrollToElement: true,
            scrollPadding: 250,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}
      <div className={clsx(classes.search__container)}></div>

      <div className={clsx(classes.wrapper)}>
        {isFavoritesPage && !favoriteCount ? (
          <div className={classes['zero-favorites']}>
            <div className={classes['zero-favorites__inner']}>
              <div className={classes['zero-favorites__title']}>
                <Type
                  tag="h2"
                  size="lg"
                  className={classes['zero-favorites__text']}
                >
                  {t('No favorite solutions yet?')}
                </Type>
              </div>

              <div className={classes['zero-favorites__subtitle']}>
                <Type
                  tag="h3"
                  size="simple"
                  className={classes['zero-favorites__text']}
                >
                  {t('Try finding something interesting in our catalog')}
                </Type>
              </div>

              <div className={classes['zero-favorites__button']}>
                <Button size="middle" theme="primary">
                  <NavLink
                    to="/solutions"
                    className={classes['zero-favorites__button']}
                  >
                    {t('Back to Solutions')}
                  </NavLink>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.head}>
              <div
                className={classes.title}
                key={'Some_kind_of_key_so_that_it_doesn’t_break_Google'}
              >
                {t(`${title}`)}
                <span>{allCount}</span>
              </div>

              {isMediaTablet && (
                <div className={classes.filters}>
                  <Button
                    onClick={() => setOpenSelect(true)}
                    theme="icon-default"
                  >
                    <UiIcon
                      name="FilterMobileTableIcon"
                      additionalClassName={classes.mobileSortIcon}
                    />
                  </Button>

                  <Button
                    onClick={() => setShowFilters(true)}
                    theme="icon-default"
                  >
                    <FilterIcon />
                  </Button>
                </div>
              )}
            </div>

            {isMediaTablet && (
              <Modal
                isOpen={showFilters}
                onClose={() => setShowFilters(false)}
                title={t('Filters')}
                filterMobile
                classNameForContent={classes.modalMobileFilters}
              >
                <MobileFilters
                  count={count}
                  serviceTypesChecks={serviceTypes}
                  setServiceTypes={setServiceTypes}
                  serviceTypesOf={serviceTypesOfMarketPlace}
                  businessTypesOf={businessTypesOfMarketplace}
                  businessTypesChecks={businessTypes}
                  setBusinessTypes={setBusinessTypes}
                  budget={budget}
                  priceMin={priceMin}
                  setPriceMin={setPriceMin}
                  maxCost={maxCost}
                  setBudget={setBudget}
                  isOpen={showFilters}
                  onClose={() => setShowFilters(false)}
                  setCurrentPage={setCurrentPage}
                  setProductsToShow={setProductsToShow}
                  pageSize={pageSize}
                  topFilters={topFilters}
                  isFavorites={isFavoritesPage}
                  sortSelect={sortSelect}
                  clearSeeMore={clearSeeMore}
                  clearBusinessTypes={clearBusinessTypes}
                  setClearBusinessTypes={setClearBusinessTypes}
                  setIsFirstRender={setIsFirstRender}
                />
              </Modal>
            )}

            <div className={classes.content}>
              <div className={classes.left}>
                <div data-step-solutions-2>
                  {!isMediaTablet && (
                    <Filters
                      count={count}
                      pageSize={pageSize}
                      setProductsToShow={setProductsToShow}
                      topFilters={topFilters}
                      setIsFirstRender={setIsFirstRender}
                      isLoading={isLoadingService}
                      serviceTypesChecks={serviceTypes}
                      setServiceTypes={setServiceTypes}
                      businessTypesChecks={businessTypes}
                      setBusinessTypes={setBusinessTypes}
                      serviceTypesOf={serviceTypesOfMarketPlace}
                      businessTypesOf={businessTypesOfMarketplace}
                      budget={budget}
                      priceMin={priceMin}
                      setPriceMin={setPriceMin}
                      maxCost={maxCost}
                      setBudget={setBudget}
                      setCurrentPage={setCurrentPage}
                      isFavorites={isFavoritesPage}
                      clearSeeMore={clearSeeMore}
                      setClearBusinessTypes={setClearBusinessTypes}
                      clearBusinessTypes={clearBusinessTypes}
                    />
                  )}
                </div>
              </div>

              <div className={classes.right}>
                <ProductList
                  isFirstRender={isFirstRender}
                  isLoading={isLoadingSolutions}
                  setIsFree={setIsFree}
                  setIsNew={setIsNew}
                  setIsHot={setIsHot}
                  setIsSale={setIsSale}
                  setIsTopUp={setIsTopUp}
                  data={productsToShow}
                  hotDeals={marketPlaceStore.dataTimeHotDeals}
                  nameSource={title}
                  defaultCurrentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  defaultServiceTypes={serviceTypes}
                  defaultBusinessTypes={businessTypes}
                  topFilters={topFilters}
                  isFavorites={isFavoritesPage}
                  defaultBudget={budget}
                  defaultPriceMin={priceMin}
                  // enableHints={enableHints as boolean}
                  sortSelect={sortSelect}
                  setSortSelect={setSortSelect}
                  openSelect={openSelect}
                  setOpenSelect={setOpenSelect}
                  defaultChunkSize={chunkSize}
                  setMoreButtonClicked={setMoreButtonClicked}
                  moreButtonClicked={moreButtonClicked}
                  setPriceMin={setPriceMin}
                  clearBusinessTypes={clearBusinessTypes}
                  setSeeMorePage={setSeeMorePage}
                  seeMorePage={seeMorePage}
                  clearSeeMore={clearSeeMore}
                />
              </div>
            </div>
          </div>
        )}

        {window.location.pathname === '/favorites' && (
          <RecommendedService
            // ids={recommendedServicesIds}
            nameSource="Favorites"
          />
        )}

        <HotDealsCounter />
      </div>
    </>
  );
});
