import clsx from 'clsx';
import { Portal } from 'components/common/Portal';
import { Button } from 'components/shared/Button';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import classes from './FeedbackModal.module.scss';
import { FeedbackModalProps } from './FeedbackModal.props';

export const FeedbackModal = ({
  children,
  onClose,
  isOpen,
}: FeedbackModalProps) => (
  <Portal selector={`.layout-container`}>
    <div className={clsx(classes.wrapper, isOpen && classes.open)}>
      <div className={classes.background} />

      <div className={classes.modal}>
        <Button
          theme="icon-default"
          onClick={onClose}
          className={classes.close}
        >
          <CrossIcon />
        </Button>

        <div className={classes.content}>{children}</div>
      </div>
    </div>
  </Portal>
);
