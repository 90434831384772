import api from './http';

export default class Search {
  static async getSearchResults(
    resource: string,
    searchTerm: string,
    page?: number,
    page_size?: number
  ) {
    return api.get(
      `/api/v1/search/?resource=${encodeURIComponent(resource)}&search=${encodeURIComponent(searchTerm)}${page ? `&page=${page}` : ''}${page_size ? `&page_size=${page_size}` : ''}`
    );
  }

  static async getFastSearchResults(searchTerm: string) {
    return api.get(
      `/api/v1/search/fast_search/?search=${encodeURIComponent(searchTerm)}`
    );
  }
}
