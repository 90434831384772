import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { EyeIcon } from 'components/shared/Icons/EyeIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { KrestIcon } from 'components/shared/Icons/KrestIcon';
import { SuccessIcon } from 'components/shared/Icons/SuccessIcon';
import { Input } from 'components/shared/Input';
import { Link } from 'components/shared/Link';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { PASS_MODE } from 'constants/enums';
import { languages } from 'constants/languages';
import logo from 'assets/img/logo.png';
import logo_ru from 'assets/img/logo_ru.svg';
import desktop from 'assets/img/Restore/desktop.png';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { toNbsp } from 'tools/toNbsp';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './NewPassword.module.scss';
import { useNewPassword } from './useNewPassword';

export const NewPasswordContainer = () => {
  const {
    showPassword,
    password,
    i18n,
    changeLanguage,
    t,
    handleSubmit,
    submit,
    errors,
    control,
    showChecks,
    step,
    passwordChecks,
    setShowCheks,
    setPassword,
    passwordRep,
    handleShowPassword,
    disableSubmit,
    isValid,
    setPasswordRep,
  } = useNewPassword();
  const [showRepeatCheck, setShowRepeatCheck] = useState(false);

  const { newPass, newPassConfirmation } = showPassword;

  return (
    <>
      <TitleAndDescription pageName="NewPassword" />
      <div className={classes.container}>
        <img
          src={RUSSIAN_LOCALE ? logo_ru : logo}
          alt=""
          className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
        />
        <div className={classes.left}>
          <img src={desktop} alt="" className={classes.image} />
        </div>

        <div className={classes.right}>
          {!RUSSIAN_LOCALE && (
            <div className={classes.lang}>
              <MultiselectComponent
                data={languages}
                langSelect={true}
                defaultSelected={
                  languages[
                    languages.findIndex(
                      (lang) => lang.lang_code === i18n.language
                    )
                  ].id
                }
                setMultiselect={changeLanguage}
                className={classes['multiselect-lang']}
              />
            </div>
          )}

          {step === 0 && (
            <>
              <div className={classes.title}>{t('Restore your password')}</div>
              <div className={classes.text}>{t('Create a new password')}</div>
              <form className={classes.form} onSubmit={handleSubmit(submit)}>
                <div className={classes['bottom-fields']}>
                  <div className={classes['input-field']}>
                    {showChecks && (
                      <div className={classes['password-check']}>
                        <div className={classes.title}>
                          {t('Password requirements')}
                        </div>
                        <div className={classes.checks}>
                          {passwordChecks.map(({ condition, text }, index) => (
                            <div className={classes.check} key={index}>
                              <div className={classes.icon}>
                                {password.length === 0 && (
                                  <CheckIcon fill="#A09CAC" />
                                )}
                                {condition && password.length >= 1 ? (
                                  <CheckIcon fill="#00C853" />
                                ) : (
                                  password.length > 0 && <KrestIcon />
                                )}
                              </div>
                              <div
                                className={clsx(
                                  classes.name,
                                  condition && password.length >= 1
                                    ? classes.correct
                                    : password.length > 0 && classes.wrong
                                )}
                              >
                                {text}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <Controller
                      render={({ field }) => (
                        <Input
                          label={t('New password')}
                          labelPosition={'left'}
                          type={newPass ? 'text' : 'password'}
                          placeholder={t('Enter new password')}
                          value={field.value}
                          onChange={(e) => {
                            setPassword(e);
                            field.onChange(e);
                          }}
                          className={classes.password}
                          error={!!errors.newPass}
                          onBlur={() => setShowCheks(false)}
                          onFocus={() => setShowCheks(true)}
                        />
                      )}
                      name="newPass"
                      control={control}
                    />
                    {errors.newPass && errors.newPass.type === 'server' ? (
                      <TooltipContainer
                        text={errors.newPass?.message}
                        customClasses={'kit-ui-block'}
                        position={'top'}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    ) : (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleShowPassword(PASS_MODE.NEW)}
                        className={classes['show-password']}
                      >
                        <EyeIcon width="19px" height="14px" />
                      </div>
                    )}
                  </div>

                  <div className={classes['input-field']}>
                    {showRepeatCheck && (
                      <div className={classes['repeat-check']}>
                        <div className={classes.title}>
                          {t('Password checking')}
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {passwordRep.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {!errors.newPassConfirmation &&
                            passwordRep.length > 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              passwordRep.length > 0 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              !errors.newPassConfirmation &&
                                passwordRep.length > 1 &&
                                classes.correct,
                              errors.newPassConfirmation &&
                                passwordRep.length > 0 &&
                                classes.wrong
                            )}
                          >
                            {errors.newPassConfirmation
                              ? errors.newPassConfirmation?.message
                              : t('Passwords match')}
                          </div>
                        </div>
                      </div>
                    )}

                    <Controller
                      render={({ field }) => (
                        <Input
                          label={t('Repeat password')}
                          labelPosition={'left'}
                          type={newPassConfirmation ? 'text' : 'password'}
                          placeholder={t('Repeat new password')}
                          value={field.value}
                          onChange={(e) => {
                            setPasswordRep(e);
                            field.onChange(e);
                          }}
                          className={classes.password}
                          error={!!errors.newPassConfirmation}
                          onBlur={() => setShowRepeatCheck(false)}
                          onFocus={() => setShowRepeatCheck(true)}
                        />
                      )}
                      name="newPassConfirmation"
                      control={control}
                    />
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => handleShowPassword(PASS_MODE.CONFIRM)}
                      onKeyDown={() => {}}
                      className={classes['show-password']}
                    >
                      <EyeIcon width="19px" height="14px" />
                    </div>
                  </div>
                </div>

                <div className={classes.buttons}>
                  <Button
                    className={clsx(classes.button, classes['submit-button'])}
                    theme="primary"
                    disabled={!isValid || disableSubmit}
                  >
                    {t('Save password')}
                  </Button>
                </div>
              </form>
            </>
          )}

          {step === 1 && (
            <>
              <div className={classes.heading}>
                <SuccessIcon />
                {toNbsp(t('Password has changed'))}
              </div>
              <div className={classes.body}>
                <div className={classes.text}>
                  {t(
                    'Your new password has been successfully changed, you can '
                  )}
                  <span>
                    <Link
                      className={classes.link}
                      href={`${process.env.REACT_APP_LOGIN_URL}`}
                    >
                      {t('sign in')}
                    </Link>
                  </span>{' '}
                  {t('now')}.
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
