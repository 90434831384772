import { CLIENT_LEVEL } from 'constants/enums';
import ClientLevel from './ClientLevel';
import classes from './ClientList.module.scss';
import ClientPreview from './ClientPreview';
import ClientPoints from './ClientPoints';
import { Client } from 'types/user';

interface ClientListProps {
  client?: Client;
  bonusBalance: number;
}

const ClientList = ({ client, bonusBalance }: ClientListProps) => (
  <ul className={classes.clientList}>
    <ClientPreview
      title={'Клиент'}
      name={client?.name || 'Название компании не указано'}
      description={
        client?.business_type?.name?.split('/')?.[0] ||
        client?.business_type?.name ||
        'Индустрия компании не указана'
      }
      buttonName={'Перейти в профиль'}
    />
    <ClientLevel
      title={'Уровень'}
      level={client?.level?.name || CLIENT_LEVEL.GOLD}
      description={
        'Вы получаете доступ к уникальным персонализированным предложениям'
      }
      linkName={'Подробнее о возможностях'}
    />
    <ClientPoints
      title={'Баллы'}
      points={bonusBalance}
      firstLinkName="История начислений"
      secondLinkName="Правила бонусной программы"
    />
  </ul>
);

export default ClientList;
